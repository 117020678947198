import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ChatListLoader = () => {
  // Define the number of skeleton rows you want to display
  const rows = 5;

  return (
    <div>
      {Array.from({ length: rows }).map((_, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          {/* Loading avatar */}
          <Skeleton circle={true} height={50} width={50} />

          {/* Loading text */}
          <div style={{ marginLeft: '16px', width: '100%' }}>
            {/* First line (name) */}
            <Skeleton width="80%" height={20} />
            {/* Second line (message preview) */}
            <Skeleton width="60%" height={15} style={{ marginTop: '8px' }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatListLoader;
