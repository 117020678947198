import React, { useEffect, useState } from 'react';
import { Divider, Input, notification, Upload, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CommonFooter from '../../components/Footer/footer';
import './style.css';

const AthleteAccount = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [guestData, setGuestData] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [photo_display, setphoto_display] = useState(null);
  const [photo_display_details, setphoto_display_details] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

    useEffect(() => {
        handleRetriveGuest();
    }, []);

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleRetriveGuest = () => {
            props.guestProfileAPI({
                callback: (res, data) => {
                    setLoading(false)
                    if(_.get(data, 'data.details.id')){
                        setGuestData(_.get(data, 'data.details'));
                        setFirstName(_.startCase(_.get(data, 'data.details.first_name')));
                        setLastName(_.startCase(_.get(data, 'data.details.last_name')));
                        setEmail(_.get(data, 'data.details.contact_details.email'));

                        let photo_path =`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.small')}/${_.get(data, 'data.details.photo')}?time=${new Date().getTime()}`
                  setphoto_display(photo_path)
                  props.athleteRapidPhotoAPI({
                    value: {
                        athlete_photo: photo_path,
                        realPhoto: _.get(data, 'data.details.photo')
                    }
                  })
                    }
                    
                }
            })
    };

    const getAbbreviation = () => {
        if(!_.isEmpty(guestData)){
            let name  = `${_.startCase(_.get(guestData, 'first_name'))} ${_.startCase(_.get(guestData, 'last_name'))}`
            const words = name.trim().split(' ').filter(Boolean); // Split by spaces and remove empty elements
    
            if (words.length === 1) {
                // If there is only one word, return the first and last letter
                const firstLetter = words[0][0].toUpperCase();
                const lastLetter = words[0][words[0].length - 1].toUpperCase();
                return firstLetter + lastLetter;
            } else {
                // If there are multiple words, return the first letter of the first two words
                const firstWordFirstLetter = words[0][0].toUpperCase();
                const secondWordFirstLetter = words[1][0].toUpperCase();
                return firstWordFirstLetter + secondWordFirstLetter;
            }
        }
       
    };

    const handleUpdateGuest = () => {
        setSubmitLoading(true);
        props.guestUpdateAPI({
            body: {
                first_name: firstName,
                last_name: lastName,
            },
            callback: (res, data) => {
                setSubmitLoading(false);
                    handleUpdateGuestContactDetails(); 
                    if(_.get(data, 'success')){
                        notification.success({
                            message: 'Success',
                            description: 'Account updated successfully'
                        })
                    }
            }
        })
    };

    const handleUpdateGuestContactDetails = () => {
        props.userUpdateContactDetailsAPI({
                body: {
                    email: email
                  },
                  callback: (res, data) => {
                    
                  }
        })
    };

    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      };
      

    const onChangePhoto = async (info,list) => {

     
        if(`${_.get(info,'fileList[0].type')}`.includes('png') ||
      `${_.get(info,'fileList[0].type')}`.includes('jpg') ||
    `${_.get(info,'fileList[0].type')}`.includes('jpeg')){
    getBase64(_.get(info,'fileList[0].originFileObj'), (imageUrl) => {
          setphoto_display_details(_.get(info,'fileList[0]'))  
          setphoto_display(imageUrl);
          if(_.get(info,'fileList[0]')){
            updatePhoto(_.get(info,'fileList[0]'));
          }
        });
    }else{
    //   commonErrorToaster("Upload failed - Please ensure all your files are in supported formats (png, jpg or jpeg) and try again.",'Error')
    notification.error({
        message: 'Error',
        description: 'Upload failed - Please ensure all your files are in supported formats (png, jpg or jpeg) and try again.'
    })
    }
    };

    const beforeUpload = (file, fileList) => {
        // Access file content here and do something with it
        
      
        // Prevent upload
        return false
      };

      const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
      };

      const updatePhoto = (photoData) => {
        return new Promise(resolve => {
        if (_.get(photoData, 'originFileObj')) {
          
            let formData = new FormData()
            formData.append('photo', _.get(photoData, 'originFileObj'))
            props.guestUpdatePhotoAPI({
                body: formData,
                callback: (res, data) => {
                  
                    if (res == true) {
                      let details =JSON.parse(_.get(data,'data'))
                      notification.success({
                        message: 'Success',
                        description: 'Photo updated successfully'
                      })
                    //   let displayPictureUrl= `${_.get(mediaData, 'photo.path')}/${_.get(mediaData, 'photo.folder')}/${_.get(mediaData, 'photo.sizes.medium')}/${_.get(details, 'details[0].photo')}` 
                    //   chartkittyuserupdate({..._.get(details,'details'),displayPictureUrl})
                    handleRetriveGuest()
                         
                    }
                        resolve();
                }
            });
        }
        else{
            resolve();
        }
      })
      };

      const handleCopyEmail = () => {
        navigator.clipboard.writeText('support@communiti.app');
        success();
      };

      const handleCopyPhone = () => {
        navigator.clipboard.writeText('+18448443345');
        success();
      };

      const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Copied',
        });
      };

  return (
    <div>
        {contextHolder}
        {
            loading && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress sx={{ color: '#EF6820' }}/>
            </div>
        }
        { !loading && <div>
        <div style={{marginTop: "32px", marginBottom: "32px"}} className='container'>
            <div>
                <div className='athlete-account-page-width'>
                <div style={{fontSize: windowSize.width<=768 ? "1.25rem" : ""}} className='account-header'>
                    Account details
                    </div>
                    <div style={{marginTop: "4px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='account-sub-header'>
                    Manage your account information from here
                    </div>
                    <Divider style={{margin: 0, marginTop: "4px"}}/>

                    <div style={{marginTop: "32px"}} className="welcome-card">
                        {/* original athlete image have to come here */}
                            <Upload
                        showUploadList={false}
                                                
                        onChange={onChangePhoto}
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        accept='image/png, image/jpeg, image/jpg'
                        multiple={false}
                        customRequest={dummyRequest}
                        >
                            { _.get(guestData, 'photo') !== "default.png" && photo_display && (<img style={{cursor: "pointer"}} src={photo_display} className='athlete-avatar-img' />)}
                            { _.get(guestData, 'photo') == "default.png" && <div style={{cursor: "pointer"}} className='host-avatar-img-txt'>{getAbbreviation()}</div>}
                        </Upload> 
                            
                            <div className="session-text-container" style={{marginLeft: windowSize.width <=768 ? "12px" : "24px"}}>
                            <div className="session-header-text" style={{fontSize: windowSize.width<=768 ? "1.125rem" : ""}}>
                            {`${_.startCase(_.get(guestData, 'first_name'))} ${_.startCase(_.get(guestData, 'last_name'))}`}
                            </div>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={windowSize.width <=768 ? "17" : "24"} height={windowSize.width <=768 ? "17" : "24"} viewBox="0 0 24 23" fill="none">
                                    <path d="M12 7.66663L16 11.5M16 11.5L12 15.3333M16 11.5H3M3.33782 6.70829C5.06687 3.84383 8.29859 1.91663 12 1.91663C17.5228 1.91663 22 6.20723 22 11.5C22 16.7927 17.5228 21.0833 12 21.0833C8.29859 21.0833 5.06687 19.1561 3.33782 16.2916" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>

                                <div className="session-sub-header-text" style={{marginTop: "1px", marginLeft: "8px"}}>
                                {`${_.get(guestData, 'login_type') === 'email' ? "Email" : _.get(guestData, 'login_type') === 'phone' ? "Phone number" : "Google"}`} Sign-in: {`${_.get(guestData, 'login_type') === 'email' ? _.get(guestData, 'contact_details.email') : _.get(guestData, 'login_type') === 'phone' ? _.get(guestData, 'contact_details.phone') : _.get(guestData, 'contact_details.email')}`}
                                    </div>
                            </div>
                                
                            </div>
                    </div>
                    <Divider style={{margin: 0, marginTop: "32px"}}/>

                    <div style={{marginTop: "8px"}} className='athlete-personal-info-txt'>
                    Personal info
                    </div>
                    <div className='update-info-sub-txt' style={{marginTop: "4px"}}>
                    Update your name and contact email here
                    </div>
                    <div className='athlete-update-info-card'>
                        <div className={windowSize.width <=768 ? "" : "athlete-info-card-row"}>
                            <div className="athlete-info-card-input-container">
                            <label className="athlete-info-card-label">First Name</label>
                            <Input 
                            value={firstName} 
                            type="text" 
                            placeholder="First Name" 
                            className="athlete-info-card-input" 
                            onChange={(e) => handleChangeFirstName(e)}
                            style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}}
                            />
                            </div>
                            <div style={{marginTop: windowSize.width <= 768 ? "12px" : ''}} className="athlete-info-card-input-container">
                            <label className="athlete-info-card-label">Last Name</label>
                            <Input 
                            value={lastName} 
                            type="text" 
                            placeholder="Last Name" 
                            className="athlete-info-card-input" 
                            onChange={(e) => handleChangeLastName(e)}
                            style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}}
                            />
                            </div>
                        </div>

                        <div style={{marginTop: "20px"}}>
                        <label className="athlete-info-card-label">Contact email address</label>
                        <Input 
                        value={email} 
                        type="text" 
                        placeholder="example@example.com" 
                        className="athlete-info-card-input" 
                        onChange={(e) => handleChangeEmail(e)}
                        style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}}
                        />
                        </div>
                        <div style={{marginTop: "24px"}}>
                        <Divider style={{ margin: '0 -24px', width: 'calc(100% + 48px)' }}/>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            {
                                submitLoading && <div className='account-save-changes-btn-loading' style={{height: "44px"}}>
                                    <div className='dot-pulse'/>
                                </div>
                            }
                            { !submitLoading  && <button
                             onClick={() => handleUpdateGuest()} 
                             className='account-save-changes-btn'
                             style={{height: "44px"}}
                             >Save
                             </button>}
                        </div>
                    </div>

                    <Divider style={{margin: 0, marginTop: "32px"}}/>
                    <div style={{marginTop: "8px"}} className='athlete-personal-info-txt'>
                    Contact us
                    </div>
                    <div style={{marginTop: "4px"}} className='update-info-sub-txt'>
                    Reach out for support
                    </div>
                    <div className='athlete-info-support-card'>
                        <div>
                        <label className="athlete-info-card-label" style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}}>Support email</label>
                            
                        <div  style={{cursor: "pointer"}} className='account-support-email-container'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M1.66663 5.83331L8.47073 10.5962C9.0217 10.9819 9.29719 11.1747 9.59685 11.2494C9.86154 11.3154 10.1384 11.3154 10.4031 11.2494C10.7027 11.1747 10.9782 10.9819 11.5292 10.5962L18.3333 5.83331M5.66663 16.6666H14.3333C15.7334 16.6666 16.4335 16.6666 16.9683 16.3942C17.4387 16.1545 17.8211 15.772 18.0608 15.3016C18.3333 14.7668 18.3333 14.0668 18.3333 12.6666V7.33331C18.3333 5.93318 18.3333 5.23312 18.0608 4.69834C17.8211 4.22793 17.4387 3.84548 16.9683 3.6058C16.4335 3.33331 15.7334 3.33331 14.3333 3.33331H5.66663C4.26649 3.33331 3.56643 3.33331 3.03165 3.6058C2.56124 3.84548 2.17879 4.22793 1.93911 4.69834C1.66663 5.23312 1.66663 5.93318 1.66663 7.33331V12.6666C1.66663 14.0668 1.66663 14.7668 1.93911 15.3016C2.17879 15.772 2.56124 16.1545 3.03165 16.3942C3.56643 16.6666 4.26649 16.6666 5.66663 16.6666Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg> <div style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}} onClick={() => window.location = 'mailto:support@communiti.app'}>support@communiti.app</div> <div onClick={() => handleCopyEmail()}>
                                <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15436_1413)">
                                        <path d="M6.66675 13.3333V15.6667C6.66675 16.6001 6.66675 17.0668 6.8484 17.4233C7.00819 17.7369 7.26316 17.9919 7.57676 18.1517C7.93328 18.3333 8.39999 18.3333 9.33341 18.3333H15.6667C16.6002 18.3333 17.0669 18.3333 17.4234 18.1517C17.737 17.9919 17.992 17.7369 18.1518 17.4233C18.3334 17.0668 18.3334 16.6001 18.3334 15.6667V9.33332C18.3334 8.3999 18.3334 7.93319 18.1518 7.57667C17.992 7.26307 17.737 7.0081 17.4234 6.84831C17.0669 6.66666 16.6002 6.66666 15.6667 6.66666H13.3334M4.33341 13.3333H10.6667C11.6002 13.3333 12.0669 13.3333 12.4234 13.1517C12.737 12.9919 12.992 12.7369 13.1518 12.4233C13.3334 12.0668 13.3334 11.6001 13.3334 10.6667V4.33332C13.3334 3.3999 13.3334 2.93319 13.1518 2.57667C12.992 2.26307 12.737 2.0081 12.4234 1.84831C12.0669 1.66666 11.6002 1.66666 10.6667 1.66666H4.33341C3.39999 1.66666 2.93328 1.66666 2.57676 1.84831C2.26316 2.0081 2.00819 2.26307 1.8484 2.57667C1.66675 2.93319 1.66675 3.3999 1.66675 4.33332V10.6667C1.66675 11.6001 1.66675 12.0668 1.8484 12.4233C2.00819 12.7369 2.26316 12.9919 2.57676 13.1517C2.93328 13.3333 3.39999 13.3333 4.33341 13.3333Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15436_1413">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                </div>
                        </div>
                        <div className='account-support-respond-txt' >
                        We respond to most support emails within 2 business days
                        </div>
                        </div>

                        <div style={{marginTop: "12px"}}>
                        <label className="athlete-info-card-label">Text Us</label>
                        <div  style={{cursor: "pointer"}} className='account-support-email-container'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.08333 10.4167C7.08333 10.4167 8.17708 11.6667 10 11.6667C11.8229 11.6667 12.9167 10.4167 12.9167 10.4167M12.2917 6.25H12.3M7.70833 6.25H7.71667M5.83333 15V16.9463C5.83333 17.3903 5.83333 17.6123 5.92436 17.7263C6.00352 17.8255 6.12356 17.8832 6.25045 17.8831C6.39636 17.8829 6.56973 17.7442 6.91646 17.4668L8.90434 15.8765C9.31043 15.5517 9.51347 15.3892 9.73957 15.2737C9.94017 15.1712 10.1537 15.0963 10.3743 15.051C10.6231 15 10.8831 15 11.4031 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V11.6667C2.5 12.4416 2.5 12.8291 2.58519 13.147C2.81635 14.0098 3.49022 14.6836 4.35295 14.9148C4.67087 15 5.05836 15 5.83333 15ZM12.7083 6.25C12.7083 6.48012 12.5218 6.66667 12.2917 6.66667C12.0615 6.66667 11.875 6.48012 11.875 6.25C11.875 6.01988 12.0615 5.83333 12.2917 5.83333C12.5218 5.83333 12.7083 6.01988 12.7083 6.25ZM8.125 6.25C8.125 6.48012 7.93845 6.66667 7.70833 6.66667C7.47821 6.66667 7.29167 6.48012 7.29167 6.25C7.29167 6.01988 7.47821 5.83333 7.70833 5.83333C7.93845 5.83333 8.125 6.01988 8.125 6.25Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> <div style={{fontSize: windowSize.width<= 768 ? "0.875rem" : ""}} onClick={() => window.open('sms:+18448443345')}>1-844-844-3345</div> <div onClick={() => handleCopyPhone()}>
                                <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15436_1413)">
                                        <path d="M6.66675 13.3333V15.6667C6.66675 16.6001 6.66675 17.0668 6.8484 17.4233C7.00819 17.7369 7.26316 17.9919 7.57676 18.1517C7.93328 18.3333 8.39999 18.3333 9.33341 18.3333H15.6667C16.6002 18.3333 17.0669 18.3333 17.4234 18.1517C17.737 17.9919 17.992 17.7369 18.1518 17.4233C18.3334 17.0668 18.3334 16.6001 18.3334 15.6667V9.33332C18.3334 8.3999 18.3334 7.93319 18.1518 7.57667C17.992 7.26307 17.737 7.0081 17.4234 6.84831C17.0669 6.66666 16.6002 6.66666 15.6667 6.66666H13.3334M4.33341 13.3333H10.6667C11.6002 13.3333 12.0669 13.3333 12.4234 13.1517C12.737 12.9919 12.992 12.7369 13.1518 12.4233C13.3334 12.0668 13.3334 11.6001 13.3334 10.6667V4.33332C13.3334 3.3999 13.3334 2.93319 13.1518 2.57667C12.992 2.26307 12.737 2.0081 12.4234 1.84831C12.0669 1.66666 11.6002 1.66666 10.6667 1.66666H4.33341C3.39999 1.66666 2.93328 1.66666 2.57676 1.84831C2.26316 2.0081 2.00819 2.26307 1.8484 2.57667C1.66675 2.93319 1.66675 3.3999 1.66675 4.33332V10.6667C1.66675 11.6001 1.66675 12.0668 1.8484 12.4233C2.00819 12.7369 2.26316 12.9919 2.57676 13.1517C2.93328 13.3333 3.39999 13.3333 4.33341 13.3333Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15436_1413">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                </div>
                        </div>
                        <div className='account-support-respond-txt'>
                        Text us if you’re having issues
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CommonFooter/>
        </div>}
    </div>
  )
}

const mapStateToProps = state => {
  
    return {
     
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    guestProfileAPI: details => dispatch(global.redux.action.guest.profile(details)),
    guestUpdateAPI: details => dispatch(global.redux.action.guest.update(details)),
    userUpdateContactDetailsAPI: details => dispatch(global.redux.action.guest.updatePhoto(details)),
    guestUpdatePhotoAPI: details => dispatch(global.redux.action.guest.updatePhoto(details)),
    athleteRapidPhotoAPI: details => dispatch(global.redux.action.settings.athleteRapidPhoto(details))
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AthleteAccount)