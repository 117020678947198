import React from 'react';

import './my-session.css'

const EmptyState = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "120px", flexDirection: "column"}}>
        <img style={{width: "207px", height: "177px"}} src={require('../../assets/img/my-session-empty-state-img.png')}/>
        <div style={{marginTop: "16px"}}>
            <div className='session-empty-txt'>
            No sessions found
            </div>
            <div style={{marginTop: "4px"}} className='session-empty-sub-txt'>
            All of your purchased sessions will appear here. Get<br/> started by booking your first session
            </div>
        </div>
    </div>
  )
}

export default EmptyState