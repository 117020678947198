import _ from 'lodash';
import actionTypes from './actionTypes';

const initialState = {
  // host state
  [actionTypes.host.name]: {
    [actionTypes.host.profile]: {},
    [actionTypes.host.update]: {},
    [actionTypes.host.updatePhoto]: {},
    [actionTypes.host.getHostsDetails]: {}
  },

  // guest state
  [actionTypes.guest.name]: {
    [actionTypes.guest.profile]: {},
    [actionTypes.guest.profilesList]: {},
    [actionTypes.guest.update]: {},
    [actionTypes.guest.updatePhoto]: {},
    [actionTypes.guest.updatePhone]: {},
    [actionTypes.guest.stripeCustomer]: {},
    [actionTypes.guest.guestProfilesCreate]: {},
    [actionTypes.guest.guestProfilesView]: {},
    [actionTypes.guest.guestProfilesUpdate]: {},
    [actionTypes.guest.guestProfilesDelete]: {},
    [actionTypes.guest.guestUpdatePhone]: {},
    [actionTypes.guest.guestUpdateEmail]: {},
    [actionTypes.guest.updateContactDetails]: {},
  },

  // settings state
  [actionTypes.settings.name]: {
    [actionTypes.settings.isAppReady]: false,
    [actionTypes.settings.canAccessHostDashboard]: false,
    [actionTypes.settings.chatKittyCurrentUserData]: {},
    [actionTypes.settings.onsitePlayersCheckIn]: {},
    [actionTypes.settings.setTemplateFuncActive]: {},
    [actionTypes.settings.setTemplateFuncArchive]: {},
    [actionTypes.settings.tabTeam]: {},
    [actionTypes.settings.setQuestionsAnswer]: {},
    [actionTypes.settings.setAllQuestionsRedux]: {},
    [actionTypes.settings.athleteRapidPhoto]: {}
    
  },

  // account state
  [actionTypes.account.name]: {
    [actionTypes.account.socialLogin]: {},
    [actionTypes.account.phoneLogin]: {},
    [actionTypes.account.stripeOnboardWeb]: {}
  },

  // categories state
  [actionTypes.categories.name]: {
    [actionTypes.categories.list]: {}
  },


  // settings state
  [actionTypes.experiencesTemplates.name]: {
    [actionTypes.experiencesTemplates.list]: {},
    [actionTypes.experiencesTemplates.view]: {},
    [actionTypes.experiencesTemplates.create]: {},
    [actionTypes.experiencesTemplates.update]: {},
    [actionTypes.experiencesTemplates.addPhoto]: {},
    [actionTypes.experiencesTemplates.changeStatusTemplate]: {}
  },


  
  // site state
  [actionTypes.site.name]: {
    [actionTypes.site.contactUs]: {}
  },

  // subscription state
  [actionTypes.subscription.name]: {
    [actionTypes.subscription.list]: {},
    [actionTypes.subscription.view]: {},
    [actionTypes.subscription.list_subscribers]: {},
    [actionTypes.subscription.subscribe]: {},
  },

  // dashboard state
  [actionTypes.dashboard.name]: {
    [actionTypes.dashboard.forHost]: {},
    [actionTypes.dashboard.revenueStats]: {}
  },
  // order state
  [actionTypes.order.name]: {
    [actionTypes.order.list]: {},

  },
  // coupons state
  [actionTypes.coupons.name]: {
    [actionTypes.coupons.apply]: {}
  },

   // finances state
   [actionTypes.finances.name]: {
    [actionTypes.finances.list]: {},
    [actionTypes.finances.ratingList]: {},
    [actionTypes.finances.view]: {},

  },

   // league state
   [actionTypes.league.name]: {
    [actionTypes.league.list]: {},
    [actionTypes.league.changeEventStatus]: {},
    [actionTypes.league.leagueTeamsList]: {},
    [actionTypes.league.leagueEventRosterList]: {},
    [actionTypes.league.viewLeagueEvents]: {},
    [actionTypes.league.leagueEventCheckIn]: {},
    [actionTypes.league.leagueMatchesList]: {},
    [actionTypes.league.courtsCreate]: {},
    [actionTypes.league.leagueUpdateCaptain]: {},
    [actionTypes.league.leagueUpdateMatch]: {},
    [actionTypes.league.leagueNewUpdateMatch]: {},
    [actionTypes.league.leagueUpdateTeam]: {},
    [actionTypes.league.leaderBoardList]: {},

  },
  [actionTypes.notifications.name]: {
    [actionTypes.notifications.list]: {},
    [actionTypes.notifications.view]: {},

  },

  //user handle property
  [actionTypes.userHandle.name]: {
    [actionTypes.userHandle.checkUserHandleAvailablity]: {},
    [actionTypes.userHandle.createOrUpdateUserHandle]: {},
  },

  [actionTypes.question.name]: {
    [actionTypes.question.experienceQuestionList]: {},
    [actionTypes.question.guestQuestionList]: {},
    [actionTypes.question.experienceAnswerCreate]: {},
    [actionTypes.question.experienceAnswerUpdate]: {},
    [actionTypes.question.guestAnswerCreate]: {},
    [actionTypes.question.guestAnswerUpdate]: {},
  },

  [actionTypes.otpLogin.name]: {
    [actionTypes.otpLogin.sendOtp]: {},
  },

  [actionTypes.accountProfile.name]: {
    [actionTypes.accountProfile.checkProfile]: {},
  },

  [actionTypes.orders.name]: {
    [actionTypes.orders.list]: {},
  },

}


const reduxUpdateHostState = (state, action) => {
  return {
    ...state, [actionTypes.host.name]:
    {
      ...state[actionTypes.host.name],
      ..._.get(action, 'data', {})
    }
  };
}

const reduxUpdateGuestState = (state, action) => {
  return {
    ...state, [actionTypes.guest.name]:
    {
      ...state[actionTypes.guest.name],
      ..._.get(action, 'data', {})
    }
  };
}

const reduxUpdateSettingsState = (state, action) => {
  return {
    ...state, [actionTypes.settings.name]:
    {
      ...state[actionTypes.settings.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateAccountState = (state, action) => {
  return {
    ...state, [actionTypes.account.name]:
    {
      ...state[actionTypes.account.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateCategoriesState = (state, action) => {
  return {
    ...state, [actionTypes.categories.name]:
    {
      ...state[actionTypes.categories.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateExperiencesTemplatesState = (state, action) => {
  return {
    ...state, [actionTypes.experiencesTemplates.name]:
    {
      ...state[actionTypes.experiencesTemplates.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateExperiencesState = (state, action) => {

  return {
    ...state, [actionTypes.experiences.name]:

    {
      ...state[actionTypes.experiences.name],
      ..._.get(action, 'data', null)
    }
  };

}
const reduxUpdatePackageState = (state, action) => {

  return {
    ...state, [actionTypes.packages.name]:

    {
      ...state[actionTypes.packages.name],
      ..._.get(action, 'data', null)
    }
  };

}
const reduxUpdateSiteState = (state, action) => {
  return {
    ...state, [actionTypes.site.name]:
    {
      ...state[actionTypes.site.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateSubscriptionState = (state, action) => {
  return {
    ...state, [actionTypes.subscription.name]:
    {
      ...state[actionTypes.subscription.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateDashboardState = (state, action) => {
  return {
    ...state, [actionTypes.dashboard.name]:
    {
      ...state[actionTypes.dashboard.name],
      ..._.get(action, 'data', null)
    }
  };
}
const reduxUpdateOrderState = (state, action) => {
  return {
    ...state, [actionTypes.order.name]:
    {
      ...state[actionTypes.order.name],
      ..._.get(action, 'data', null)
    }
  };
}
const reduxUpdateMessageState = (state, action) => {
  return {
    ...state, [actionTypes.message.name]:
    {
      ...state[actionTypes.message.name],
      ..._.get(action, 'data', null)
    }
  };
}
const reduxUpdateTranscationsState = (state, action) => {
  return {
    ...state, [actionTypes.message.name]:
    {
      ...state[actionTypes.message.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateCouponsState = (state, action) => {

  return {
    ...state, [actionTypes.coupons.name]:

    {
      ...state[actionTypes.coupons.name],
      ..._.get(action, 'data', null)
    }
  };

}

const reduxUpdateFinancesState = (state, action) => {
  return {
    ...state, [actionTypes.finances.name]:
    {
      ...state[actionTypes.finances.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateLeagueState = (state, action) => {
  return {
    ...state, [actionTypes.league.name]:
    {
      ...state[actionTypes.league.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateNotificationState = (state, action) => {
  return {
    ...state, [actionTypes.notifications.name]:
    {
      ...state[actionTypes.notifications.name],
      ..._.get(action, 'data', null)
    }
  };
};

const reduxUpdateUserHandleState = (state, action) => {
  return {
    ...state, [actionTypes.userHandle.name]:
    {
      ...state[actionTypes.userHandle.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateQuestionState = (state, action) => {
  return {
    ...state, [actionTypes.question.name]:
    {
      ...state[actionTypes.question.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateOtpLoginState = (state, action) => {
  return {
    ...state, [actionTypes.otpLogin.name]:
    {
      ...state[actionTypes.otpLogin.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateCheckProfileAccountState = (state, action) => {
  return {
    ...state, [actionTypes.accountProfile.name]:
    {
      ...state[actionTypes.accountProfile.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateOrdersState = (state, action) => {
  return {
    ...state, [actionTypes.orders.name]:
    {
      ...state[actionTypes.orders.name],
      ..._.get(action, 'data', null)
    }
  };
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reduxUpdateHostState:
      return reduxUpdateHostState(state, action);
    case actionTypes.reduxUpdateGuestState:
      return reduxUpdateGuestState(state, action);
    case actionTypes.reduxUpdateSettingsState:
      return reduxUpdateSettingsState(state, action);
    case actionTypes.reduxUpdateAccountState:
      return reduxUpdateAccountState(state, action);
    case actionTypes.reduxUpdateCategoriesState:
      return reduxUpdateCategoriesState(state, action);
    case actionTypes.reduxUpdateExperiencesTemplatesState:
      return reduxUpdateExperiencesTemplatesState(state, action);
    case actionTypes.reduxUpdateExperiencesState:
      return reduxUpdateExperiencesState(state, action);
    case actionTypes.reduxUpdatePackageState:
      return reduxUpdatePackageState(state, action);
    case actionTypes.reduxUpdateSiteState:
      return reduxUpdateSiteState(state, action);
    case actionTypes.reduxUpdateSubscriptionState:
      return reduxUpdateSubscriptionState(state, action);
    case actionTypes.reduxUpdateDashboardState:
      return reduxUpdateDashboardState(state, action);
    case actionTypes.reduxUpdateOrderState:
      return reduxUpdateOrderState(state, action);
    case actionTypes.reduxUpdateMessageState:
      return reduxUpdateMessageState(state, action);
    case actionTypes.reduxUpdateTranscationsState:
      return reduxUpdateTranscationsState(state, action);
    case actionTypes.reduxUpdateCouponsState:
      return reduxUpdateCouponsState(state, action);
      case actionTypes.reduxUpdateFinancesState:
      return reduxUpdateFinancesState(state, action);
      case actionTypes.reduxUpdateLeagueState:
      return reduxUpdateLeagueState(state, action);
      case actionTypes.reduxUpdateNotificationsState:
      return reduxUpdateNotificationState(state, action);
      case actionTypes.reduxUpdateUserHandleState:
      return reduxUpdateUserHandleState(state, action);
      case actionTypes.reduxUpdateQuestionState:
      return reduxUpdateQuestionState(state, action);
      case actionTypes.reduxUpdateOtpLoginState:
      return reduxUpdateOtpLoginState(state, action);
      case actionTypes.reduxUpdateCheckProfileAccountState:
      return reduxUpdateCheckProfileAccountState(state, action);
      case actionTypes.reduxUpdateOrdersState:
      return reduxUpdateOrdersState(state, action);
    default:
      return state
  }
}

export default reducer;
