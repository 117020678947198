import React, {useState, useEffect} from 'react';
import { Select, Modal, notification, Button } from 'antd';
import { MdOutlineCalendarToday } from "react-icons/md";
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import _, { isEmpty } from 'lodash';
import { useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { CiMobile3 } from "react-icons/ci";
import EmailLogin from "../email-login/index"
import LoginWithPhone from './login-with-phone';


import './style.css';
const { Option } = Select;
const SessionCard = (props) => {
  
  const navigate = useNavigate()
  const location = useLocation()

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
  const [getTempData, setTempData] = useState({});
  const [getScheduledDate, setScheduledDate] = useState();
  const [getExperinceData, setExperinceData] = useState([]);
  const [getItems, setItems] = useState(null);
  const [pastDateCount, setPastDateCount] = useState(0);
  const [getTime, setTime] = useState(null);
  const [getDate, setDate] = useState(null);
  const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [getMerDate, setMerDate] = useState();
  const [expiredPastDate, setExpiredPastDate] = useState(0);
  const [isExpiredSessionDateModalOpen, setIsExpiredSessionDateModalOpen] = useState(false);
const [emailLoginOpen, setemailLoginOpen] = useState({visible:false,form:1})
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [getViewTemplateLoading, setViewTemplateLoading] = useState(true)
  const [getExperienceListLoading, setExperienceListLoading] = useState(true)
  // const [setSelectedExperience, setSelectedExperience] = useState({})
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Adjust this value based on your layout and preference
      setSticky(offset > 100); 
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  useEffect(() => {
    let session_end_date = _.get(getTempData, 'data.details.experience_end_date')
    const currentDate = new Date();
    const inputDate = new Date(session_end_date);
    setIsPastDate(inputDate < currentDate);
  }, [_.get(getTempData, 'data.details.experience_end_date')])

  useEffect(() => {
    viewTemplate();
    experienceList()
  }, [])

  const fullscheduleButtonStyle = {
    borderRadius: '6px',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    background: 'var(--Base-White, #FFF)',
    padding:" 4px 10px 4px 9px",
    marginLeft: "12px",
    color: '#667085',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    cursor: "pointer"
    // Add any additional styles as needed
  };

  const priceStyle = {
    color: "#344054",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "20px" ,
marginTop: "8px"
  };

  const proceedRegn = {
    borderRadius: "8px",
    border: "1px solid var(--Orange-500, #EF6820)",
    background: "var(--Orange-500, #EF6820)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "var(--Base-White, #FFF)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px", /* 125% */
    display: "flex",
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    cursor: "pointer"
  };

  const proceedRegnDisabled = {
    borderRadius: "8px",
    border: "#ddd",
    background: "#ddd",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "#999",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px", /* 125% */
    display: "flex",
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    cursor: "not-allowed"
  };

  const commonText = {
    color: "var(--Gray-600, #475467)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    marginTop: "8px"
  };

  const strike_price = {
      fontSize: "0.875rem",
      fontWeight: "normal",
      lineHeight: "20px",
      marginLeft: "5px",
      marginTop: "8px",
      marginBottom: "-3px",
      color: "#101828",
      textDecorationLine: "line-through",
  };

  const schedule_price = {
    fontSize: "0.875rem",
      fontWeight: "normal",
      lineHeight: "20px",
      marginLeft: "5px",
      marginTop: "8px",
      marginBottom: "-3px",
      color: "101828"
  }

  const notAvailableText = {
    display: "flex",
    justifyContent: "center",
    color: '#475467',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: "16px"
  }

  const viewTemplate = () => {
    setViewTemplateLoading(true);
    props.viewSessionAPI({
      params: {
        id: id,
        expand: "template,template_photos,host",
        is_price_porated: 1
      },
      callback: (res, value) => {
        setViewTemplateLoading(false);
        if(res){
          setTempData(value);
          const concated_date = [_.get(value, 'data.details.experience_date'), ..._.get(value, 'data.details.schedule_dates')]
            setScheduledDate(concated_date);
            let count = 0;
            const selectedToday = moment.tz(_.get(value, 'data.details.meta_details.timezone'));
            const selectedTimeZone = _.get(value, 'data.details.meta_details.timezone');
            concated_date.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setExpiredPastDate(count);
          
        }
      }
    })
  };

  const experienceList = () => {
    setExperienceListLoading(true);
    props.experienceListAPI({
        params: {
            template: searchParams.get("template"),
            is_price_porated: 1,
            // min_experience_date: currentLocalISOString,
            // min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
            is_closed: 0,
            sort:"created_at.desc"
        },
        callback: (res, value) => {
          setExperienceListLoading(false);
            if(res){
                if(_.size(_.get(value, 'data.items'))){
                  const sortedArray = _.get(value, 'data.items').sort((a, b) => moment(a?.experience_date).diff(moment(b?.experience_date)));
                  setExperinceData(sortedArray);
                }
                
               
                if(_.size(_.get(value, 'data.items')) === 1){
                  // const mergedDate = [selected.experience_date, ...selected.schedule_dates]
                  if(_.isEmpty(_.get(value, 'data.items[0].schedule_dates'))){
                    const mergedDate = [_.get(value, 'data.items[0].experience_date')];
                  setMerDate(mergedDate)
                  let count = 0;
            const selectedToday = moment.tz(_.get(value, 'data.items[0].meta_details.timezone'));
            const selectedTimeZone = _.get(value, 'data.items[0].meta_details.timezone');
            mergedDate.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setPastDateCount(count);
                  } else {
                    const mergedDate = [_.get(value, 'data.items[0].experience_date'), ..._.get(value, 'data.items[0].schedule_dates')];
                  setMerDate(mergedDate)
                  let count = 0;
            const selectedToday = moment.tz(_.get(value, 'data.items[0].meta_details.timezone'));
            const selectedTimeZone = _.get(value, 'data.items[0].meta_details.timezone');
            mergedDate.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setPastDateCount(count);
                  }
                  
                }
            }
        }
    })
  };

  const handleSelectChange = (value) => {
    // Find the selected item based on the address
    setDate(value);
    setTime(null);
    if(_.size(getExperinceData) > 0){
        const selected = getExperinceData.find(item => item.id === value);
        if(selected){
          const mergedDate = [
            selected.experience_date,
            ...(_.isArray(selected.schedule_dates) ? selected.schedule_dates : [])
          ];
          setItems({
              id: selected.id,
              dates: mergedDate,
              session_dates: mergedDate,
              experience_date: selected.experience_date,
              experience_end_date: selected.experience_end_date,
              meta_details: selected.meta_details,
              can_late_register: selected.can_late_register,
              original_price: selected.original_price,
              price: selected.price,
              today:  moment.tz(_.get(selected, 'meta_details.timezone')),
              address: selected.address,
              maxSlots:selected.no_of_available_slots,
              maxStatus: selected.status,
              type: selected.type,
              lateRegDate: selected.late_registration_time_limit,
              latLong: selected.location
          });
          handleSendToParent(selected);
          let count = 0;
            const selectedToday = moment.tz(_.get(selected, 'meta_details.timezone'));
            const selectedTimeZone = _.get(selected, 'meta_details.timezone');
            mergedDate.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setPastDateCount(count);
        }
      
    }
   
};

const handleSendToParent = (value) => {
  props.sendDataToParent(value);
}

const handleChangeTime = (value) => {
        setTime(value);
        // setIsEnableSchedule(false)
    
};

const showSessionDateModal = () => {
  setIsSessionDateModalOpen(true)
};

const scheduleHandleOk = () => {
  setIsSessionDateModalOpen(false)
}

const scheduleHandleCancel = () => {
  setIsSessionDateModalOpen(false)
};

const showExpiredSessionDateModal = () => {
  setIsExpiredSessionDateModalOpen(true)
};

const expiredScheduleHandleOk = () => {
  setIsExpiredSessionDateModalOpen(false)
}

const expiredScheduleHandleCancel = () => {
  setIsExpiredSessionDateModalOpen(false)
};

const modalTitle = (
  <div style={{display: "flex", flexDirection: "column"}}>
    <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
    <div className='continue-with-email' style={{marginBottom: "5px"}}>Please sign-in to complete your purchase</div>
  </div>
);

const selectRedirect = () => {
  if(_.get(props, 'islogindata.isLoggedIn') === true){
    navigate(`/guest-profiles?id=${ _.get(getItems, 'id') ? _.get(getItems, 'id') : searchParams.get('id')}&handle=${searchParams.get('handle')}`);
  } else {
    showLoginModal();
  }
}

const showLoginModal = () => {
  setIsModalOpen(true);
};
const handleLoginOk = () => {
  setIsModalOpen(false);
  // setemailLoginOpen({visible:false})
};
const handleLoginCancel = () => {
  setIsModalOpen(false);
  // setemailLoginOpen(true)
};

const getGoogleToken = (response, signInType) => {
  if (response) {
      let requestParams = {
        type: 'google',
        token: _.get(response, 'credential'),
                  scopes: ['profile', 'email']
      }
      props.login({
        body:requestParams,
        callback: (res,data)=>{
          if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
message:"Success",
description:'Login successful'
})
localStorage.setItem('login_methods', 'Google');
navigate(`/guest-profiles?id=${ _.get(getItems, 'id') ? _.get(getItems, 'id') : searchParams.get('id')}&handle=${searchParams.get('handle')}`);
// window.location.reload(false);
          }
          
        }
    });
      
    }
  };

  const roundPrice = (price) => {
    return Math.round((parseFloat(price) + Number.EPSILON) * 100) / 100;
  };


  const session_title = {
      color: 'var(--Gray-900, #101828)',
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
  };

  const input_div_style = {
    display: 'flex',
    padding: '10px 14px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    background: 'var(--Base-White, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    marginTop: "8px"
  };

  const select_inside_text = {
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  }

  const containerStickyStyle = {
    width: windowSize.width <768 ?'100%' :'392px',
    borderRadius: '12px',
    border: '1px solid var(--Gray-200, #EAECF0)', // You can customize the border color
    padding: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: "0px",
    position: 'relative'
  };

  const continue_with_phone_btn_text = {
    color: "var(--Gray-600, #475467)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer"
  };

  const continue_with_email_text = {
    color: "var(--Gray-600, #475467)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textDecorationLine: "underline"
  };

  const showModal = () => {
    setIsModalVisible(true);
    setIsModalOpen(false)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDataFromChild = (data) => {
    // Handle data received from child component
    setDataFromChild(data);
    setIsModalOpen(data);
  };

  const handleProceed = () => {
    let is_date_past;
    if( !getViewTemplateLoading && !getExperienceListLoading){
      let check_date;
      let current_date = moment();
      if(getExperinceData.length > 0){
        check_date = moment(_.get(getItems, 'lateRegDate'))
      } else {
        check_date = moment(_.get(getTempData, 'data.details.late_registration_time_limit'));
      };
      
      if(!_.isEmpty(check_date)){
        if (check_date.isBefore(current_date)) {
          
          is_date_past = true
        } else {
          
          is_date_past = false
        } 
      }
     
    }
    const hasOnlyOneItem = getExperinceData.length === 1;
    const hasNoAvailableSlots =  getItems !== null &&  _.get(getItems, 'maxSlots') === 0
    const hasStatusNotEqualOne =  getItems !== null &&  _.get(getItems, 'maxStatus') !== 1
    const hasMultipleItems = getExperinceData.length > 1;

    return (
        (hasOnlyOneItem && (getExperinceData[0].status !== 1 || getExperinceData[0].no_of_available_slots === 0)) ||
        (hasNoAvailableSlots || hasStatusNotEqualOne || (_.get(getTempData, 'data.details.type') !== 'open' && hasMultipleItems && getItems === null) || isPastDate || is_date_past === true)
    );
};

const disabledText = () => {
  let is_date_past;
  if( !getViewTemplateLoading && !getExperienceListLoading){
    let check_date;
    let current_date = moment();
    if(getExperinceData.length > 0){
      check_date = moment(_.get(getItems, 'lateRegDate'))
    } else {
      check_date = moment(_.get(getTempData, 'data.details.late_registration_time_limit'));
    };
    
    if(!_.isEmpty(check_date)){
      if (check_date.isBefore(current_date)) {
        
        is_date_past = true
      } else {
        
        is_date_past = false
      } 
    }
   
  }
    
  const hasOnlyOneItem = getExperinceData.length === 1;
  const hasNoAvailableSlots =  getItems !== null &&  _.get(getItems, 'maxSlots') === 0
  const hasStatusNotEqualOne =  getItems !== null &&  _.get(getItems, 'maxStatus') !== 1

  return (
      (hasOnlyOneItem && (getExperinceData[0].status !== 1 || getExperinceData[0].no_of_available_slots === 0)) ||
      (hasNoAvailableSlots || hasStatusNotEqualOne || isPastDate || is_date_past === true)
  );
}; 


  return (
    <div>
        <div style={containerStickyStyle}>
            <div style={{...session_title, fontSize: windowSize.width <=768 ? "1.125rem" : ''}}>{_.get(getTempData, 'data.details.template.title')}</div>
            {_.get(getTempData, 'data.details.type') !== 'open' && <div>
                <div 
                style={{
                    marginTop: "20px",
                    color: 'var(--Gray-700, #344054)',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                    Start date and end date
                    </div>
                    { _.size(getExperinceData) > 1 ? <Select
                    rootClassName='date-select-class'
                    className='date-select-class'
                    style={{ width: "100%", height: "40px", marginTop:"8px" }}
                    placeholder="Select start and end date"
                    onChange={handleSelectChange}
                    
                    optionLabelProp="customLabel" // Use a custom label prop
                >
                    { !_.isEmpty(getExperinceData) > 0 && getExperinceData.map(item => (
                        <Option 
                        
                        key={item} 
                        value={item.id}
                          
                        customLabel={(
                          <span 
                          className='date-select-class secondColor-style'
                          style={{ 
                           
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px" 
                          }}
                          >
                            {`${moment
                              .tz(item.experience_date, _.get(item, 'meta_details.timezone'))
                              .format('ddd, MMM DD, YYYY')} to ${moment
                              .tz(_.get(item, 'experience_end_date'), _.get(item, 'meta_details.timezone'))
                              .format('ddd, MMM DD, YYYY')}`}
                          </span>
                        )}
                        >
                            {`${moment.tz(item.experience_date, _.get(item, 'meta_details.timezone')).format("ddd, MMM DD, YYYY")} to ${moment.tz(_.get(item, 'experience_end_date'), _.get(item, 'meta_details.timezone')).format("ddd, MMM DD, YYYY")}`}
                        </Option>
                    ))}
                </Select> : _.size(getExperinceData) > 0 ?
                <div style={input_div_style}>
                  <div style={{...select_inside_text, fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='secondColor-style'>
                  {`${moment.tz(_.get(getExperinceData[0], 'experience_date'), _.get(getExperinceData[0], 'meta_details.timezone')).format("ddd, MMM DD, YYYY")} to ${moment.tz(_.get(getExperinceData[0], 'experience_end_date'), _.get(getExperinceData[0], 'meta_details.timezone')).format("ddd, MMM DD, YYYY")}`}
                  </div>
                  </div> : <div style={input_div_style}>
                  <div style={{...select_inside_text, fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='secondColor-style'>
                  {`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("ddd, MMM DD, YYYY")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("ddd, MMM DD, YYYY")}`}
                  </div>
                  </div>}
                    </div>}
                    {
                      _.get(getTempData, 'data.details.type') !== 'open' && <div>
                      <div 
                      style={{
                          marginTop: "20px",
                        
                          fontSize: '1rem',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '24px'
                        }}
                        className='secondColor-style'
                      >
                         Start time and end time
                          </div>
                          {_.size(getExperinceData) > 1 ? !_.isEmpty(getDate) ?
                         <Select
                         rootClassName='date-select-class'
                          className='date-select-class'
                          style={{width: "100%", height: "40px", marginTop: "8px"}}
                          placeholder="Select time"
                          value={`${moment.tz(_.get(getItems, 'experience_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}
                          defaultValue={`${moment.tz(_.get(getItems, 'experience_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}
                          onChange={handleChangeTime}
                          optionLabelProp="customLabel" // Use a custom label prop
                          >
                              <Option 
                              key={`${moment.tz(_.get(getItems, 'experience_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`} 
                              value={_.get(getItems, 'experience_start_time')}
                              customLabel={(
                                <span 
                                style={{ 
                                 
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px" 
                                }}
                                className='secondColor-style'
                                >
                                  {`${moment.tz(_.get(getItems, 'experience_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}
                                </span>
                              )}
                              >
                                  {`${moment.tz(_.get(getItems, 'experience_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}
                              </Option>
                          </Select> : <div className='secondColor-style' style={{...commonText, fontSize: windowSize.width<=768 ? "0.875rem" : ''}}>Select a start date to view available start times</div> : 
                          _.size(getExperinceData) > 0 ? <div style={input_div_style}>
                        <div className='secondColor-style' style={{...select_inside_text, fontSize: windowSize.width<=768 ? "0.875rem" : ''}}>
                        {`${moment.tz(_.get(getExperinceData[0], 'experience_date'), _.get(getExperinceData[0], 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getExperinceData[0], 'experience_end_date'), _.get(getExperinceData[0], 'meta_details.timezone')).format("hh:mm A z")}`}
                        </div>
                        </div> : <div style={input_div_style}>
                        <div className='secondColor-style' style={{...select_inside_text, fontSize: windowSize.width<=768 ? "0.875rem" : ''}}>
                        {`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}
                        </div>
                        </div>
                      }
                      </div>
                    }
                    
                    { _.get(getTempData, 'data.details.type') !== 'open' && <div>
    <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                   Location
                    </div>
                    {/* location card starts here */}
                    { _.size(getExperinceData) > 1 ? !_.isEmpty(getItems) ? <div 
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px 14px",
                      border: "1px solid #ddd",
                      marginTop:"8px"
                  }}>
                    <div 
                    style={{
                    
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >{_.get(getItems, 'address')}</div>
                  </div>:<div 
                    style={{
                      marginTop: "8px",
                      
                      fontSize: windowSize.width<=768 ? "0.875rem" : "",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >Select a start time to view location</div> : 
                    _.size(getExperinceData) > 0 ?
                    <div style={input_div_style}>
                    <div style={{...select_inside_text, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}  className='secondColor-style'>
                    {_.get(getExperinceData[0], 'address')}  
                    </div>
                    </div> : <div style={input_div_style}>
                    <div style={{...select_inside_text, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}  className='secondColor-style'>
                    {_.get(getTempData, 'data.details.address')}  
                    </div>
                    </div>}
                    </div>}

                    { _.get(getTempData, 'data.details.type') === 'open' && <div> <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                   Location
                    </div>
                    <div 
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px 14px",
                      border: "1px solid #ddd",
                      marginTop:"8px"
                  }}>
                    <div 
                    style={{
                   
                      fontSize:windowSize.width<=768 ? "0.875rem" : "",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >{_.get(getTempData, 'data.details.address')}</div>
                  </div> </div>}
                  {/* location card ends here */}
                    {/* session schedule card starts here */}
                    { _.get(getTempData, 'data.details.type') !== 'open' && <div>
                  <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                   Session schedule
                    </div>
                    {/* session schedule card starts here */}
                    { _.size(getExperinceData) > 1 ? !_.isEmpty(getItems) ? <div 
                    style={{
                      width: "100%",
                      padding: "0px 16px" ,
                      backgroundColor: "#F9FAFB",
                      borderBottom: "1px solid var(--Gray-200, #EAECF0)", 
                      marginTop:"8px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center"
                      
                  }}>
                    <div 
                    style={{
                     
                      fontSize:windowSize.width<=768 ? "0.875rem" : "",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >
                      {_.size(_.get(getItems, 'session_dates'))-pastDateCount > 1 ? `${_.size(_.get(getItems, 'session_dates'))-pastDateCount} Sessions` : `${_.size(_.get(getItems, 'session_dates'))-pastDateCount} Session`}
                    </div>
                    <button 
                    
                    className='secondColor-style'
                    onClick={() => showSessionDateModal()}
                    style={{...fullscheduleButtonStyle, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}>
                    <MdOutlineCalendarToday style={{marginRight: "6px", marginBottom: "2px"}} />
                    See full schedule
                    </button>
                  </div> : <div 
                    style={{
                      width: "100%",
                      padding: "0px 16px" ,
                      backgroundColor: "#F9FAFB",
                      borderBottom: "1px solid var(--Gray-200, #EAECF0)", 
                      marginTop:"8px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center"
                      
                  }}>
                    <div 
                    style={{
                      
                      fontSize:windowSize.width<=768 ? "0.875rem" : "",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >Select a start time to view schedule</div>
                  </div> : <div 
                    style={{
                      width: "100%",
                      padding: "0px 16px" ,
                      backgroundColor: "#F9FAFB",
                      borderBottom: "1px solid var(--Gray-200, #EAECF0)", 
                      marginTop:"8px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center"
                      
                  }}>
                    <div 
                    style={{
                     
                      fontSize:windowSize.width<=768 ? "0.875rem" : "",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >
                      { _.size(getExperinceData) > 0 ? _.size(getMerDate)-pastDateCount > 1 ? `${_.size(getMerDate)-pastDateCount} Sessions` : `${_.size(getMerDate)-pastDateCount} Session` : _.size(getScheduledDate)-expiredPastDate > 1 ? `${_.size(getScheduledDate)-expiredPastDate} Sessions` : `${_.size(getScheduledDate)-expiredPastDate} Session`}
                    </div>
                    { _.size(getExperinceData) > 0 ? <button 
                     className='secondColor-style'
                    onClick={() => showSessionDateModal()}
                    style={{...fullscheduleButtonStyle, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}>
                    <MdOutlineCalendarToday style={{marginRight: "6px", marginBottom: "2px"}} />
                    See full schedule
                    </button> : <button 
                     className='secondColor-style'
                    onClick={() => showExpiredSessionDateModal()}
                    style={{...fullscheduleButtonStyle, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}>
                    <MdOutlineCalendarToday style={{marginRight: "6px", marginBottom: "2px"}} />
                    See full schedule
                    </button>}
                  </div>}
                  </div>}
                  {/* session schedule card ends here */}
                    
                  <div 
                style={{
                    marginTop: "20px",
                    color: 'var(--Gray-700, #344054)',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                   Price
                    </div>
                    { _.get(getTempData, 'data.details.type') !== 'open' ? _.size(getExperinceData) > 1 ? !_.isEmpty(getItems) ? <div 
                    style={{...priceStyle, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}
                    className='secondColor-style'
                    >
                    ${`${roundPrice(_.get(getItems, 'price'))} ${_.get(getItems, 'meta_details.currency')}`}
                      </div> : <div  style={{...commonText, fontSize:windowSize.width<=768 ? "0.875rem" : ""}}  className='secondColor-style'>Select a start time to view price</div> : _.size(getExperinceData) > 0 ? <div style={priceStyle}  className='secondColor-style'>${`${roundPrice(_.get(getExperinceData[0], 'price'))} ${_.get(getExperinceData[0], 'meta_details.currency')}`}</div> :<div style={priceStyle}  className='secondColor-style'>${`${roundPrice(_.get(getTempData, 'data.details.price'))} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</div> : <div style={priceStyle}  className='secondColor-style'>${`${roundPrice(_.get(getTempData, 'data.details.price'))} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</div>}
                      

                    
                      {handleProceed()&&<button className='diabled-proceed-btn'>Proceed to registration</button>}
                      {!handleProceed() && <button onClick={() => selectRedirect()} className='proceed-btn'>Proceed to registration</button>}
                      { disabledText() &&  <div style={notAvailableText}>This sessions isn't available right now , has past its last date to register or has sold out</div>}
                    
        </div>

      {/* without multiple  dates */}

      <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
        <div 
                style={{
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px',
                    
                  }}
                  className='sticky-header-title secondColor-style'
                >
                   Session schedule
                    </div>
                 
                  <div>
      { !_.isEmpty(getExperinceData) && _.size(getMerDate) > 0 ? getMerDate.map((item, index) => {
        const date = moment.tz(item, _.get(getExperinceData[0], 'meta_details.timezone'));
        const isPastDate = date.isBefore(moment.tz(_.get(getExperinceData[0], 'meta_details.timezone')));
        const dateClassName = isPastDate ? 'register-schedule-strike secondColor-style' : 'register-schedule secondColor-style';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getExperinceData[0], 'meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getExperinceData[0], 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getExperinceData[0], 'experience_end_date'), _.get(getExperinceData[0], 'meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  }) : !_.isEmpty(getItems) && _.size(_.get(getItems, 'session_dates')) > 0 && _.get(getItems, 'session_dates').map((item, index) => {
  
    const date = moment.tz(item, _.get(getItems, 'meta_details.timezone'));
    const isPastDate = date.isBefore(_.get(getItems, 'today'));
    const dateClassName = isPastDate ? 'register-schedule-strike secondColor-style' : 'register-schedule secondColor-style';
    return(
    <div
      key={index}
      className='card'
      style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
    >
      <div style={{display: "flex", flexDirection: "column"}}>
        <p className={dateClassName}>{moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
        <p className={dateClassName}>{`${moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}</p>
        </div>
    </div>
    )
})}
    </div>
      </Modal>
{/* expired session date */}
      <Modal width={300} closable={false} footer={null} open={isExpiredSessionDateModalOpen} onOk={expiredScheduleHandleOk} onCancel={expiredScheduleHandleCancel}>
        <div 
                style={{
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px',
                    
                  }}
                  className='sticky-header-title secondColor-style'
                >
                   Session schedule
                    </div>
                 
                  <div>
      { !_.isEmpty(getScheduledDate) && _.size(getScheduledDate) > 0 ? getScheduledDate.map((item, index) => {
  
        const date = moment.tz(item, _.get(getExperinceData[0], 'meta_details.timezone'));
        const isPastDate = date.isBefore(moment.tz(_.get(getTempData, 'data.details.meta_details.timezone')));
        const dateClassName = isPastDate ? 'register-schedule-strike secondColor-style' : 'register-schedule secondColor-style';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  }) : ""}
    </div>
      </Modal>
{emailLoginOpen?.visible ==true &&<EmailLogin 
onChangeFormModal ={(values)=>setemailLoginOpen(values)}
onCloseModal={(isBack)=>{setemailLoginOpen({visible:false,form:1});

isBack ==true &&setIsModalOpen(true)
}} 
onOpenLoginModal ={()=>setemailLoginOpen({visible:true,form:1})}
isModalOpen={emailLoginOpen}/>}
      <Modal 
      title={modalTitle}
      open={isModalOpen} 
      onOk={handleLoginOk} 
      onCancel={handleLoginCancel}
      footer={null}
      >
        <div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                    <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <button onClick={showModal} className='continue-with-phone-btn raise-btn'><CiMobile3 style={{width: "25px", height: "25px"}}/><div style={continue_with_phone_btn_text}>Continue with phone number</div></button>
        </div>
        <div onClick={()=>{
          handleLoginCancel()
          setemailLoginOpen({
            visible:true,form:1
          })
         
          localStorage.setItem('redirect_url',`/guest-profiles?id=${ _.get(getItems, 'id') ? _.get(getItems, 'id') : searchParams.get('id')}&handle=${searchParams.get('handle')}`)

        }} style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <div className='continue-with-email-style' style={{textDecorationLine: "none", color: 'var(--Gray-600, #475467)',fontSize: '16px',fontWeight: 600,lineHeight: '24px',cursor: 'pointer'}}>Continue with email</div>
        </div>
        <div className='login-popup-text secondColor-style' style={{marginTop: "24px"}}>
        By selecting “Continue with Google/Phone number/Email/”, you acknowledge that you have read and understood, and agree to Communit’s <a href='https://www.communiti.app/terms-of-service' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Terms of Service</a> and <a href='https://www.communiti.app/privacy-policy' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Privacy Policy</a>
        </div>
        </div>
        
      </Modal>
      <LoginWithPhone sendDataToParent={handleDataFromChild} isModalVisible={isModalVisible} handleCancel={handleCancel}  proceed={true} props={props} experience_id={_.get(getItems, 'id') ? _.get(getItems, 'id') : searchParams.get('id')} handle={searchParams.get('handle')}/>
    </div>
  )
}

const mapStateToProps = state => {
  return {
     
islogindata: _.get(state, 'app.account.isLogin'),
  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
  login: details => dispatch(global.redux.action.account.socialLogin(details)),
  otpLoginAPI: details => dispatch(global.redux.action.otpLogin.sendOtp(details)),
  checkProfileAPI: details => dispatch(global.redux.action.accountCheckProfile.checkProfile(details)),
  verifyOtpAPI: details => dispatch(global.redux.action.account.phoneLogin(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SessionCard)