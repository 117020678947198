import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, notification, Image } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { CiCircleInfo } from "react-icons/ci";
import { PiQuestionThin } from "react-icons/pi"
import { SlCheck } from 'react-icons/sl'
import '../create-profile/style.css'
import "./style.css";
import StickyBox from "react-sticky-box";
import CommonRightContainer from '../common-right-container';
import AuthFooter from '../../components/auth-footer'
import { TbArrowBackUp } from 'react-icons/tb';
const { Option } = Select;
const { TextArea } = Input;

const QuestionList = (props) => {
  let navigate = useNavigate();
  const childRef = useRef(null);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getTempData, setTempData] = useState({});
  const [getScheduledDate, setScheduledDate] = useState([]);
  const [pastDateCount, setPastDateCount] = useState(0);
  const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
  const [getComputeOrder, setComputeOrder] = useState({});
  const [getProfileDetails, setProfileDetails] = useState({});
  const [getExperinceQuestionPartOne, setExperinceQuestionPartOne] = useState([]);
  const [getExperinceQuestionPartTwo, setExperinceQuestionPartTwo] = useState([]);
  const [getGuestQuestion, setGuestQuestion] = useState([]);
  const [getAllQuestion, setAllQuestion] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [textAreaValues, setTextAreaValues] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [getFinal, setFinal] = useState([]);
  const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
  const [isScrollingByButton, setIsScrollingByButton] = useState(false);
const [showStickyButton, setShowStickyButton] = useState(true);


  const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
  const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = () => {
    if (isScrollingByButton) return; // Ignore scroll when triggered by the button

    if (childRef.current) {
      // Check for both proceed and disabled-proceed buttons
      const childButton = childRef.current.querySelector('.question-proceed-btn');
      const disabledChildButton = childRef.current.querySelector('.diabled-proceed-btn');

      if (childButton || disabledChildButton) {
        const childButtonPosition = (childButton || disabledChildButton).getBoundingClientRect().top;

        if (childButtonPosition <= window.innerHeight) {
          setShowStickyButton(false);
        } else {
          setShowStickyButton(true);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollingByButton]);

  const scrollToChild = () => {
    if (childRef.current) {
        childRef.current.scrollIntoView({ behavior: 'smooth' });
      
    }
  };

  useEffect(() => {
    getExperienceDetails();
    computeOrder();
    viewGuest();
   

  }, []);
  useEffect(() => {
    let count = 0;

    getScheduledDate.forEach((dateString) => {
      const date = moment.tz(dateString, timeZone);
      if (date.isBefore(today)) {
        count++;
      }
    });

    setPastDateCount(count);
  }, [getScheduledDate, today, timeZone]);

  useEffect(() => {
    // Add experience_type: 1 to Array_one and Array_two
    const modifiedArrayOne = getExperinceQuestionPartOne.map(item => ({ ...item, experience_type: 1 }));
    const modifiedArrayTwo = getExperinceQuestionPartTwo.map(item => ({ ...item, experience_type: 1 }));

    // Add experience_type: 2 to Array_three
    const modifiedArrayThree = _.map(getGuestQuestion, item => ({ ...item, experience_type: 2 }));

    // Merge all three arrays into a single one
    const mergedArray = [...modifiedArrayOne, ...modifiedArrayTwo, ...modifiedArrayThree];
    const only2Array = [...modifiedArrayOne, ...modifiedArrayTwo];

    setAllQuestion(mergedArray);

    // Function to remove duplicates based on the 'id' property
    function removeDuplicates(array) {
      const uniqueArray = [];
      const uniqueIds = new Set();

      for (const item of array) {
        if (!uniqueIds.has(item.id)) {
          uniqueArray.push(item);
          uniqueIds.add(item.id);
        }
      }

      return uniqueArray;
    }

    const uniqueData = removeDuplicates(only2Array);


    const updatedJunctionOne = uniqueData.map(item1 => {
      const matchingItem = modifiedArrayThree.find(item2 => item2.experience_registration_info === item1.id);
      if (matchingItem) {
        if(item1?.type=="multichoice"){
          let other_include = `${matchingItem?.registration_info_value}`.includes("#Others-")
          if(other_include==true){
            item1.value = "#Others";
            item1.other_value=splitOthers(matchingItem?.registration_info_value)
          }else if(other_include==false){
            item1.value = matchingItem.registration_info_value;

          }

        }else if(item1?.type=="checkbox"){
          let other_include = `${matchingItem?.registration_info_value}`.includes("#Others-");
          let spilt_value = `${matchingItem?.registration_info_value}`.split(",")
          if(other_include==true){
        spilt_value.pop()
         item1.value = "#Others";
         item1.other_value=splitOthers(_.last(`${matchingItem?.registration_info_value}`.split(",")))
         item1.values =[...spilt_value,"#Others"]           
          }else if(other_include==false){
            item1.value = "#Others";
            let spilt_value = `${matchingItem?.registration_info_value}`.split(",")
            item1.values =[...spilt_value]    

          }
        }else{
          item1.value = matchingItem.registration_info_value;
        }

        
        
        item1.update_id = matchingItem.id
        
      }
      return item1;
    });
    // _.uniqBy(updatedJunctionOne, 'id');

    // If you want to update the state with the modified array, use useState and setJunctionOne
    // setJunctionOne(updatedJunctionOne);

    setFinal(updatedJunctionOne)
    // setTimeout(() => {
    //   if(_.size(updatedJunctionOne)==0){

    //   }
    // }, 2000);


  }, [getExperinceQuestionPartOne, getExperinceQuestionPartTwo, getGuestQuestion]);

  useEffect(() => {
    // Check if any required fields are not filled or unclicked
    const isAnyRequiredNotFilled = getFinal.some(
      (item) => item.is_required && (!textAreaValues[item.id] && !selectedCard[item.id])
    );

    // Enable or disable the button based on the condition
    setIsButtonEnabled(!isAnyRequiredNotFilled);
  }, [textAreaValues, selectedCard]);


  const getExperienceDetails = () => {
    props.viewSessionAPI({
      params: {
        id: searchParams.get("id"),
        expand: "template,template_photos,host",
        is_price_porated: 1
      },
      callback: (res, value) => {
        if (res) {
        if(_.get(value, 'data.details.meta_details.registration_info_set_id')){
          questionListData(_.get(value, 'data.details.meta_details.registration_info_set_id'))
        }else{
          // questionListData(5)
          questionData({ category: _.get(value, 'data.details.template.category'),
          experience_type:  _.get(value, 'data.details.template.type')}, _.get(value, 'data.details.host.organization')? _.get(value, 'data.details.host.organization'):0);
        }
          
          setTempData(value)
          let start_date_item = _.get(value, 'data.details.experience_date');
          let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
          let concated_array = scheduled_date_items
          setScheduledDate(concated_array.filter(item => item !== "" && item !== null))

        }
      }
    })
  };

  const computeOrder = () => {
    props.experienceComputeOrderAPI({
      body: {
        experience: searchParams.get("id"),
        profile: props?.orderCreateData?.profile
      },
      callback: (res, data) => {
        if (!_.isEmpty(_.get(data, 'details'))) {
          setComputeOrder(_.get(data, 'details'))
        }
      }
    })
  };

  const showSessionDateModal = () => {
    setIsSessionDateModalOpen(true)
  };
  const scheduleHandleOk = () => {
    setIsSessionDateModalOpen(false)
  }

  const scheduleHandleCancel = () => {
    setIsSessionDateModalOpen(false)
  };

  const viewGuest = () => {
    props.viewGuestProfilesAPI({
      params: {
        id: props?.orderCreateData?.profile
      },
      callback: (res, data) => {
        if (!_.isEmpty(_.get(data, 'data.details'))) {
          setProfileDetails(_.get(data, 'data.details'))
        }
      }
    })
  };

  const editProfile = () => {
    navigate(`/edit-profile?id=${searchParams.get("id")}&p_id=${props?.orderCreateData?.profile}&from=questions&category=${searchParams.get("category")}&type=${searchParams.get("type")}`);
  }

  const chooseAnotherProfile = () => {
    navigate(`/guest-profiles?id=${searchParams.get("id")}`);
  };

  const questionListData = (id) => {
    props.questionList({
      params: {
        limit: 10000,
        sort: "sort_order.asc",
        experience_registration_info_set_id:id
      },
      callback: (res, data) => {
        if (res) {

          setExperinceQuestionPartOne(_.get(data, 'data.items'))
       
                if (_.size(_.get(data, 'data.items')) == 0 && _.size(_.get(data, 'data.items')) == 0) {
                  props?.onChangeScreen(3, getFinal)
                }
               
                props.guestQuestionListAPI({
                  params: {
                    limit: 10000,
                    profile: props?.orderCreateData?.profile,
                    experience_registration_info_set_id:id
                  },
                  callback: (res, val) => {
                    if (res) {
                      let filter_type_question =[]
                      // setGuestQuestion(filter_type_question)
                      _.map(_.get(val, 'data.items'),(item)=>{

                        if(item?.type=="multichoice"){
                          let other_include = `${item?.registration_info_value}`.includes("#Others-")
                          
                          if(other_include==true){
                            filter_type_question.push({...item,registration_info_value:"#Others",other_value:splitOthers(item?.registration_info_value)})
                          }else if(other_include==false){
                            filter_type_question.push(item)

                          }

                        }else if(item?.type=="checkbox"){
                          let other_include = `${item?.registration_info_value}`.includes("#Others-");
                          let spilt_value = `${item?.registration_info_value}`.split(",")
                          if(other_include==true){
                            // const index = _.indexOf(spilt_value, 'Others');

                         [...spilt_value].pop()
                            filter_type_question.push({...item,registration_info_value:"#Others",
                            other_value:splitOthers(_.last(`${item?.registration_info_value}`.split(","))),values:[...spilt_value,"#Others"]})
                          }else if(other_include==false){
                            let spilt_value = `${item?.registration_info_value}`.split(",")
                            filter_type_question.push({...item,values:spilt_value})

                          }
                        }else{
                          filter_type_question.push(item)
                        }

                      })

                      setGuestQuestion(filter_type_question)
                      
                    }
                  }
                })
              }
            }
          })
        }
     

  const questionData = (params,organization) => {
    props.experienceQuestionListsAPI({
      params: {
        organization: 0,
        limit: 10000,
        sort: "sort_order.asc",
        ...params
      },
      callback: (res, data) => {
        if (res) {

          setExperinceQuestionPartOne(_.get(data, 'data.items'))
          props.experienceQuestionListsAPI({
            params: {
              organization: organization,
              limit: 10000,
              sort: "sort_order.asc",
              ...params

            },
            callback: (res, val) => {

              if (res) {
                if (_.size(_.get(val, 'data.items')) == 0 && _.size(_.get(data, 'data.items')) == 0) {
                  props?.onChangeScreen(3, getFinal)
                }
                setExperinceQuestionPartTwo(_.get(val, 'data.items'))
                props.guestQuestionListAPI({
                  params: {
                    // category: searchParams.get("category"),
                    // organization: 0,
                    limit: 10000,
                    profile: props?.orderCreateData?.profile
                  },
                  callback: (res, val) => {
                    if (res) {
                      setGuestQuestion(_.get(val, 'data.items'))
                    }
                  }
                })
              }
            }
          })
        }
      }
    })

  };

  const handleCardClick = (itemId, objectType) => {
    if (selectedCard[objectType] === itemId) {
      // Clicked on an already selected card in this object, deselect it
      setSelectedCard({ ...selectedCard, [objectType]: null });
    } else {
      // Clicked on a different card in this object, select it
      setSelectedCard({ ...selectedCard, [objectType]: itemId });
    }
  };

  const onTextAreaChange = (objectId, value) => {

    setTextAreaValues({ ...textAreaValues, [objectId]: value });
  };

  const handleButtonClick = () => {
    // Create an array of objects from textAreaValues and selectedCards
    const dataArray = [];

    // Add objects from textAreaValues
    Object.keys(textAreaValues).forEach(key => {
      dataArray.push({
        experience_registration_info: key,
        registration_info_value: textAreaValues[key],
        profile: props?.orderCreateData?.profile,
        organization: searchParams.get("organization") ? searchParams.get("organization") : null,
        // label: 
      });
    });

    // Add objects from selectedCards
    Object.keys(selectedCard).forEach(key => {
      dataArray.push({
        experience_registration_info: key,
        registration_info_value: selectedCard[key],
        profile: props?.orderCreateData?.profile,
        organization: searchParams.get("organization") ? searchParams.get("organization") : null
      });
    });

    // Now, you can send dataArray to the desired function or API
    // For example, you can send it as props.createAnswers(dataArray)
    if (_.size(dataArray) > 0) {
      props.setQuestionsAnswerRedux({
        value: dataArray
      })
      localStorage.setItem('questions_answers', JSON.stringify(dataArray));
      localStorage.setItem('question_redux', JSON.stringify(getFinal))
      // dataArray.map((value, index) => {
      //   props.guestQuestionCreateAPI({
      //     body:{
      //       experience_registration_info: value.experience_registration_info,
      //       registration_info_value: value.registration_info_value,
      //       profile: value.profile
      //     },
      //     callback:(res, val) => {
      //     }
      //   })
      // })
    } else {
      localStorage.clear();
    }

    setTimeout(() => {
      navigate(`/payment?id=${searchParams.get("id")}&p_id=${props?.orderCreateData?.profile}`)
    })
  };

  const setRegistrationValue = (id, value,type) => {
    let myArray = [...getFinal]
    let objIndex = myArray.findIndex((obj => obj.id == id))
    myArray[objIndex]['value'] = value
    if(type=="checkbox"){
      let includeCheck = _.includes(myArray[objIndex]['values'],value)
      const index = _.indexOf(myArray[objIndex]['values'], value);
     
if(includeCheck==true){
  myArray[objIndex]['values'].splice(index, 1);

  // myArray[objIndex]['values'] = myArray[objIndex]['values']
}else{
  myArray[objIndex]['values'] = _.isArray(myArray[objIndex]['values']) ?_.size(myArray[objIndex]['values'])>0 ?[...myArray[objIndex]['values'],value]:[value]:[value] 
}
    }
    
    setFinal(myArray)
  };

  const disableCondition = () => {
    let filter_item = _.filter(getFinal, (v) => v?.is_required == true)
    let required_value = _.filter(getFinal, (v) => v?.value)

let filter_items =[]
_.map(filter_item,(item,index)=>{

  if(item?.type=="multichoice"){
    if(item?.value=="#Others" &&item?.other_value){
      filter_items.push(item)
    }else if(item?.value!="#Others" && item?.value){
      filter_items.push(item)
    }

  }else if(item?.type=="checkbox"){
 let checkbox_values = _.includes(_.get(item,'values'),"#Others")  
 
 if(checkbox_values==true && item?.other_value){
  filter_items.push(item)
 }else if(checkbox_values==false && _.size(item?.values)>0){
  filter_items.push(item)
 }
    
  }else if(item?.value){
    filter_items.push(item)
  }


})

    // let filter_items = []
    // _.map(_.map(filter_item, 'id'), (v, i) => {
    //   _.map(_.map(required_value, 'id'), (v1, i1) => {
    //     if (v == v1) {
    //       filter_items.push(v)
    //     }
    //   })


    // })
    let a = _.size(filter_items) == _.size(filter_item) ? true : false
    return a

  }

  const createRegistrationInfoDetail = (a, b, i, sizeFilter) => {
    
    props.guestQuestionCreateAPI({
      body: {
        "experience_registration_info": a,
        "registration_info_value": b,
        profile: props?.orderCreateData?.profile
      },
      callback: (res, data) => {

        if (i + 1 == sizeFilter) {
          props?.onChangeScreen(3, getFinal)
          localStorage.setItem('question_redux', JSON.stringify(getFinal));
    }

      }
    })
  }

  const updateRegistrationInfoDetail = (a, b, i, sizeFilter) => {
    
    props.guestQuestionUpdateAPI({
      body: {
        "registration_info_value": b ? b : null,
        id: a
      },
      callback: (res, data) => {

        if (i + 1 == sizeFilter) {
          props?.onChangeScreen(3, getFinal)
          localStorage.setItem('question_redux', JSON.stringify(getFinal));

        }

      }
    })
  }

  const proceedNext = () => {

    if (_.size(getFinal) > 0) {
      const filteredArray = getFinal.filter(item => item.value !== undefined);
      filteredArray.map((v, i) => {
        if (_.get(v, 'update_id')) {
          if(_.get(v,'type')=="multichoice" &&_.get(v,'value')=="#Others"){
            updateRegistrationInfoDetail(_.get(v, 'update_id'), `#Others-${_.get(v,'other_value')}`, i, _.size(filteredArray))
          }else if(_.get(v,'type')=="checkbox"){
            let checkbox_values = _.includes(_.get(v,'values'),"#Others")
            if(checkbox_values==true){

            let abc =   _.filter(_.get(v,'values'),(v)=>v!="#Others")
              let final_Value = [...abc,`#Others-${_.get(v,'other_value')}`].join(",")
              updateRegistrationInfoDetail(_.get(v, 'update_id'), final_Value, i, _.size(filteredArray))
            }else{
              let final_Value = [..._.get(v,'values')].join(",")
              updateRegistrationInfoDetail(_.get(v, 'update_id'), final_Value, i, _.size(filteredArray))
            }

          }else{
            updateRegistrationInfoDetail(_.get(v, 'update_id'), _.get(v,'value'), i, _.size(filteredArray))
          }
         
        } else {
          if(_.get(v,'type')=="multichoice" &&_.get(v,'value')=="#Others"){
          createRegistrationInfoDetail(_.get(v, 'id'), `#Others-${_.get(v,'other_value')}`, i, _.size(filteredArray))
          }else if(_.get(v,'type')=="checkbox"){
            let checkbox_values = _.includes(_.get(v,'values'),"#Others")
            if(checkbox_values==true){
              
              let abc =   _.filter(_.get(v,'values'),(v)=>v!="#Others")
              
              let final_Value = [...abc,`#Others-${_.get(v,'other_value')}`].join(",")
              createRegistrationInfoDetail(_.get(v, 'id'), final_Value, i, _.size(filteredArray))
            }else{
              let final_Value = [..._.get(v,'values')].join(",")
              createRegistrationInfoDetail(_.get(v, 'id'), final_Value, i, _.size(filteredArray))
            }

          }else{
            createRegistrationInfoDetail(_.get(v, 'id'), _.get(v,'value'), i, _.size(filteredArray))
          }
        }
      })
    }
  };

  const showProratedModal = () => {
    setIsProratedModalOpen(true);
  }

  const handleProratedOk = () => {
    setIsProratedModalOpen(false)
  }

  const handleProratedCancel = () => {
    setIsProratedModalOpen(false)
  }
const splitOthers =(value)=>{
  if(`${value}`.includes("#Others-")){
    let a = `${value}`.split("#Others-")
    return _.last(a)
  }else{
    return null
  }
  
}

  const questionListRender =(val)=>{
if(val.type === 'dropdown'){
      return  <div>
          <div className='question-label-title secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.label}{val.is_required && <span style={{ marginRight: "5px", fontSize: windowSize.width<=768?"0.875rem": '' }}>*</span>}</div>
          {val.description && <div className='question-description-des secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.description}</div>}
          <Select
          value={val?.value || undefined}
      style={{
        width: "100%",
        marginTop:'16px',
        borderColor:'#EF6820',
        fontSize: windowSize.width<=768? "0.875rem":'1rem'

      }}
placeholder="Choose an option"
      className='question-dropdown-container'
      onChange={(value)=>{
        setRegistrationValue(val.id, value)
      }}
      options={_.map(val.options,(v)=>({ value: _.get(v, 'value'),
      label:  _.get(v, 'value')}))}
    />
          {/* {val.options.map(option => {
            return (
              <div
                key={option.value}
                className={`card`}
                style={{
                  boxShadow: "none",
                  marginBottom: "16px",
                  color: val?.value == _.get(option, 'value') ? "#EF6820" : "#475467",
                  cursor: "pointer",
                  marginTop: '16px',
                  border: val?.value == _.get(option, 'value') ? "1px solid #EF6820" : "1px solid #EAECF0",
                  borderRadius: '12px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px'
                }}
                onClick={() => setRegistrationValue(val.id, option.value)}
              //  onClick={() => handleCardClick(option.value, val.id)}
              >
                {option.value}
              </div>
            )

          })} */}
        </div>
    }else if(val?.type=='text'){
     return <div><div className='question-label-title secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.label}{val.is_required && <span style={{ marginRight: "5px", fontSize: windowSize.width<=768?"0.875rem": '' }}>*</span>}</div>
     {val.description && <div className='question-description-des secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.description}</div>}
                            <TextArea
                              className='question-textarea secondColor-style'
                              placeholder='Enter your answer here..'

                              autoSize={{ minRows: 1, maxRows: 7 }}
                              // style={{ height: '48px',paddingTop:'12px',paddingBottom:'12px' }}
                              style={{ marginTop: '16px', marginBottom: '16px', paddingTop: '12px', paddingBottom: '12px', height: '48px', fontSize: windowSize.width<=768? "0.875rem":'1rem' }}
                              onChange={(e) => setRegistrationValue(val.id, e.target.value)}
                              value={_.get(val, 'value')}
                            // value={textAreaValues[val.id] || val.value}
                            // rows={4} 
                            />
                          </div>

    }else if(val.type == "multichoice"){
      return  <div>
          <div className='question-label-title secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.label}{val.is_required && <span style={{ marginRight: "5px" }}>*</span>}</div>
          {val.description && <div className='question-description-des secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.description}</div>}
          {val.options.map((option,optionIndex) => {
            if(_.get(option, 'value')=="#Others" && _.size(val.options)==optionIndex+1){
              
              return (
                <div
                  key={option.value}
                  className={`card`}
                  style={{
                   
                    boxShadow: "none",
                    marginBottom: "16px",
                    color: val?.value == _.get(option, 'value') ? "#EF6820" : "#475467",
                    cursor: "pointer",
                    marginTop: '16px',
                    border: val?.value == _.get(option, 'value') ? "1px solid #EF6820" : "1px solid #EAECF0",
                    borderRadius: '12px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px',
                    fontSize: windowSize.width<=768? "0.875rem":'1rem'
                  }}
                  
                //  onClick={() => handleCardClick(option.value, val.id)}
                >
                 <div style={{display:'flex',alignItems:'center'}}>
                 <span 
                 onClick={() => setRegistrationValue(val.id, option.value)}
                 style={{marginRight:'8px'}}>
                  {val?.value == _.get(option, 'value')? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FEF6EE"/>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#EF6820"/>
    <circle cx="8" cy="8" r="3" fill="#EF6820"/>
  </svg> : <div className='multiple-choice-class' />}
  
                    </span> <span
                    onClick={() => setRegistrationValue(val.id, option.value)}
                    className="questions-options-title" style={{color:val?.value == _.get(option, 'value') ? "#EF6820" : "#475467",
                    width:val?.value == _.get(option, 'value')==true?"8%":"100%", fontSize: windowSize.width<=768?"0.875rem" : ""

                    }}>{"Other"} - </span>
                    
                   {val?.value == _.get(option, 'value')==true &&<span style={{width:'85%',marginLeft:'2px'}}> 
                   <Input 
                   
                   placeholder={val?.value == _.get(option, 'value')==true?"Enter your answer here..":""}
                   value={_.get(val, 'other_value')}
                   readOnly={val?.value == _.get(option, 'value')==true?false:true}
                   onChange={(e)=>{
                    let myArray = [...getFinal]
                    let objIndex = myArray.findIndex((obj => obj.id == val?.id))
                    myArray[objIndex]['other_value'] =e.target.value

                    setFinal(myArray)
                   }}
                   className='question-input-class' style={{border:'none',width:'100%',outline:'none',
                   borderBottomWidth:val?.value == _.get(option, 'value')==true?1:0,borderBottomColor:val?.value == _.get(option, 'value')==true?"#475467":"",
                   borderBottom:val?.value == _.get(option, 'value')==true?"1px solid rgb(71, 84, 103)":"none",
                   borderBottomRightRadius:'0px',borderBottomLeftRadius:'0px', fontSize: windowSize.width<=768? "0.875rem":'1rem'
                   }} /></span>}
                 </div>
                </div>
              )
            }else{
              return (
                <div
                  key={option.value}
                  className={`card`}
                  style={{
                   
                    boxShadow: "none",
                    marginBottom: "16px",
                    color: val?.value == _.get(option, 'value') ? "#EF6820" : "#475467",
                    cursor: "pointer",
                    marginTop: '16px',
                    border: val?.value == _.get(option, 'value') ? "1px solid #EF6820" : "1px solid #EAECF0",
                    borderRadius: '12px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px'
                  }}
                  onClick={() => setRegistrationValue(val.id, option.value)}
                //  onClick={() => handleCardClick(option.value, val.id)}
                >
                 <div style={{display:'flex',alignItems:'center'}}>
                 <span style={{marginRight:'8px'}}>
                  {val?.value == _.get(option, 'value')? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FEF6EE"/>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#EF6820"/>
    <circle cx="8" cy="8" r="3" fill="#EF6820"/>
  </svg> : <div className='multiple-choice-class' />}
  
                    </span> <span className="questions-options-title" style={{color:val?.value == _.get(option, 'value') ? "#EF6820" : "#475467", fontSize: windowSize.width<=768?"0.875rem" : ""}}>{option.value}</span>
                 </div>
                </div>
              )
            }
           

          })}
        </div>

    }else if(val.type == "checkbox"){
      
      return  <div>
          <div className='question-label-title secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.label}{val.is_required && <span style={{ marginRight: "5px", fontSize: windowSize.width<=768?"0.875rem": '' }}>*</span>}</div>
          {val.description && <div className='question-description-des secondColor-style' style={{fontSize: windowSize.width<=768?"0.875rem": ''}}>{val.description}</div>}
          {val.options.map((option,optionIndex) => {
            if(_.get(option, 'value')=="#Others" && _.size(val.options)==optionIndex+1){
              return (
                <div
                  key={option.value}
                  className={`card`}
                  style={{
                   
                    boxShadow: "none",
                    marginBottom: "16px",
                    color: _.includes(_.get(val, 'values'),option?.value)== true ? "#EF6820" : "#475467",
                    cursor: "pointer",
                    marginTop: '16px',
                    border: _.includes(_.get(val, 'values'),option?.value)== true? "1px solid #EF6820" : "1px solid #EAECF0",
                    borderRadius: '12px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px'
                  }}
                  
                //  onClick={() => handleCardClick(option.value, val.id)}
                >
                 <div style={{display:'flex',alignItems:'center'}}>
                 <span
                 onClick={() => setRegistrationValue(val.id, option.value,val.type)}
                 style={{marginRight:'8px'}}>
                  {_.includes(_.get(val, 'values'),option?.value)== true? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#FEF6EE"/>
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#EF6820"/>
  <path d="M12 5L6.5 10.5L4 8" stroke="#EF6820" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  : <div className='multiple-checkbox-class' />}
  
                    </span> <span
                    onClick={() => setRegistrationValue(val.id, option.value,val.type)}
                    className="questions-options-title" style={{color:_.includes(_.get(val, 'values'),option?.value)== true? "#EF6820" : "#475467",
                    width:_.includes(_.get(val, 'values'),option?.value)== true?"8%":"100%", fontSize: windowSize.width<=768?"0.875rem" : ""

                    }}>{"Other"} - </span>
                    {_.includes(_.get(val, 'values'),option?.value)== true &&<span style={{width:'85%',marginLeft:'2px'}}> <Input 
                    readOnly={_.includes(_.get(val, 'values'),option?.value)== true?false:true}
                    value={_.get(val, 'other_value')}
                     onChange={(e)=>{
                      let myArray = [...getFinal]
                      let objIndex = myArray.findIndex((obj => obj.id == val?.id))
                      myArray[objIndex]['other_value'] =e.target.value
  
                      setFinal(myArray)
                     }}
                     placeholder={_.includes(_.get(val, 'values'),option?.value)== true?"Enter your answer here..":""}
                    className='question-input-class' style={{border:'none',width:'100%',outline:'none',
                    
                    borderBottomWidth:_.includes(_.get(val, 'values'),option?.value)== true?1:0,borderBottomColor:_.includes(_.get(val, 'values'),option?.value)== true?"#475467":"",
                    borderBottom:_.includes(_.get(val, 'values'),option?.value)== true?"1px solid rgb(71, 84, 103)":"none",
                    borderBottomRightRadius:'0px',borderBottomLeftRadius:'0px',
                    border:"none",
                    
                    }} /></span>}
                 </div>
                </div>
              )
            }else{
              return (
                <div
                  key={option.value}
                  className={`card`}
                  style={{
                    boxShadow: "none",
                    marginBottom: "16px",
                    color: _.includes(_.get(val, 'values'),option?.value)== true ? "#EF6820" : "#475467",
                    cursor: "pointer",
                    marginTop: '16px',
                    border: _.includes(_.get(val, 'values'),option?.value)== true? "1px solid #EF6820" : "1px solid #EAECF0",
                    borderRadius: '12px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', paddingRight: '12px'
                  }}
                  onClick={() => setRegistrationValue(val.id, option.value,val.type)}
                //  onClick={() => handleCardClick(option.value, val.id)}
                >
                 <div style={{display:'flex',alignItems:'center'}}>
                 <span style={{marginRight:'8px'}}>
                  {_.includes(_.get(val, 'values'),option?.value)== true? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#FEF6EE"/>
  <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#EF6820"/>
  <path d="M12 5L6.5 10.5L4 8" stroke="#EF6820" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  : <div className='multiple-checkbox-class' />}
  
                    </span> <span className="questions-options-title" style={{color:_.includes(_.get(val, 'values'),option?.value)== true? "#EF6820" : "#475467", fontSize: windowSize.width<=768?"0.875rem" : ""}}>{option.value}</span>
                 </div>
                </div>
              )
            }
            

          })}
        </div>

    }
     

  }

  const render = () => {
    const uniqueData = _.uniqBy(getFinal, 'experience_registration_info');

    return (
      <div>

        <div className="banner-container-profile">
          <div className='container'>

            <div className='row'>
            { windowSize.width <=900 && <div className="col-lg-4">
                {/* <div style={{marginTop: "16px"}}> */}
                <StickyBox offsetTop={16} offsetBottom={20}>
                  <CommonRightContainer orderCreateData={props?.orderCreateData} />
                </StickyBox>
                {/* </div> */}
              </div>}
              <div className='col-lg-8' style={{ marginBottom: '45px' }}>


                <div style={{ display: "flex", flexDirection: 'column' }}>
                  <div style={{ display: "flex", marginLeft: '1px' }}>
                    <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../assets/img/step-2.png')} />
                    <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                      <div className='profile-heading secondColor-style'>Athlete profile</div>
                      {
                        <div className='profile-description secondColor-style' style={{ display: 'flex', flexDirection: 'row', fontSize: windowSize.width<=768 ? "0.875rem" : '' }}>{_.startCase(_.get(props, 'orderCreateData.profile_details.first_name')) + ' ' + _.startCase(_.get(props, 'orderCreateData.profile_details.last_name'))}... <div style={{ textDecorationLine: 'underline', cursor: 'pointer', marginLeft: '5px' }}
                          onClick={() => {
                            props?.onChangeScreen(1, getFinal)
                          }}
                        >Change</div></div>}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Image className='step-images-1' style={{ width: '32px', height: '66px' }} preview={false} src={require('../../assets/img/steps-1.png')} />
                    <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                      <div className='profile-heading secondColor-style'>Additional information</div>
                      {
                        <div style={{fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='profile-description secondColor-style'>Answer some questions</div>}
                    </div>
                  </div>
                </div>
                <div>
                  {getFinal.map(val => {
                    return (
                      <div key={val.id}>
                        {questionListRender(val)}
                      </div>
                    )
                  })}
                </div>

                <div ref={childRef}>
                  <Button
                    className='question-proceed-btn'
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "8px",
                      backgroundColor: disableCondition() ? "#e85d04" : "#F2F4F7",
                      color: disableCondition() ? "#fff" : "#D0D5DD",
                      borderRadius: '12px',
                      fontWeight: 600,
                      fontSize: '1rem'

                    }}

                    onClick={() => proceedNext()}
                    disabled={disableCondition() ? false : true}

                  >Proceed to next section</Button>
                </div>
                <Button
                  className='ant-back-button-container'
                  style={{
                    width: "100%",
                    height: "44px",
                    backgroundColor: "#FFF",
                    color: "#475467",
                    borderRadius: '12px',
                    fontWeight: 400,
                    fontSize: '1rem',
                    marginRight: '12px',
                    border: 'none',
                    marginTop: '16px'

                  }}
                  onClick={() => {
                    props?.onChangeScreen(1, getFinal)
                  }}
                ><TbArrowBackUp size={20} color={"#475467"} style={{ marginTop: -3 }} /> back</Button>
              </div>
             { windowSize.width >=900 && <div className="col-lg-4">
                {/* <div style={{marginTop: "16px"}}> */}
                <StickyBox offsetTop={16} offsetBottom={20}>
                  <CommonRightContainer orderCreateData={props?.orderCreateData} />
                </StickyBox>
                {/* </div> */}
              </div>}
            </div>
          </div>

        </div>
        <div>
          <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
            <p className='register-location'>Session Schedule</p>

            <div>
              {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
                const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
                const isPastDate = date.isBefore(today);
                const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
                return (
                  <div
                    key={index}
                    className='card'
                    style={{ backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px' }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
                      <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </Modal>
        </div>
        <div>
          <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              This session has already started and the price has been prorated. View the schedule to see the dates remaining
            </div>
          </Modal>
        </div>
        {showStickyButton && windowSize.width <=768 && (
        <div style={{padding: "16px"}}>
        <button className="sticky-btn" onClick={scrollToChild}>
        Proceed to registration
        </button>
        </div>
      )}
        {/* <AuthFooter/> */}
      </div>
    )
  };
  return render();

}

const mapStateToProps = state => {
  return {


  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
  viewGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesView(details)),
  experienceQuestionListsAPI: details => dispatch(global.redux.action.question.experienceQuestionList(details)),
  guestQuestionListAPI: details => dispatch(global.redux.action.question.guestQuestionList(details)),
  guestQuestionCreateAPI: details => dispatch(global.redux.action.question.guestAnswerCreate(details)),
  guestQuestionUpdateAPI: details => dispatch(global.redux.action.question.guestAnswerUpdate(details)),
  setQuestionsAnswerRedux: details =>
    dispatch(global.redux.action.settings.setQuestionsAnswer(details)),

    questionList: details => dispatch(global.redux.action.question.questionList(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(QuestionList)