import { Image,Card, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
// import { RiArrowGoBackFill } from "react-icons/ri";
import AuthFooter from '../../components/auth-footer'
import PaymentPendingcreen from './payment-failed.js';
import { TbArrowBackUp } from 'react-icons/tb';
import NotFoundPage from '../../components/common-not-found-pafe'

const PaymentSuccess = (props) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderDetails, setorderDetails] = useState({});
  const [isNotFound, setIsNotFound] = useState();
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);


useEffect(() => {
  navigate(`/success?order_id=${searchParams.get('order_id')}&handle=${searchParams.get('handle')}`);
}, [])

  useEffect(() => {
    // window.history.replaceState({}, undefined, "/iyyappan");
    // window.history.pushState({}, undefined, "/iyyappan");
    if(searchParams.get('order_id')){
      getExperienceDetails(searchParams.get('order_id'))
    }
    // navigate('/success?order_id=3860&handle=337&id=4953&payment_intent=pi_3Oh13iEK4MLKCsXA04ci10MJ&payment_intent_client_secret=pi_3Oh13iEK4MLKCsXA04ci10MJ_secret_OluprDpmQ7JggLJZHgXGvjF2S&redirect_status=succeeded&test=true',{replace:true})
   
  }, [])

  
  const getExperienceDetails = (id) => {
    props.orderView({
        params: {
          id: id,
          expand: "experience,experience_template,guest",
      
        },
        callback: (res, value) => {
          console.log('is_not', value);

          if(_.get(value, 'error.statusCode') == 404){
            setIsNotFound(true);
          } else if(_.get(value, 'error.statusCode') == 401){
            setIsNotFound(true);
          }
          
          if(res && _.get(value,'data.details.id')){
            let a ={
              id:_.get(value,'data.details.id'),
              email:_.get(value,'data.details.guest.contact_details.email'),
              name:_.get(value,'data.details.experience.template.title'),
              start_date:moment(_.get(value,'data.details.experience.experience_date')).tz(!_.isEmpty(_.get(value,'data.details.experience.meta_details.timezone')) ?_.get(value,'data.details.experience.meta_details.timezone') : "America/Toronto").format('MMM DD, YYYY') +' - '+ 
              moment(_.get(value,'data.details.experience.experience_end_date')).tz(!_.isEmpty(_.get(value,'data.details.experience.meta_details.timezone')) ?_.get(value,'data.details.experience.meta_details.timezone') : "America/Toronto").format('MMM DD, YYYY'),
              start_time:moment.tz(_.get(value,'data.details.experience.experience_date'), !_.isEmpty(_.get(value,'data.details.experience.meta_details.timezone')) ?_.get(value,'data.details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm A z") +' to '+
              moment.tz(_.get(value,'data.details.experience.experience_end_date'), !_.isEmpty(_.get(value,'data.details.experience.meta_details.timezone')) ?_.get(value,'data.details.experience.meta_details.timezone') : "America/Toronto").format("hh:mm A z"),
              guest_name:_.startCase(_.get(value,'data.details.profile_info.first_name')) +' '+ _.get(value,'data.details.profile_info.last_name'),
              gender:_.startCase(_.get(value,'data.details.profile_info.gender')),
              dob:_.get(value,'data.details.profile_info.date_of_birth'),
              order_paid:_.get(value,'data.details.status')==2 && _.get(value,'data.details.paid')==0 ?false:true,
              experience_type:_.get(value,'data.details.experience.type'),
              status: _.get(value,'data.details.status')


            }
            if( _.get(value,'data.details.paid')!=0){
              getTransactionDetails(_.get(value,'data.details.id'),a)
            }else{
              setorderDetails(a)
            }
          
           
            
          } 
        }
      })
};
const getTransactionDetails = (id,details) => {
  props.transList({
      params: {
        user_type: 0,
        order:id,
        limit:1000,
    
      },
      callback: (res, value) => {
        
        let payment=_.get(value,'data.items[0]')
        if(res){
          let data = {
            amount: `$${_.get(payment, 'amount')}`,
            cardType: _.get(payment, 'payment_object.charges.data[0].payment_method_details.type')=="afterpay_clearpay"?"Afterpay":
            _.get(payment, 'payment_object.charges.data[0].payment_method_details.type')=="affirm"?"Affirm":
            
            _.startCase(_.get(payment, 'payment_object.charges.data[0].payment_method_details.card.network')),
            cardNumber: _.get(payment, 'payment_object.charges.data[0].payment_method_details.card.last4'),
            currency: _.upperCase(_.get(payment, 'payment_object.charges.data[0].currency')),
            isCard: _.get(payment, 'payment_object.charges.data[0].payment_method_details.type')=="afterpay_clearpay"?false:
            _.get(payment, 'payment_object.charges.data[0].payment_method_details.type')=="affirm"?false:
            
            true,
          }
          let a ={...details,payment_details:data}

          setorderDetails(a)
         
          
        }
      }
    })
};

const redirectHandle = () => {
  // navigate(0);
  
  window.location.replace(`/${searchParams.get('handle')}`);
  // navigate(`/${searchParams.get('handle')}`,{replace:true,state:{screenLoad:true}})
  // window.location.reload()
}
  return (
<div>
{isNotFound && <NotFoundPage/>}
{!isNotFound && <div className="banner-container-profile" style={{marginBottom:'80px'}}>
  <div className='container' style={{marginBottom:'32px'}}>
  {/* faild-payment.png */}
  {/* <PaymentPendingcreen/> */}
    {_.get(orderDetails,'id') ? <div className='order-confirmation-layout'>
      { _.get(orderDetails,'order_paid')==true ?
      <div className='fullWidth-orderPage'>
      <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
        <Image preview={false} style={{width:'48px',height:'48px'}} src={require('../../assets/img/order-checked.png')} />
        <div className='order-confirm-title firstColor-style' style={{marginTop:'20px',fontSize:'1.5rem'}}>
        Order Confirmed
        </div>
        <div className='order-confirm-des secondColor-style' style={{marginTop:'8px',fontSize:'1rem',fontWeight:400,}}>
        Thank you for your purchase. We’ve sent you an order confirmation at {_.get(orderDetails,'email')}
        </div>
        <div style={{marginTop:'16px',width:'100%'}}>
        <Card  rootClassName='order-side-card'
    title={<div style={{display:'flex',justifyContent:'left',alignItems:'center',padding:'16px'}}>
       <Image preview={false} style={{width:'48px',height:'48px'}} src={require('../../assets/img/order-detail.png')} />
        <div className='order-confirm-title firstColor-style' style={{marginLeft:'12px',fontSize:'1.25rem'}}>
        Order details
        </div>
    </div>}
   
    style={{width:'100%'}}
  >
    <div style={{padding:'16px'}}>
      <div>
      <div className='order-details-exp-title secondColor-style'>Listing details</div>
      <div className='order-details-exp-des secondColor-style'>{_.get(orderDetails,'name')}</div>
      {/* <div className='order-details-exp-des'>Beginner-focused Fundamentals </div> */}
      {_.get(orderDetails,'experience_type')!='open' &&<><div className='order-details-exp-des secondColor-style'>{_.get(orderDetails,'start_date')}</div>
      <div className='order-details-exp-des secondColor-style'>{_.get(orderDetails,'start_time')}</div></>}
      </div>
      <div style={{marginTop:'16px'}}>
      <div className='order-details-exp-title secondColor-style'>Athlete profile</div>
      <div className='order-details-exp-des secondColor-style'>{_.startCase(_.get(orderDetails,'guest_name'))}</div>
      <div className='order-details-exp-des secondColor-style'>{_.get(orderDetails,'gender')}</div>
      <div className='order-details-exp-des secondColor-style'>{_.get(orderDetails,'dob')}</div>
      </div>
      <div style={{marginTop:'16px'}}>
      <div className='order-details-exp-title secondColor-style'>Payment information</div>
     <div style={{marginTop:'16px',display:'flex',alignItems:'center'}}>
     <div>
      <Image preview={false} style={{width:'46px',height:'32px'}} src={require('../../assets/img/stripe-icon.png')} />
      </div>
      <div style={{marginLeft:'12px'}}>
      <div className='order-details-exp-des secondColor-style' style={{fontSize:'1rem',fontWeight:500}}>{_.get(orderDetails,'payment_details.isCard')==true?`${_.get(orderDetails,'payment_details.cardType')} ending in ${_.get(orderDetails,'payment_details.cardNumber')}`:`${_.get(orderDetails,'payment_details.cardType')}`}</div>
      <div className='order-details-exp-des secondColor-style' style={{fontSize:'1rem',fontWeight:400}}>was charged {_.get(orderDetails,'payment_details.amount')} {_.get(orderDetails,'payment_details.currency')}</div>
      </div>
     </div>
      </div>

    </div>
    
  </Card>
  <div style={{marginTop: "16px", display: "flex", flexDirection: "row"}} onClick={() => redirectHandle()}>
  <TbArrowBackUp size={20} color={"#475467"}  />
    <div className='view-more-listings secondColor-style' style={{marginLeft: "8px",fontSize:'1rem',}}>View more listings</div>
  </div>
        </div>
      </div>
      

    </div>
      :  _.get(orderDetails,'status')==2 ? <PaymentPendingcreen/> :
      
      <div className='col-8'>
        <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
          <Image preview={false} style={{width:'48px',height:'48px'}} src={require('../../assets/img/faild-payment.png')} />
          <div className='order-confirm-title firstColor-style' style={{marginTop:'20px',fontSize:'1.5rem'}}>
          Payment failed
          </div>
          <div className='order-confirm-des secondColor-style' style={{marginTop:'8px',fontSize:'1rem',fontWeight:400,width:'85%'}}>
          It looks like we weren’t able to process your payment due to an unexpected error. Dont worry, your card or bank account wasn’t charged. 
          </div>
          <div className='order-confirm-des secondColor-style' style={{marginTop:'8px',fontSize:'1rem',fontWeight:400,width:'85%'}}>
          Please try to complete your purchase again or try using a different card or payment method. If this problem persists please contact us at support@communiti.app
          </div>
          <div style={{marginTop:'16px',width:'100%'}}>
          
    <div style={{marginTop: "16px", display: "flex", flexDirection: "row"}} onClick={() => redirectHandle()}>
    <TbArrowBackUp size={20} color={"#475467"}  />
      <div className='view-more-listings secondColor-style' style={{marginLeft: "8px",fontSize:'1rem',}}>View more listings</div>
    </div>
          </div>
        </div>
        

      </div>}
      <div className='fullWidth-appsAvailablity' style={{marginTop: windowSize.width <= 768 ? '32px' : '', marginLeft: windowSize.width <= 768 ? '' : '16px'}}>
        <Card style={{padding:0}} rootClassName='order-side-card'>
       <div style={{paddingTop:'32px',paddingBottom:'32px',paddingLeft:'16px',paddingRight:'16px'}}>
        <div className='order-side-title secondColor-style' style={{fontSize:'1rem',fontWeight:400,color:'475467'}}>
        Did you know that you can manage your sessions and message clubs/coaches directly on the communiti app?
        </div>

        <div className='order-get-app' style={{textAlign:'center',marginTop:'16px',fontSize:'1rem',fontWeight:400,color:'#475467'}}>
        Get the app
        </div>
        <div style={{flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'16px'}}>
        <Image onClick={()=>window.open('https://link.communiti.app/ios', '_blank').focus()} preview={false} style={{width:'135px',height:'40px',cursor:'pointer'}} src={require('../../assets/img/app-store.png')} />
        <Image onClick={()=>window.open('https://link.communiti.app/android', '_blank').focus()} preview={false} style={{width:'135px',height:'40px',marginTop:'16px',cursor:'pointer'}} src={require('../../assets/img/play-store.png')} />
        </div>
       </div>
        </Card>

      </div>
        
        
        </div>:<Skeleton active />}
        </div>
       {windowSize.width <=768 ? '' : <AuthFooter/>}
        </div>}
      
        </div>
  )
}
const mapStateToProps = state => {
  
    return {
      
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    orderView: details => dispatch(global.redux.action.finances.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    transList: details => dispatch(global.redux.action.experience.transList(details)),
    viewGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesView(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    exprienceCreateOrderAPI: details => dispatch(global.redux.action.experience.experienceCreateOrder(details)),
    applyCoupon: details => dispatch(global.redux.action.coupons.apply(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
