import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { Rating } from 'react-simple-star-rating'
import { Input, Button, Modal } from 'antd';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CommonFooter from '../../components/Footer/footer';
import './review.css';

const CreateReview = (props) => {
    const navigate = useNavigate();
    const { TextArea } = Input;
    const location =  useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [topTags, setTopTags] = useState([
        "Great facilities",
        "Well-organized",
        "Fun & engaging",
        "Friendly Staff",
        "Inclusive environment",
        "Technical"
    ]);

    const [rating, setRating] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [description, setDescription] = useState("");
    const [starErrMsg, setStarErrMsg] = useState(false);
    const [descrErrMsg, setDescrErrMsg] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reviewLoading, setReviewLoading] = useState(true);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Call handler right away so state gets updated with initial window size
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
            if(_.get(location, 'state')){
                setOrderData(_.get(location, 'state'));
                
            }
    }, []);

    useEffect(() => {
        handleViewRating();
    }, [orderData]);

    const handleRating = (value) => {
        
            setStarErrMsg(false)
        
        setRating(value)
        
      };

      

    const handleDescriptionChange = (event) => {
                    setDescrErrMsg(false)
            setDescription(event.target.value)
    }

    const handleSubmitRating = () => {
        
        if(rating === 0){
            setStarErrMsg(true)
        } 
        
        if(_.isEmpty(description)){
                setDescrErrMsg(true)
        }

        if(rating > 0 && !_.isEmpty(description)){
            setLoading(true)
            let body = {
                star_rating: rating,
                message: description,
                experience: _.get(orderData, 'orderValue.experience.id'),
                type: 1,
                guest: _.get(orderData, 'orderValue.guest.id'),
                tags:selectedItems
            }
            props.createRatingAPI({
                    body:body,
                    callback: (res, data) => {
                        setLoading(false)
                        if(_.get(data, 'success')){
                            showModal();
                        }
                    }
            })
        }

        
    };

    const handleSelect = (item) => {
        
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(i => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const handleViewRating = () => {

        if(_.get(orderData, 'orderValue.experience.id') && _.get(orderData, 'orderValue.guest.id') && _.get(orderData, 'orderValue.host.id')){
            props.viewRatingAPI({
                params:{
                    expand: 'top_tags,guest',
                    experience: _.get(orderData, 'orderValue.experience.id'),
                    guest: _.get(orderData, 'orderValue.guest.id'),
                    host: _.get(orderData, 'orderValue.host.id')
                },
                callback: (res, data) => {
                    setReviewLoading(false)
                    if(_.size(_.get(data, 'data.items')) > 0){
                        let ratingData =  _.get(data, 'data.items[0]')
                        setRating(_.get(ratingData, 'star_rating'));
                        setDescription(_.get(ratingData, 'message'));

                        let topTags = _.get(ratingData, 'tags')

                        if((_.size(topTags) > 0)){
                            setSelectedItems(topTags);
                        }
                        
                    }
                }
            })
        }
        
        
    };

    const getAbbreviation = () => {
        let name  = _.get(location, 'state.orderValue.host.display_name')
        const words = name.trim().split(' ').filter(Boolean); // Split by spaces and remove empty elements

        if (words.length === 1) {
            // If there is only one word, return the first and last letter
            const firstLetter = words[0][0].toUpperCase();
            const lastLetter = words[0][words[0].length - 1].toUpperCase();
            return firstLetter + lastLetter;
        } else {
            // If there are multiple words, return the first letter of the first two words
            const firstWordFirstLetter = words[0][0].toUpperCase();
            const secondWordFirstLetter = words[1][0].toUpperCase();
            return firstWordFirstLetter + secondWordFirstLetter;
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
        navigate(`/my-sessions?handle=${searchParams.get('handle')}`)
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        navigate(`/my-sessions?handle=${searchParams.get('handle')}`)
      };

      const modalTitle = () => {
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className='star-rating-success-img'>
                <img style={{width: "24px", height: "24px"}} src={require('../../assets/img/rating_success_img1.png')}/>
            </div>
            </div>
            
        )
      }
    
  return (
    <div>
         {reviewLoading && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress sx={{ color: '#EF6820' }}/>
        </div>}
        { !reviewLoading && <div>
       
        <div style={{marginTop: "32px", marginBottom: "32px"}} className='container'>

            <div style={{fontSize: windowSize.width<=768?"1.25rem" : ""}} className='review-header-txt'>
                Leave a review for {_.startCase(_.get(location, 'state.orderValue.experience.template.title'))}
            </div>

            <div style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='review-sub-header-txt'>
            Tell us about your experience
            </div>

            <div style={{display:"flex", flexDirection: "row", marginTop: "32px"}}>
                <div style={{width: windowSize.width <=768 ? "100%" : "50%"}}>
                <div className='session-review-card'>
                <div className="welcome-card">
      {_.get(location, 'state.photo') &&  !_.isEmpty(_.get(location, 'state.photo')) && <img
        src={_.get(location, 'state.photo')}
        alt="Profile"
        className="session-image"
      />}
      {_.get(location, 'state.photo') && _.isEmpty(_.get(location, 'state.photo')) && <div className='host-avatar-img-txt'>{getAbbreviation()}</div>}
      <div className="session-text-container" style={{marginLeft: "12px"}}>
      <div className="session-sub-header-text" style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>{_.startCase(_.get(location, 'state.orderValue.host.display_name'))}</div>
        <div className="session-header-text" style={{marginTop: "1px", fontSize: windowSize.width<=768 ? "1.125rem" : ""}}>{_.startCase(_.get(location, 'state.orderValue.experience.template.title'))}</div>
      </div>
    </div>

            <div className='star-rating-txt' style={{marginTop: "32px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>
            Your rating*
            </div>
            <div>
                <Rating onClick={handleRating} size={40} initialValue={rating}/>
            </div>
            {starErrMsg && <div className='star-rating-error-txt'>
            Please select a rating
            </div>}

            <div className='star-rating-decsription' style={{marginTop: "32px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>
            What did you think of this program?*
            </div>
            
            <TextArea
            value={description}
        placeholder="Describe your experience"
        autoSize={{
          minRows: 8,
          maxRows: 8,
        }}
        style={{border: "1px solid  #D0D5DD", marginTop: "18px", borderRadius: "8px", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)", fontSize: windowSize.width<=768 ? "0.875rem" : ""}}
        onChange={(e) => handleDescriptionChange(e)}
      />
      {!descrErrMsg && <div className='star-rating-note-txt' style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>
      Please be respectful when you leave your review
      </div>}

      {descrErrMsg && <div className='star-rating-error-txt'>Please provide a brief description of your experience </div>}

      <div className='what-went-well-txt'>
      What went well for you?
      </div>
                {windowSize.width > 768 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{marginTop: "12px", cursor: "pointer"}}>
                {topTags.slice(0, 3).map((item, index) => (
                    <span
                     key={index}
                      className='rating-top-tags'
                       style={{ marginRight: '16px', backgroundColor: selectedItems.includes(item) ? '#EF6820' : '#FFF', color: selectedItems.includes(item) ? '#FFF' : 'var(--Gray-700, #344054)' }}
                       onClick={() => handleSelect(item)}
                       >
                        {item}
                        </span>
                ))}
            </div>
            <div style={{marginTop: "12px", cursor: "pointer"}}>
                {topTags.slice(3).map((item, index) => (
                    <span 
                    key={index + 3} 
                    className='rating-top-tags'
                     style={{ marginRight: '16px', backgroundColor: selectedItems.includes(item) ? '#EF6820' : '#FFF', color: selectedItems.includes(item) ? '#FFF' : 'var(--Gray-700, #344054)'}}
                     onClick={() => handleSelect(item)}
                     >
                        {item}
                        </span>
                ))}
            </div>
        </div>}

        {windowSize.width <= 768 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <div style={{ marginTop: "12px", cursor: "pointer" }}>
        {topTags.slice(0, 2).map((item, index) => (
            <span
                key={index}
                className='rating-top-tags'
                style={{ marginRight: '16px', backgroundColor: selectedItems.includes(item) ? '#EF6820' : '#FFF', color: selectedItems.includes(item) ? '#FFF' : 'var(--Gray-700, #344054)', fontSize: "0.75rem" }}
                onClick={() => handleSelect(item)}
            >
                {item}
            </span>
        ))}
    </div>
    <div style={{ marginTop: "12px", cursor: "pointer" }}>
        {topTags.slice(2, 4).map((item, index) => (
            <span
                key={index + 2}
                className='rating-top-tags'
                style={{ marginRight: '16px', backgroundColor: selectedItems.includes(item) ? '#EF6820' : '#FFF', color: selectedItems.includes(item) ? '#FFF' : 'var(--Gray-700, #344054)', fontSize: "0.75rem" }}
                onClick={() => handleSelect(item)}
            >
                {item}
            </span>
        ))}
    </div>
    <div style={{ marginTop: "12px", cursor: "pointer" }}>
        {topTags.slice(4).map((item, index) => (
            <span
                key={index + 4}
                className='rating-top-tags'
                style={{ marginRight: '16px', backgroundColor: selectedItems.includes(item) ? '#EF6820' : '#FFF', color: selectedItems.includes(item) ? '#FFF' : 'var(--Gray-700, #344054)', fontSize: "0.75rem" }}
                onClick={() => handleSelect(item)}
            >
                {item}
            </span>
        ))}
    </div>
</div>}

        {/* <div style={{marginTop: "32px"}}> */}
       { loading && <div className='star-rating-submit-btn-loading' style={{height: "44px", marginTop: "32px"}}>
            <div className='dot-pulse'/>
        </div>}
            { !loading && <button style={{height: "44px"}} onClick={() => handleSubmitRating()} className='star-rating-submit-btn'>Submit review</button>}
        {/* </div> */}
            
                </div>
                </div>
                
            </div>
        </div>
        <div>
        <Modal 
        footer={null} 
        style={{borderRadius: "12px"}} 
        centered 
        title={modalTitle()} 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        closable={false}
        styles={{
            mask: {
              backgroundColor: "#344054", opacity: 0.7
            },
          }}
          transitionName="myModal"
            maskTransitionName=""
        >
       <div>
        <div style={{marginTop: "16px"}} className='review-submited-header-txt'>
        Review submitted!
        </div>
        <div style={{marginTop: "4px"}} className='review-success-txt'>
        Thank you for taking the time to share your experience. Your review was successfully recorded.
        </div>
        <button onClick={handleOk} className='star-rating-submit-btn' style={{width: "100%", marginTop: "32px", height: "44px"}}>Okay</button>
       </div>
      </Modal>
        </div>
        <CommonFooter/>
        </div>}
        </div>
  )
}

const mapStateToProps = state => {
  
    return {
     
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    createRatingAPI: details => dispatch(global.redux.action.experience.experienceCreateRating(details)),
    viewRatingAPI: details => dispatch(global.redux.action.experience.experienceRatingList(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CreateReview)