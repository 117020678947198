import appPages from './app-pages';

export default [

    {
        key: "RegistrationProfile",
        element: <appPages.RegistrationProfile/>,
        path:'/profile-registration',
        exact:true
    },
    {
        key: "CreateGuestProfiles",
        element: <appPages.CreateGuestProfiles/>,
        path:'/create-profiles',
        exact:true
    },
    {
        key: "QuestionList",
        element: <appPages.QuestionList/>,
        path:'/registration-question',
        exact:true
    },
    {
        key: "EditGuestProfiles",
        element: <appPages.EditGuestProfiles/>,
        path:'/edit-profile',
        exact:true
    },
    {
        key: "GuestProfilesList",
        element: <appPages.StepConfiguration/>,
        path:'/guest-profiles',
        exact:true
    },
    {
        key: "ClauseOperation",
        element: <appPages.ClauseOperation/>,
        path:'/registration-clause',
        exact:true
    },
    {
        key: "PaymentOperation",
        element: <appPages.PaymentOperation/>,
        path:'/payment',
        exact:true
    },
    {
        key: "PaymentSuccess",
        element: <appPages.PaymentSuccess/>,
        path:'/success',
        exact:true
    },

    {
        key: "ViewSession",
        element: <appPages.ViewSession />,
        path:'/session/:id',
        exact:true
    },
    {
        key: "UserHandleExperience",
        element: <appPages.UserHandleExperience />,
        path:'/:dynamicValue/session/:id',
        exact:true
    },

    {
        key: "ExperienceListHandle",
        element: <appPages.ExperienceListHandle />,
        path:'/:handleValue',
        exact:true,
        hash:'#handleScreen'
    },
    {
        key: "PayInvoice",
        element: <appPages.PayInvoice />,
        path:'/pay/invoice/:id',
        exact:true
    },
    {
        key: "SessionList",
        element: <appPages.SessionList />,
        path:'/session-list/:id',
        exact:true
    },
    {
        key: "ExperienceListing",
        element: <appPages.ExperienceListing />,
        path:'/experience-list/:id',
        exact:true
    },

    {
        key: "SeeAllReviews",
        element: <appPages.SeeAllReviews />,
        path:'/reviews',
        exact:true
    },

    {
        key: "MySessions",
        element: <appPages.MySessions />,
        path:'/my-sessions',
        exact:true
    },

    {
        key: "OrderDetails",
        element: <appPages.OrderDetails />,
        path:'/order-details',
        exact:true
    },

    {
        key: "CreateReview",
        element: <appPages.CreateReview />,
        path:'/session-review',
        exact:true
    },

    {
        key: "AthleteAccount",
        element: <appPages.AthleteAccount />,
        path:'/account',
        exact:true
    },

    {
        key: "ChatsAndNotification",
        element: <appPages.ChatsAndNotification />,
        path:'/messages',
        exact:true
    },

    
    
];