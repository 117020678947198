import {  Button, Avatar, Tooltip, Select, Divider, Dropdown, Image, Drawer } from "antd";
import {  Modal, notification } from "antd";
import { CiUser } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment-timezone';
import { GoogleLogin } from '@react-oauth/google';
import { CiMobile3 } from "react-icons/ci";
import { MdAccountCircle } from "react-icons/md";
import { MdLogout } from "react-icons/md";

// import { googleLogout } from '@react-oauth/google';
import { BellFilled, BellOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import chatkitty from "../../core_units/chat-system/chatkitty";
import ChatHome from "../../core_units/chat-system/components/home/index";
import "./header.css";
import EmailLogin from "../../containers/email-login";
import LoginWithPhone from '../../containers/sticky-container/login-with-phone';

const { Option } = Select;
const PageHeader = (props) => {
    let history = useNavigate();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [menuVisible, setmenuVisible] = useState(false)
    const [userProfile, setuserProfile] = useState({})
    const [schoolDetailsView, setschoolDetailsView] = useState({})
    const [userProfileMeta, setuserProfileMeta] = useState({})
    const [school_photo, setschool_photo] = useState(null);
    const [getId, setId] = useState("");
    const location = useLocation();
    const [getPhoto, setPhoto] = useState();
    const [getSecondHost, setSecondHost] = useState();
    const [string, setString] = useState(null)
    const [hostDetails, setHostDetails] = useState({});
    const [getHostData, setHostData] = useState({});
    const [getExperienceList, setExperienceList] = useState([]);
    const [listItem, setlistItem] = useState([])
    const [getTempData, setTempData] = useState({});
    const [getHostVal, setHostVal] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailLoginOpen, setemailLoginOpen] = useState({visible:false,form:1})
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(false);
    const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    
    const [headerActiveBtn, setHeaderActiveBtn] = useState('');
    const [guestPhoto, setGuestPhoto] = useState('');
    const [athleteChangedImage, setAthleteChangedImage] = useState('');
    const [athleteRealPhoto, setAthleteRealPhoto] = useState('');
    const [visible, setVisible] = useState(false);

    

    
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Call handler right away so state gets updated with initial window size
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      if(!_.isEmpty(_.get(props, 'athleteChangedImage'))){
        
        setAthleteChangedImage(_.get(props, 'athleteChangedImage.athlete_photo'));
        setAthleteRealPhoto(_.get(props, 'athleteChangedImage.realPhoto'))

      }
      
    }, [_.get(props, 'athleteChangedImage')])

    const items = [
      {
        key: '1',
        label: (
          <div onClick={() => {handleHeaderLoggedInActionBtns('account'); setVisible(false)}} className="account-logo-drop-menu" style={{width: "240px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M13.3333 14C13.3333 13.0696 13.3333 12.6044 13.2185 12.2259C12.96 11.3736 12.293 10.7067 11.4408 10.4482C11.0622 10.3333 10.597 10.3333 9.66665 10.3333H6.33333C5.40295 10.3333 4.93776 10.3333 4.55923 10.4482C3.70696 10.7067 3.04002 11.3736 2.78148 12.2259C2.66666 12.6044 2.66666 13.0696 2.66666 14M11 5C11 6.65685 9.65684 8 7.99999 8C6.34314 8 4.99999 6.65685 4.99999 5C4.99999 3.34315 6.34314 2 7.99999 2C9.65684 2 11 3.34315 11 5Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> Account
            </div>
        ),
      },
      {
        key: 'divider',
        type: 'divider',
      },
      {
        key: '2',
        label: (
          <div onClick={() => logout()} className="account-logo-drop-menu" style={{width: "240px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6M6 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.7157 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.07989 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H6" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> Sign out
            </div>
        ),
      },
    ];
  

    const ToggleMenu = () => {
        setmenuVisible(!menuVisible)
    };

    useEffect(() => {
      // Get the current URL
      const currentUrl = window.location.href;
  
      // Check if the URL matches the expected pattern
      if (currentUrl.match(/\/[a-zA-Z0-9_]+$/)) {
        // Extract the last part of the URL (after the last '/')
        const lastPart = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
  
        // Log the result to the console (you can use it as needed)
        
        localStorage.setItem('coach_handle', lastPart)
      } else {
        
      }
    }, []);
    const logout = () => {

      props.logout({
        callback: (response, data) => {
            // googleLogout();
            const url = new URL(window.location.href);
            const id = url.pathname.split('/')
            console.log('hfg_id', id);
            
            if(searchParams.get("handle")){
              history(`/${searchParams.get('handle')}`);
                localStorage.clear();
                window.location.reload(false);
            } else {
              history(`/${id[1]}`);
              localStorage.clear();
              window.location.reload(false);
            }
            
        }
    })
        
    }
    // useEffect(() => {
    //     getProfile()
    // }, [props.hostProfileData])

    useEffect(() => {
        if (_.get(props, 'checkUpdate') == true) {
            if (_.get(props, 'islogindata.isLoggedIn') === true) {
                setuserProfile({})
                
                   getProfile()
               
            }

        }

    }, [_.get(props, 'checkUpdate')]);

    useEffect(() => {
        // Get the parameter value from the URL
        const url = new URL(window.location.href);
        const id = url.pathname.split('/').pop();
        setId(id)
        
        viewTemplate();
        viewExperience();
        setTimeout(() => {
          getHost();
        }, 2000);
       
        
      }, []);

      useEffect(() => {
        setTimeout(() => {
          getHost();
        }, 2000);
      }, [_.get(location, 'state.isFromOrders')]);
    


    const getProfile = async () => {
        let conditionType = _.includes(_.get(props,'islogindata.types'),0)==true?'guestProfileData':'hostProfileData';
        props[conditionType]({
            params:{expand:'invite_code'},
           
            callback: async (res, data) => {
              const uniqueIdentifier = new Date().getTime();
                let displayPictureUrl=`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.small')}/${_.get(data, 'data.details.photo')}?v=${uniqueIdentifier}` 
                
                setGuestPhoto(displayPictureUrl)
               chatkitty.initialyze({},{..._.get(data, 'data.details'),displayPictureUrl})
                
                if (res == true && _.get(data, 'data.details.id')) {
                    await setuserProfile(_.get(data, 'data.details'))
                    await setuserProfileMeta(_.get(data, 'data.meta'))
                    setmenuVisible(true)
                }
            }
        })
    };

    const redirectSocialLogin = () => {
      
        // history(`/social-login/?id=${getId}`)
    }

    const viewTemplate = () => {

        const currentURLxcd = window.location.href;
        const partssdf = currentURLxcd.split('/');
        const extractedString = partssdf[partssdf.length - 1];
        
                if (extractedString) {
                  
                  setString(extractedString)
                props.experienceListAPI({
                  params:{
                    status: 1,
                    page: 1,
                    search_type: 0,
                    expand: 'host,template,template_photos',
                    group_by: 'template',
                    client_timezone: "America/Toronto",
                    is_slots_available:1,
                    is_price_porated:1,
                    host_handle: searchParams.get('handle') ? searchParams.get('handle') : extractedString,
                    min_experience_date: currentLocalISOString,
                    min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
                  },
                  callback: (r, v) => {
                    
                    if(_.size(_.get(v, 'data.items')) > 0){
                        // const text = <span>prompt text</span>;
                       setHostDetails(_.get(v, 'data.items[0].host'))
                       
                       setExperienceList(_.get(v, 'data.items'));
                       if(_.size(_.get(v, 'data.items'))>0){
                        let listExperience =[]
                        _.map(_.get(v, 'data.items'),(item,i)=>{
                          
                          listExperience.push({
                            key:   _.get(item, 'id'),
                            label: 
                            <div onClick={()=>{
                              // handleChange(_.get(item, 'id'),_.get(v, 'data.items'),searchParams.get('handle'))
                            }} className='date-select-class-option' style={{ borderBottom:i+1==_.size(_.get(v, 'data.items'))?"": '1px solid #EAECF0',paddingTop:'9px',paddingBottom:'9px' }}>
                            {_.get(item, 'template.title')}
                          </div>
                          
                          })

                        })
                        setlistItem(listExperience)
                       }
                    }
                  }
                }) 
              } else {
                
              }
        };

        const viewExperience = () => {
            const currentURLxcd = window.location.href;
        const partssdf = currentURLxcd.split('/');
        const extractedString = partssdf[partssdf.length - 1];
        
        props.viewSessionAPI({
            params: {
                id: searchParams.get('id'),
                expand: "template,template_photos,host",
                is_price_porated: 1
              },

              callback:(res, v) => {
                
                if(!_.isEmpty(_.get(v, 'data.details'))){
                  setTempData(v)
                    setHostDetails(_.get(v, 'data.details.host'));
                }
                if(!_.isEmpty(_.get(v, 'data.details.host'))){
                  props.getHostDetailsAPI({
                    params:{
                      handle: _.get(v, 'data.details.host.handle')
                    },
                    callback:(res, d) => {
                      
                      if(d){
                        const uniqueIdentifier = new Date().getTime(); 
                        setSecondHost(`${_.get(d, 'data.meta.photo.path')}/${_.get(d, 'data.meta.photo.folder')}/${_.get(d, 'data.meta.photo.sizes.medium')}/${_.get(d, 'data.details.meta_details.logo')}?v=${uniqueIdentifier}`);
                      }
                      
                    }
                  })
                }
              }
        })
        }

        
            const text = <span>{`Club/Coach: ${_.startCase(_.get(getHostData, 'details.first_name'))} ${_.startCase(_.get(getHostData, 'details.last_name'))}`}</span>

            const getHost = () => {
              const currentURLxcd = window.location.href;
              const partssdf = currentURLxcd.split('/');
              const extractedString = partssdf[partssdf.length - 1];
              props.getHostDetailsAPI({
                params :{
                  handle: _.get(getTempData, 'data.details.host.handle') ? _.get(getTempData, 'data.details.host.handle') : searchParams.get('handle') ? searchParams.get('handle') : extractedString
                },
                callback: (response, viewData) => {
                  
                  if(!_.isEmpty(viewData)){
                    const uniqueIdentifier = new Date().getTime(); 
                    setHostVal(viewData)
                    setPhoto(`${_.get(viewData, 'data.meta.photo.path')}/${_.get(viewData, 'data.meta.photo.folder')}/${_.get(viewData, 'data.meta.photo.sizes.medium')}/${_.get(viewData, 'data.details.meta_details.logo')}?v=${uniqueIdentifier}`);
                    setHostData(_.get(viewData, 'data'));
                  };
                }
              })
            };

            const handleChange = (value) => {
              const selectedItem = getExperienceList.find(item => item.id === value);
                  if (selectedItem) {
                    window.open(`/experience-list/${_.get(selectedItem, 'id')}?template=${_.get(selectedItem, 'template.id')}&handle=${searchParams.get('handle')}&id=${_.get(selectedItem, 'id')}`)
                  };
                  
            };

            const loadListing =()=>{
             return _.size(getExperienceList) >0 ?  getExperienceList.map(item => (

                {key: item.id,
              label: (<div className='date-select-class-option' style={{ borderBottom: '1px solid #EAECF0',paddingTop:'9px',paddingBottom:'9px' }}>
                {_.get(item, 'template.title')}
              </div>
                
              )})):[]
            }

            const SelectList = () => {
              
              return(
                <Dropdown
                
                rootClassName="dropdown-open-style"
                className="dropdown-open-style"
                openClassName="dropdown-open-style"
                // open={true}
                menu={
                  {items:listItem,onClick:(e)=>{
                    handleChange(e.key)
                    
                  }}
                }
                placement="bottom"
                arrow
                
                trigger={['click']}
                
              >
                <Button  style={{display:'flex',alignItems:'center',fontWeight:400}} className="all-listing-btn">All Listings <Image preview={false} style={{width:'20px',height:'20px'}} src={require('../../assets/img/chevron-down.png')} /></Button>
              </Dropdown>
              )
            };

            const getAbbreviation = () => {
              if(!_.isEmpty(userProfile)){
                  let name  = `${_.startCase(_.get(userProfile, 'first_name'))} ${_.startCase(_.get(userProfile, 'last_name'))}`
                  const words = name.trim().split(' ').filter(Boolean); // Split by spaces and remove empty elements
          
                  if (words.length === 1) {
                      // If there is only one word, return the first and last letter
                      const firstLetter = words[0][0].toUpperCase();
                      const lastLetter = words[0][words[0].length - 1].toUpperCase();
                      return firstLetter + lastLetter;
                  } else {
                      // If there are multiple words, return the first letter of the first two words
                      const firstWordFirstLetter = words[0][0].toUpperCase();
                      const secondWordFirstLetter = words[1][0].toUpperCase();
                      return firstWordFirstLetter + secondWordFirstLetter;
                  }
              }
             
          };

            const AccountAction = () => {
              return (
                <Dropdown
                rootClassName="dropdown-open-style"
                className="dropdown-open-style"
                openClassName="dropdown-open-style"
                menu={{
                  items,
                }}
                trigger={['click']}
                placement="bottom"
                arrow
              >
                {/* <Button>Test</Button> */}
                {_.get(userProfile, 'photo') === 'default.png' ? <div style={{cursor: "pointer"}} className='athleteheader-avatar-img-txt'>{getAbbreviation()}</div> : athleteRealPhoto === 'default.png'  ? <div style={{cursor: "pointer"}} className='athleteheader-avatar-img-txt'>{getAbbreviation()}</div> : <img  className="header-athlete-profile-img" style={{marginLeft: "12px", marginTop: "5px", cursor: "pointer"}} src={athleteChangedImage ? athleteChangedImage : guestPhoto}/>}
                {/* {} */}
              </Dropdown>
              )
            }

            const redirectPage = () => {
              // 
              // if(_.get(props, 'islogindata.isLoggedIn') === true){
              //   history(`/${localStorage.getItem('coach_handle')}`)
              // } else if(searchParams.get('handle')){
              //   history(`/${searchParams.get('handle')}`)
              // };
              if(searchParams.get('handle')){
                history(`/${searchParams.get('handle')}`)
              }
              
            };

            const modalTitle = (
              <div style={{display: "flex", flexDirection: "column"}}>
                <img src={require('../../assets/img/communiti-logo.png')} alt="Communiti Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
                <div className='continue-with-email' style={{marginBottom: "5px"}}>Please sign-in to complete your purchase</div>
              </div>
            );

            const showLoginModal = () => {
              setVisible(false)
              setIsModalOpen(true);
            };
            const handleLoginOk = () => {
              setIsModalOpen(false);
            };
            const handleLoginCancel = () => {
              setIsModalOpen(false);
            };

            const getGoogleToken = (response, signInType) => {
              if (response) {
                  let requestParams = {
                    type: 'google',
                    token: _.get(response, 'credential'),
                              scopes: ['profile', 'email']
                  }
                  props.login({
                    body:requestParams,
                    callback: (res,data)=>{
                                      
                      if(res==true && _.get(data,'isLoggedIn')==true){
                        setIsModalOpen(false)
            notification.success({
            message:"Success",
            description:'Login successful'
            })
            localStorage.setItem('login_methods', 'Google');
            // navigate(`/guest-profiles?id=${searchParams.get('id')}`);
            // window.location.reload(false);
                      }
                      
                    }
                });
                  
                }
              };
              
              const continue_with_phone_btn_text = {
                color: "var(--Gray-600, #475467)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                cursor: "pointer"
              };
            
              const continue_with_email_text = {
                color: "var(--Gray-600, #475467)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                cursor: "pointer",
                textDecorationLine: "underline"
              };

              const showModal = () => {
                setIsModalVisible(true);
                setIsModalOpen(false)
              };

              const handleCancel = () => {
                setIsModalVisible(false);
              };

              const handleDataFromChild = (data) => {
                // Handle data received from child component
                setDataFromChild(data);
                setIsModalOpen(data);
              };

              const handleHeaderLoggedInActionBtns = (type) => {

                let local_path = location.pathname.split("/");

                  if(type === "mySessions"){
                    setHeaderActiveBtn('mySessions');
                    if(searchParams.get('handle')){
                      history(`/my-sessions?handle=${searchParams.get('handle')}`)
                    } else {
                      history(`/my-sessions?handle=${local_path[1]}`)
                    }
                    
                  } else if(type === "messages"){
                    setHeaderActiveBtn('messages');
                    if(searchParams.get('handle')){
                      history(`/messages?handle=${searchParams.get('handle')}`)
                    } else {
                      history(`/messages?handle=${local_path[1]}`)
                    }
                   
                  } else if(type === "account"){
                    setHeaderActiveBtn('account');
                    if(searchParams.get('handle')){
                      history(`/account?handle=${searchParams.get('handle')}`)
                    } else {
                      history(`/account?handle=${local_path[1]}`)
                    }
                    // history(`/account`)
                  }
              };

              const showDrawer = () => {
                setVisible(true);
            };
        
            const onClose = () => {
                setVisible(false);
            };

              const HamburgerMenu = () => {
                return (
                  <>
            <Button type="text" icon={<MenuOutlined />} onClick={showDrawer} />
            <Drawer
                title="Menu"
                placement="right"
                onClose={onClose}
                open={visible}
                width="75%"
            >
                { _.get(props, 'islogindata.isLoggedIn') === true && <>
        <div onClick={() => {handleHeaderLoggedInActionBtns("mySessions"); setVisible(false)}} className={headerActiveBtn === 'mySessions' ? 'header_btn_active' : 'header_btn_inactive'}><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 11L11 13L15.5 8.5M20 21.5V8.3C20 6.61984 20 5.77976 19.673 5.13803C19.3854 4.57354 18.9265 4.1146 18.362 3.82698C17.7202 3.5 16.8802 3.5 15.2 3.5H8.8C7.11984 3.5 6.27976 3.5 5.63803 3.82698C5.07354 4.1146 4.6146 4.57354 4.32698 5.13803C4 5.77976 4 6.61984 4 8.3V21.5L6.75 19.5L9.25 21.5L12 19.5L14.75 21.5L17.25 19.5L20 21.5Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> My Sessions</div>

<div onClick={() => {handleHeaderLoggedInActionBtns("messages"); setVisible(false)}} className={headerActiveBtn === 'messages'? 'header_btn_active' : 'header_btn_inactive'}><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 9H12M7 12.5H15M9.68375 18.5H16.2C17.8802 18.5 18.7202 18.5 19.362 18.173C19.9265 17.8854 20.3854 17.4265 20.673 16.862C21 16.2202 21 15.3802 21 13.7V8.3C21 6.61984 21 5.77976 20.673 5.13803C20.3854 4.57354 19.9265 4.1146 19.362 3.82698C18.7202 3.5 17.8802 3.5 16.2 3.5H7.8C6.11984 3.5 5.27976 3.5 4.63803 3.82698C4.07354 4.1146 3.6146 4.57354 3.32698 5.13803C3 5.77976 3 6.61984 3 8.3V20.8355C3 21.3684 3 21.6348 3.10923 21.7716C3.20422 21.8906 3.34827 21.9599 3.50054 21.9597C3.67563 21.9595 3.88367 21.7931 4.29976 21.4602L6.68521 19.5518C7.17252 19.162 7.41617 18.9671 7.68749 18.8285C7.9282 18.7055 8.18443 18.6156 8.44921 18.5613C8.74767 18.5 9.0597 18.5 9.68375 18.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Messages</div>
        </>}
                {_.get(props, 'islogindata.isLoggedIn') === true && <div onClick={() => {handleHeaderLoggedInActionBtns('account'); setVisible(false)}} className="header_btn_inactive"><MdAccountCircle style={{width: "24px", height: "24px"}}/> Account</div>}
                {_.get(props, 'islogindata.isLoggedIn') === true && <div onClick={() => logout()} className="header_btn_inactive"><MdLogout style={{width: "24px", height: "24px"}}/> Signout</div>}
                {_.get(props, 'islogindata.isLoggedIn') === false && (
                    <button
                        onClick={() => showLoginModal()}
                        className="login-sign-out-btn raise-btn"
                        style={{ fontSize: '1rem', marginTop: '0px' }}
                    >
                        Login
                    </button>
                )}
            </Drawer>
        </>
                )
              }
    
    const render = () => {
      
      let local_path = location.pathname.split('/')
      
      
      
        let photo =_.get(userProfile, 'photo')==null?require('../../assets/img/push-and-app-icon.png') :`${_.get(userProfileMeta, 'photo.path')}/${_.get(userProfileMeta, 'photo.folder')}/512/${_.get(userProfile, 'photo')}?time=${new Date().getTime()}`


        return (
        <header className="custom-header">
             <div className="container">
            <div className="header-action-container">
                <div className="header-left-container">
                {
                        _.get(getHostVal, 'error') || _.isEmpty(_.get(getHostData, 'details.meta_details.logo')) ||  local_path[1] === 'my-sessions' ||  local_path[1] === 'messages' ||  local_path[1] === 'account' ||  local_path[1] === 'order-details' ||  local_path[1] === 'session-review' ? 
                        <img className="header-avatar" style={{cursor: "pointer", height: windowSize.width <=768 ? "40px" : "60px", maxWidth: "152px",borderRadius:'0px', objectFit: 'contain'}} 
                        onClick={() => local_path[1] === 'my-sessions' ||  local_path[1] === 'messages' ||  local_path[1] === 'account' ||  local_path[1] === 'order-details' ||  local_path[1] === 'session-review' ? '' : redirectPage()} size="large" src={require('../../assets/img/header-logo-1.png')}/> :
                         _.get(getHostVal, 'data.details') ? 
                         <img className="header-avatar" style={{cursor: "pointer", height: windowSize.width <=768 ? "40px" : "60px",borderRadius:'0px', objectFit: 'contain', maxWidth: "120px"}} onClick={() => redirectPage()} size="large" src={getPhoto}/> :  
                        <h1 className="logo">COMMUNITI</h1>
                    }
       {
                      local_path[1] === 'experience-list' && windowSize.width > 768 && <SelectList/>
                    }
                </div>
                <div className="header-right-container">
                    {windowSize.width <= 768 && _.get(props, 'islogindata.isLoggedIn') === true ? (
                        <HamburgerMenu />
                    ) : (
                        <>
                            {/* Your existing right-side content */}
                            { _.get(props, 'islogindata.isLoggedIn') === true && <>
        <div onClick={() => handleHeaderLoggedInActionBtns("mySessions")} className={headerActiveBtn === 'mySessions' ? 'header_btn_active' : 'header_btn_inactive'}><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 11L11 13L15.5 8.5M20 21.5V8.3C20 6.61984 20 5.77976 19.673 5.13803C19.3854 4.57354 18.9265 4.1146 18.362 3.82698C17.7202 3.5 16.8802 3.5 15.2 3.5H8.8C7.11984 3.5 6.27976 3.5 5.63803 3.82698C5.07354 4.1146 4.6146 4.57354 4.32698 5.13803C4 5.77976 4 6.61984 4 8.3V21.5L6.75 19.5L9.25 21.5L12 19.5L14.75 21.5L17.25 19.5L20 21.5Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> My Sessions</div>

<div onClick={() => handleHeaderLoggedInActionBtns("messages")} className={headerActiveBtn === 'messages'? 'header_btn_active' : 'header_btn_inactive'}><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 9H12M7 12.5H15M9.68375 18.5H16.2C17.8802 18.5 18.7202 18.5 19.362 18.173C19.9265 17.8854 20.3854 17.4265 20.673 16.862C21 16.2202 21 15.3802 21 13.7V8.3C21 6.61984 21 5.77976 20.673 5.13803C20.3854 4.57354 19.9265 4.1146 19.362 3.82698C18.7202 3.5 17.8802 3.5 16.2 3.5H7.8C6.11984 3.5 5.27976 3.5 4.63803 3.82698C4.07354 4.1146 3.6146 4.57354 3.32698 5.13803C3 5.77976 3 6.61984 3 8.3V20.8355C3 21.3684 3 21.6348 3.10923 21.7716C3.20422 21.8906 3.34827 21.9599 3.50054 21.9597C3.67563 21.9595 3.88367 21.7931 4.29976 21.4602L6.68521 19.5518C7.17252 19.162 7.41617 18.9671 7.68749 18.8285C7.9282 18.7055 8.18443 18.6156 8.44921 18.5613C8.74767 18.5 9.0597 18.5 9.68375 18.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Messages</div>
        </>}
                            {_.get(props, 'islogindata.isLoggedIn') === true && <AccountAction />}
                            {_.get(props, 'islogindata.isLoggedIn') === false && (
                                <button
                                    onClick={() => showLoginModal()}
                                    className="login-sign-out-btn raise-btn"
                                    style={{ fontSize: '1rem', marginTop: '0px' }}
                                >
                                    Login
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
            {emailLoginOpen?.visible ==true &&<EmailLogin 
onChangeFormModal ={(values)=>setemailLoginOpen(values)}
onCloseModal={(isBack)=>{setemailLoginOpen({visible:false,form:1});

isBack ==true &&setIsModalOpen(true)
}} 
onOpenLoginModal ={()=>setemailLoginOpen({visible:true,form:1})}
isModalOpen={emailLoginOpen}/>}
            <Modal 
      title={modalTitle}
      open={isModalOpen} 
      onOk={handleLoginOk} 
      onCancel={handleLoginCancel}
      footer={null}
      >
        <div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                    <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <button onClick={showModal}  className='continue-with-phone-btn raise-btn'><CiMobile3 style={{width: "25px", height: "25px"}}/><div style={continue_with_phone_btn_text}>Continue with phone number</div></button>
        </div>
        
        <div onClick={()=>{
          handleLoginCancel()
          setemailLoginOpen({
            visible:true,form:1
          })
          localStorage.setItem('redirect_url',``)

        }} style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <div style={{textDecorationLine: "none", color: 'var(--Gray-600, #475467)',fontSize: '16px',fontWeight: 600,lineHeight: '24px',cursor: 'pointer'}}>Continue with email</div>
        </div>
        <div className='login-popup-text'>
        By selecting “Continue with Google”, you acknowledge that you have read and understood, and agree to Communit’s <a href='https://www.communiti.app/terms-of-service' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Terms of Service</a> and <a href='https://www.communiti.app/privacy-policy' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Privacy Policy</a>
        </div>
        </div>
        
      </Modal>
      <LoginWithPhone sendDataToParent={handleDataFromChild} isModalVisible={isModalVisible} handleCancel={handleCancel}  proceed={true} props={props}/>
          </header>
  )



    }
    return render()


}
const mapStateToProps = state => {
   return {
        checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
        islogindata: _.get(state, 'app.account.isLogin'),
        // userDetails: _.get(state, 'app.host.isLogin'),
        userProfile: _.get(state, 'app.host.profileLoadingInProgress'),
        guestUserProfile: _.get(state, 'app.guest.profileLoadingInProgress'),
        athleteChangedImage: _.get(state, 'app.settings.athleteRapidPhoto'),
    }
}

const mapDispatchToProps = dispatch => ({
    isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
    logout: details => dispatch(global.redux.action.account.logout(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
   guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
   experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
   viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
   getHostDetailsAPI: details => dispatch(global.redux.action.host.getHostsDetails(details)),
   login: details => dispatch(global.redux.action.account.socialLogin(details)),
   otpLoginAPI: details => dispatch(global.redux.action.otpLogin.sendOtp(details)),
  checkProfileAPI: details => dispatch(global.redux.action.accountCheckProfile.checkProfile(details)),
  verifyOtpAPI: details => dispatch(global.redux.action.account.phoneLogin(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
