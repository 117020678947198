import { Button as AntButton, Divider, Image, Modal, notification } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { useNavigate } from 'react-router';

const { useState, useEffect, useRef, default: React,createRef } = require('react');

const useStyles = makeStyles({
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const SuccessModal = (props) => {
       const navigate = useNavigate()

       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);

       const [formStep, setformStep] = useState(1)
       const [otp, setOtp] = useState(['', '', '', '', '', '']);
       const [errorOtp, seterrorOtp] = useState(false)
  const inputRefs = useRef([...Array(6)].map(() => createRef()));
  const [enableResendOtp, setenableResendOtp] = useState(false)
  const [seconds, setSeconds] = useState(60);
  const [successModal, setsuccessModal] = useState('')
       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       useEffect(() => {
              if (seconds > 0) {
                const intervalId = setInterval(() => {
                  setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
          
                return () => clearInterval(intervalId);
              }
            }, [seconds]);
       

       const handleChange = (index, value) => {
              seterrorOtp(false)
              if (value !== '') {
                const newOtp = [...otp];
                newOtp[index] = value;
                setOtp(newOtp);
                if (index < 5 && value !== '') {
                  inputRefs.current[index + 1].current.focus();
                }
              }else if (value === '' && index >= 0) {
                     const newOtp = [...otp];
                     newOtp[index] = '';
                     setOtp(newOtp);
                     
                     
                     index!=0 &&inputRefs.current[index - 1].current.focus();
                   }
            };
          
            const handleKeyUp = (index, event) => {
              if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].current.focus();
              } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
                inputRefs.current[index + 1].current.focus();
              }
            };
          
            const handleKeyDown = (index, event) => {
              if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].current.focus();
              } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
                inputRefs.current[index + 1].current.focus();
              }
            };
       const checkProfile = (email) => {
              props.guestProfileCheck({
                     params: {
                            user_type: 0,
                            type: 'email',
                            data: email
                     },
                     callback: (res, data) => {
                            if (_.get(data, 'data.details.id')) {

                            } else if (_.get(data, 'error.error.message')) {

                            }

                     }
              })
       }

       const loginEmail = (email) => {
              
             
              props.loginEmail({
                     body: props?.userDetails?.login_details,
                     callback: (res, data) => {
                            
                            if (_.get(data, 'isLoggedIn')==true) {
                                   props?.oncloseAllModal()
                                   notification.success({
                                          message:"Success",
                                          description:'Login successful'
                                          })
                                          localStorage.setItem('login_methods', 'Email');
                                          props?.oncloseModal()
                                          let url = localStorage.getItem('redirect_url');
                                          console.log('kdjhf_local', url);
                                          
                                          if(url){
                                                 setTimeout(() => {
                                                       
                                                        navigate(url) 
                                                 }, 400);
                                          }

                                          
                            } else  {
                                  
console.log('comes in else condition');
// props?.oncloseModal()
let url = localStorage.getItem('redirect_url');
console.log('kdjhf_local', url);

if(url){
       setTimeout(() => {
             
              navigate(url) 
       }, 400);
}

                            }

                     }
              })
       }
       
       return  <div >
              <Divider style={{ color: '#EAECF0', width: 1 }} />
             
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '15px',display:'flex',justifyContent:'center' }}>
                 <Image preview={false} src={require('../../assets/img/success.png')} style={{width:'48px',height:'48px'}} />    
              </div>
              
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
              <div className='email-success-title' >
              Account created!
              </div>
              <div className='email-success-des' style={{marginTop:'8px'}}>
              Your account was successfully created!
              </div>
                     
             
   

             
              </div>
              
              
           
              <div>
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px',marginBottom:'24px' }}>
                            <AntButton
                            disabled={true}
                                   className='ant-back-button'
                                   onClick={() => {
                                         
                                          
                                   }}

                                   style={{
                                          width: "90%",
                                          height: "44px",
                                          // backgroundColor: "#D0D5DD",
                                          color: "#D0D5DD",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          marginRight: '12px'

                                   }}
                            ><TbArrowBackUp size={22} color={"#D0D5DD"} style={{ marginTop: -3 }} /> back</AntButton>
                            <Button
                                  
                                   style={{
                                          width: "90%",
                                          height: "44px",
                                          backgroundColor:  "#FF692E",
                                          color: "#FFFFFF",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          textTransform: 'none'


                                   }}
                                   onClick={() => {
                                          // setSeconds(60)
                                          // seterrorOtp(true)
                                   //       let userDetails ={
                                   //        email:props?.userDetails?.email,
                                   //        first_name:first_name,
                                   //        last_name:last_name,
                                   //        password:password
                                   //       }

loginEmail()
                                   }}

                                   fullWidth

                            >
                                  Get started
                            </Button>
                     </div>
              </div>


       </div>


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       guestProfileCheck: details => dispatch(global.redux.action.guest.checkProfile(details)),
       loginEmail: details => dispatch(global.redux.action.guest.loginEmail(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal)
