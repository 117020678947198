import _ from 'lodash';
import axios from './axios-config';
import global from '../config/global'

export default async (requestOptions) => {
    // component request can have the following properties notification , callback, params, body

    const callback = _.get(requestOptions, 'componentRequest.callback', ()=>{});
    const actionType = _.get(requestOptions, 'actionType', "");
    const reducerName = _.get(requestOptions, 'reducerName', "");
    const dispatch = _.get(requestOptions, 'dispatch', ()=>{});
    let axiosParams = {
        withCredentials: true,
        params: _.get(requestOptions, 'componentRequest.params', {}),
        data: _.get(requestOptions, 'componentRequest.body', {}),
        ..._.get(requestOptions, 'axiosParams', {}),
    };
    axiosParams.headers = _.get(axiosParams, 'headers', {})? { ..._.get(axiosParams, 'headers', {}) }:{ 'Content-Type': 'application/json' };
    //Notification
const notification = (data,type,method)=>{
    let notification_data ={type}
    if (_.get(method,'method')!='get' &&_.get(method,'url') !='/isLoggedIn' && type=='success') {
        notification_data.title=  'Success'

            // global.utils.notification.nativeAlert(notification_data)
    }else if(type=='error') {
        // if (_.get(data,'error.message')) {
        //     notification_data.title=  'Error'
        //     notification_data.message=  _.get(data,'error.message')
        // }else
         if(_.get(data,'error.errors[0].rules[0].message')){
            notification_data.title=  'Error'
            if(`${_.get(data,'error.errors[0].rules[0].message')}`.includes("kit_number")){
                notification_data.message= "The value you provided for the Jersey number already exists for another athlete of the specific school ID you provided for the school attribute."
            }else{
                notification_data.message= _.get(data,'error.errors[0].rules[0].message')
            }
           
            global.utils.notification.nativeAlert(notification_data)
        }else if(_.get(data,'error.message') != "Already booked this item."){
            notification_data.title=  'Error'
            notification_data.message=  _.get(data,'error.message')
            global.utils.notification.nativeAlert(notification_data)
        }
        
    }
    
   
}
    if(_.get(axiosParams,'url')=='/login'){
        axiosParams.data ={...axiosParams.data,
            client_id : 'TO4DFGJ3CK',
            client_secret : '5PY02KdOpCX5FO93jSjPa7gKsBvQF'
        }
    }
    // start loader for the property
    global.redux.utils.startActionLoader(dispatch, reducerName, actionType);
    
    axios(axiosParams)
    .then(response => {
        if ( _.get(response, 'data.isLoggedIn') ||  _.get(response, 'data.isLoggedIn')==false) {
            dispatch({
                type: 'reduxUpdateAccountState',
                data: {['isLogin']: _.get(response, 'data', {})}
            }); 
        }
        if ( _.get(response, 'data.userDetails.id')) {
            dispatch({
                type: reducerName,
                data: {['profile']: _.get(response, 'data.userDetails', {})}
            });
        }
        dispatch({
            type: reducerName,
            data: {[actionType]: _.get(response, 'data', {})}
        });
        global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
        notification( _.get(response, 'data', {}),'success',axiosParams)
        callback(true, _.get(response, 'data', {}));
           
       
    })
    .catch(error => { 
        notification(_.get(error, 'response.data', {}),'error')
        callback(false, _.get(error, 'response.data', {}));
        if(_.get(error, 'response.data.error.error.message')=="Unauthorized! Invalid credentials!"){
            dispatch({
                type: 'reduxUpdateAccountState',
                data: {['isLogin']: {success:true,isLoggedIn:false}}
            }) 
        }else if(_.get(error, 'response.data.error.message')=="Unauthorized! Invalid credentials!"){
            dispatch({
                type: 'reduxUpdateAccountState',
                data: {['isLogin']: {success:true,isLoggedIn:false}}
            })
        }
        global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
    })
    .finally(()=>{
    // stop loader for the property
    
    });
}
