import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateHostState;
// host actions

export default {
    // get profile data of the current logged in host
    profile: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/hosts/profile`
                },
                actionType: actionType.host.profile,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // update profile data of the current logged in host
    update: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/update`
                },
                actionType: actionType.host.update,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // update profile photo of the current logged in host
    updatePhoto: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/update-photo`,
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
                actionType: actionType.host.updatePhoto,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // Request to admin
    request: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/request`
                },
                actionType: actionType.host.request,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    updatePhone: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/update-phone`
                },
                actionType: actionType.host.updatePhone,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    hostCreate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/create`
                },
                actionType: actionType.host.create,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    verifyInviteCode :(componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/invite-codes/verify`
                },
                actionType: actionType.host.inviteCode,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    getLocation: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/getcurrentLocation`
                },
                actionType: actionType.host.location,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    getHostsDetails: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/hosts/view`
                },
                actionType: actionType.host.getHostsDetails,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}