// import ChatKitty from 'chatkitty';
import ChatKitty from '@chatkitty/core';

import appEnvirontVariables from "../../../config/env-variables";

const kitty = ChatKitty.getInstance(
    appEnvirontVariables.CHAT_SYSTEM.CHATKITTY.API_KEY
);


export default kitty;