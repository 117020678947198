import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import {Card,Divider} from 'antd';
import './chats.css';

const ChatSkeletonLoader = () => {
  return (
    <div style={styles.container}>
      {/* Left Side: Chat List */}
      <div style={styles.chatList}>
        {Array(5).fill().map((_, index) => (
          <div key={index} style={styles.chatItem}>
            <Skeleton circle={true} height={50} width={50} />
            <div style={styles.chatText}>
              <Skeleton width={`200%`} />
              <Skeleton width={`200%`} />
            </div>
          </div>
        ))}
      </div>

      {/* Right Side: Chat Conversation */}
      <div style={styles.chatConversation}>
        {Array(4).fill().map((_, index) => (
          <div key={index} style={styles.messageItem}>
            <Skeleton circle={true} height={40} width={40} />
            <div style={styles.messageText}>
              <Skeleton width={`500%`} />
              <Skeleton width={`500%`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Styles for layout
const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    padding: "20px",
    gap: "20px"
  },
  chatList: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    // borderRight: "1px solid #ddd",
    paddingRight: "10px"
  },
  chatItem: {
    display: "flex",
    gap: "15px",
    alignItems: "center"
  },
  chatText: {
    flex: 1,
  },
  chatConversation: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingLeft: "10px"
  },
  messageItem: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  messageText: {
    flex: 1,
  }
};

export default ChatSkeletonLoader;
