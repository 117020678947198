import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Skeleton, Modal } from 'antd';
import chatKittyInstance from '../../core_units/chat-system/chatkitty/instance';
import Chat from './chat';

import './my-session.css';
import EmptyState from './empty-state';

const MySessions = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const[sessionItems, setSessionItems] = useState([]);
  const [seesionMeta, setSessionMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chennelData, setchennelData] = useState('');
  const [divHeight, setDivHeight] = useState(800);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleSessionList();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      // Calculate the height based on window height minus some offset if needed
      const height = window.innerHeight - 100; // Example offset
      setDivHeight(height);
    };

    // Initial height calculation
    updateHeight();

    // Add event listener to update height on window resize
    window.addEventListener('resize', updateHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handleSessionList = (pageSize=25) => {
    setListLoading(true)
    props.orderListAPI({
      params:{
        user_type: 0,
        expand: "guest,experience,experience_template,experience_template_photos,host",
        limit: pageSize,
        sort: "created_at.desc"
      },
      callback:(res, data) => {
        setListLoading(false)
        setLoading(false)
        if(res){
          if(_.size(_.get(data, 'data.items')) > 0){
            setSessionItems(_.get(data, 'data.items'));
            setSessionMeta(_.get(data, 'data.meta'))
          }
        }
        
      }
    })
  };


  const getNextSession = (scheduleDates, timeZone, openDate) => {
    
    let merged_dates = [openDate, ...scheduleDates]
    if (merged_dates.length === 0) {
      const openSession = moment.tz(openDate, timeZone);
      const formattedDate = openSession.format('ddd, MMM D');
      const formattedTime = openSession.format('h:mm A z');
      return `Next session on ${formattedDate} at ${formattedTime}`;
    }

    const now = moment.tz(timeZone);
    const upcomingSession = merged_dates
      .map(date => moment.tz(date, timeZone))
      .find(date => date.isSameOrAfter(now));

    if (upcomingSession) {
      const isToday = upcomingSession.isSame(now, 'day');
      const formattedDate = isToday ? 'Today' : upcomingSession.format('ddd, MMM D');
      const formattedTime = upcomingSession.format('h:mm A z');

      return `Next session on ${formattedDate} at ${formattedTime}`;
    } else {
      const finalSession = moment.tz(merged_dates[merged_dates.length - 1], timeZone);
      const formattedDate = finalSession.format('ddd, MMM D');
      const formattedTime = finalSession.format('h:mm A z');

      return `Final session was on ${formattedDate} at ${formattedTime}`;
    }
  };

  const handleOrderDetails = (id) => {
    navigate(`/order-details?handle=${searchParams.get('handle')}`, {state:{orderId:id}});
  }

  const handleNavigateRating = (value) => {

    let order_id = _.get(value, 'id');
    let photo = `${_.get(seesionMeta, 'photo.path')}/${_.get(seesionMeta, 'photo.folders.host')}/${_.get(seesionMeta, 'photo.sizes.small')}/${_.get(value, 'host.photo')}`
    
    navigate(`/session-review?handle=${searchParams.get('handle')}`, {state:{orderId:order_id, orderValue: value, photo: photo}})
  }

  const  handleScroll = (event)=> {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight && !listLoading && _.get(seesionMeta, 'total') > 10) {
      if(_.size(sessionItems) < _.get(seesionMeta, 'total')){
        handleSessionList(_.get(seesionMeta, 'limit')+25)
      }
    }
}

const showModal = (value) => {
  
 
  if(_.get(value, 'host.chatkitty_user_id')){
    setIsModalOpen(true);
    openChat(_.get(value, 'host.chatkitty_user_id'))
  }
};
const handleOk = () => {
  setIsModalOpen(false);
};
const handleCancel = () => {
  setIsModalOpen(false);
};

const openChat = async (id) => {
  // 
  if (id) {

    const result = await chatKittyInstance.createChannel({
      type: "DIRECT",
      members: [{ id: id }],
  });
  
  
    
    if (result.succeeded) {
      const channel = result.channel; // Handle channel
      setchennelData(channel);
  }

};

}

  return (

    
    <div style={{ height: `${divHeight}px`, overflow: 'auto' }} onScroll={(e) => handleScroll(e)} className='container session-list-hide-scrollbar'>
      {
        loading && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress sx={{ color: '#EF6820' }}/>
        </div>
      }
      {!loading && <div>
      <div className='my_sessions_header' style={{fontSize: windowSize.width<=768 ? "1.25rem" : ''}}>My Sessions</div>
      <div style={{fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='my_sessions_sub_header'>
      You can view all of your purchases here
      </div>
      {/* session list mapping */}
      <div>
        {_.isEmpty(sessionItems) && !loading && <EmptyState/>}
        {
            _.size(sessionItems) > 0 && sessionItems.map((value, index) => {
                console.log('jdvbfdjgt', value);
                
                
              let t = _.get(value, 'experience.meta_details.timezone') ? _.get(value, 'experience.meta_details.timezone') : "America/Toronto"
            const end_day = moment.tz(_.get(value, "experience.experience_end_date"), t).format("YYYY-MM-DD HH:mm:ss")
              const totay_day = moment().format("YYYY-MM-DD HH:mm:ss")

            let status = _.get(value, 'status') == 4 && _.get(value, 'refund') == 1 ? 4 :
            _.get(value, 'status') == 4 ? 2 : moment(totay_day).isAfter(end_day) == true ? 3 :
                _.get(value, 'status') == 1 &&
                    _.get(value, 'paid') == 1 ? 1 :
                    _.get(value, 'status') == 4 && _.get(value, 'refund') == 1 ? 4 :
                        _.get(value, 'status') == 4 ? 2 : 1
       
        let status_item = ['Purchased', 'Canceled', 'Completed', 'Refunded']
        let back_color = ['#ECFDF3', "#FEF3F2", '#FFF4ED', '#EFF8FF']
        let text_color = ["#027A48", "#B42318", '#EF6820', '#175CD3']

                return (
                  <div key={_.get(value, 'id')} className="my-session-card" style={{marginTop: "32px", marginBottom: "24px"}}>
      <img src={`${_.get(seesionMeta, 'photo.path')}/${_.get(seesionMeta, 'photo.folders.experience_template')}/${_.get(seesionMeta, 'photo.sizes.large')}/${_.get(value, 'experience.template.photos[0].photo')}`} style={{borderRadius: "12px"}} alt={`${_.get(value, 'experience.template.title')} image`} className="my-session-card-image" />
      <div className="my-session-card-details" style={{marginTop: windowSize.width <=768 ? "24px" : ''}}>
        <div style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className="my_sessions_card_club_name">{_.startCase(_.get(value, 'host.display_name'))}</div>
        <div style={{cursor: "pointer", fontSize: windowSize.width<=768 ? "1.125rem" : ""}} onClick={() => handleOrderDetails(_.get(value, 'id'))} className="my_session_details_session_name">{_.get(value, 'experience.template.title')}</div>
        <div className='session_purchased_status' style={{color: text_color[parseInt(status) - 1], backgroundColor: back_color[parseInt(status) - 1]}}>{status_item[parseInt(status) - 1]}</div>

        <div style={{marginTop: "12px"}}>
          { _.get(value, 'experience.type') !== "open" && <div style={{display: "flex", flexDirection: "row"}}>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.3335 5.83333H12.5002M12.5002 5.83333L9.16683 9.16667M12.5002 5.83333L9.16683 2.5M3.3335 14.1667H16.6668M16.6668 14.1667L13.3335 17.5M16.6668 14.1667L13.3335 10.8333" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div style={{marginLeft: "8px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_date_hierarchy'>
              {getNextSession(_.size(_.get(value, 'experience.schedule_dates')) > 0 ? _.get(value, 'experience.schedule_dates') : [] , _.get(value, 'experience.meta_details.timezone'), _.get(value, 'experience.experience_date'))}
              </div>
          </div>}

          <div style={{display: "flex", flexDirection: "row", marginTop: "4px"}}>
            <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.6668 17.5C16.6668 16.337 16.6668 15.7555 16.5233 15.2824C16.2001 14.217 15.3664 13.3834 14.3011 13.0602C13.828 12.9167 13.2465 12.9167 12.0835 12.9167H7.91683C6.75386 12.9167 6.17237 12.9167 5.69921 13.0602C4.63388 13.3834 3.8002 14.217 3.47703 15.2824C3.3335 15.7555 3.3335 16.337 3.3335 17.5M13.7502 6.25C13.7502 8.32107 12.0712 10 10.0002 10C7.92909 10 6.25016 8.32107 6.25016 6.25C6.25016 4.17893 7.92909 2.5 10.0002 2.5C12.0712 2.5 13.7502 4.17893 13.7502 6.25Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </div>
              <div style={{marginLeft: "8px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_details_txt'>
              {`${_.startCase(_.get(value, 'profile_info.first_name'))} ${_.startCase(_.get(value, 'profile_info.last_name'))}`}
              </div>
          </div>

          {_.get(value, 'experience.type') !== "open" &&<div style={{display: "flex", flexDirection: "row", marginTop: "4px"}}>
            <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8789_57222)">
    <path d="M9.99984 4.99996V9.99996L13.3332 11.6666M18.3332 9.99996C18.3332 14.6023 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C14.6022 1.66663 18.3332 5.39759 18.3332 9.99996Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8789_57222">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
              </div>
              <div style={{marginLeft: "8px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_details_txt'>
              {`${moment.tz(_.get(value, 'experience.experience_date'),_.get(value, 'experience.meta_details.timezone')).format("hh:mm A z")} - ${moment.tz(_.get(value, 'experience.experience_end_date'),  _.get(value, 'experience.meta_details.timezone')).format("hh:mm A z")}`}
              </div>
          </div>}

          <div style={{display: "flex", flexDirection: "row", marginTop: "4px"}}>
            <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8789_57745)">
    <path d="M4.1665 11.9053C2.6237 12.5859 1.6665 13.5343 1.6665 14.5833C1.6665 16.6544 5.39746 18.3333 9.99984 18.3333C14.6022 18.3333 18.3332 16.6544 18.3332 14.5833C18.3332 13.5343 17.376 12.5859 15.8332 11.9053M14.9998 6.66663C14.9998 10.053 11.2498 11.6666 9.99984 14.1666C8.74984 11.6666 4.99984 10.053 4.99984 6.66663C4.99984 3.9052 7.23841 1.66663 9.99984 1.66663C12.7613 1.66663 14.9998 3.9052 14.9998 6.66663ZM10.8332 6.66663C10.8332 7.12686 10.4601 7.49996 9.99984 7.49996C9.5396 7.49996 9.1665 7.12686 9.1665 6.66663C9.1665 6.20639 9.5396 5.83329 9.99984 5.83329C10.4601 5.83329 10.8332 6.20639 10.8332 6.66663Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8789_57745">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
              </div>
              <div style={{marginLeft: "8px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_details_txt'>
              {_.get(value, 'experience.template.address')}
              </div>
          </div>
        </div>

        <div style={{marginTop: "12px", display: "flex", flexDirection: "row"}}>
          <button onClick={() => handleOrderDetails(_.get(value, 'id'))} className='session_card_action_btns' style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>View details <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.1665 9.99996H15.8332M15.8332 9.99996L9.99984 4.16663M15.8332 9.99996L9.99984 15.8333" stroke="#EF6820" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>

          <button onClick={() => handleNavigateRating(value, `${_.get(seesionMeta, 'photo.path')}/${_.get(seesionMeta, 'photo.folders.experience_template')}/${_.get(seesionMeta, 'photo.sizes.large')}/${_.get(value, 'experience.template.photos[0].photo')}`)} style={{marginLeft: "24px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_card_action_btns'>Leave a review</button>
          {windowSize.width > 990 && <div onClick={() => showModal(value)} style={{marginLeft: "24px"}} className='session_card_message'>Message</div>}
          
        </div>
        {windowSize.width < 990 && <div style={{marginTop: "24px", display: "flex", flexDirection: "row"}}>
        <div onClick={() => showModal(value)} style={{padding: "0px", fontSize: windowSize.width<=768 ? "0.875rem" : ""}} className='session_card_message'>Message</div>
        </div>}
        
      </div>
    </div>
                )
            })
        }
      </div>
{listLoading && <Skeleton active/>}
      </div>}

      <Modal 
      width={windowSize.width<=768 ? "100%" : "60%"} 
      centered 
      footer={null} 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel}
      styles={{
        mask: {
          backgroundColor: "#344054", opacity: 0.7
        },
      }}
      transitionName="myModal"
        maskTransitionName=""
      >
        
          {chennelData !='' ? <Chat channelData={chennelData} /> : null}
      </Modal>
    </div>
    
  )
}

const mapStateToProps = state => {
  
  return {
   
  }
}

const mapDispatchToProps = dispatch => ({
  orderListAPI: details => dispatch(global.redux.action.orders.list(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MySessions)