import React, {useState, useEffect} from 'react';
import { FiCalendar } from "react-icons/fi";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { MdOutlineCalendarToday } from "react-icons/md";
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import {CloseOutlined} from '@ant-design/icons'
import { makeStyles } from '@mui/styles';
import Sticky from 'react-stickynode';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

import "./style.css";
import { Button, Modal, Skeleton } from 'antd';
const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#FF692E"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#FF692E"
      }
    }
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF692E',  // Change this to the desired focused border color
    },
    "& label.Mui-focused": {
      color: "#FF692E"
    },
    
  },
  hover: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#FF692E"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#FF692E"
      }
    }
  }

});
const CommonRightContainer = (props) => {
  const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [getComputeOrder, setComputeOrder] = useState({});
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [couponCode, setcouponCode] = useState('');
    const [applyCoupon, setapplyCoupon] = useState(false);
    const [couponLoading, setcouponLoading] = useState(false);
    const [applyCouponDetails, setapplyCouponDetails] = useState({});
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [storedData, setStoredData] = useState(null);
    const [defaultCoupon, setDefaultCoupon] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const [inValidPromo, setInValidPromo] = useState(false);
    const [isShowPromoCode, setIsShowPromoCode] = useState(false);

    const [loading, setLoading] = useState(true)

    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Call handler right away so state gets updated with initial window size
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getExperienceDetails();
        
        
       if(_.get(props,'orderCreateData.profile')){
        computeOrder(
          {
              experience: searchParams.get("id"),
              profile:_.get(props,'orderCreateData.profile')
          }
      );
       
       }
        

       
    }, [_.get(props,'orderCreateData.profile')]);

    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

      const getCoupon = () => {
        const local_coupon = localStorage.getItem('coup_code');
        setDefaultCoupon(local_coupon)
      }

      const handleGetDataFromLocalStorage = () => {
        // Get the JSON string from localStorage
        const jsonData = localStorage.getItem('discount_key');
    
        // Parse the JSON string back to a JavaScript object
        const parsedData = jsonData ? JSON.parse(jsonData) : null;
    
        // Set the parsed data in the component state
        setStoredData(parsedData);
      };

    const computeOrder = (body) => {
      setLoading(true);
     let data ={...body}
     const jsonData = localStorage.getItem('discount_key');
    
     // Parse the JSON string back to a JavaScript object
     const parsedData = jsonData ? JSON.parse(jsonData) : null;
     if(parsedData?.discount?.id){
      data.discount = parsedData?.discount?.id
     }
        props.experienceComputeOrderAPI({
            body:data,
            callback: (res, data) => {
              setLoading(false);
                if(!_.isEmpty(_.get(data, 'details'))){
                    setComputeOrder(_.get(data, 'details'));
                }
                if(!_.isEmpty(_.get(data, 'discount'))){
                  const jsonData = JSON.stringify(data);
                  localStorage.setItem('discount_key', jsonData);
                }
             
                handleGetDataFromLocalStorage();
                
            }
        })
    };

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setTempData(value)
                if(_.isEmpty(_.get(value, 'data.details.schedule_dates'))){
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  setScheduledDate([start_date_item]);
                } else {
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  
                  setScheduledDate([start_date_item].concat(scheduled_date_items));
                }
                 
                
              }
            }
          })
    };

    const onChangeCouponInputText =(value)=> {
      localStorage.setItem('coup_code', value)
      
        setcouponCode(value)
        if (!_.chain(value).trim().isEmpty().value()) {
          setcouponLoading(true)
            props.applyCoupon({
                body: {
                    coupon_code: value,
                    experience: searchParams.get("id")
                },
                callback: (response, data) => {
                  if(!_.get(data, 'success')){
                    setInValidPromo(true)
                    localStorage.setItem('discount_key', '');
                    localStorage.setItem('coup_code', '');
                    setcouponCode('');
                    setcouponLoading(false);
                    setapplyCoupon(false);
                  }
                    if (response) {
                      setapplyCouponDetails(_.get(data,'details'))
                     
                       setapplyCoupon(true)
                       computeOrder({
                        experience: searchParams.get("id"),
                        profile: _.get(props, 'orderCreateData.profile'),
                        discount:_.get(data,'details.id')
                    });
                      //  processComputeOrder({
                      //   experience:_.get(props,'getregistrationFlow.defalut_experience.selectedSessionTimeData.id'),
                      //   profile:_.get(props,'getregistrationFlow.profileDetails.id')?_.get(props,'getregistrationFlow.profileDetails.id'):_.get(props,'getregistrationFlow.register_profile.id'),
                      //   discount:_.get(data,'details.id')
                      // })
                    } else if(response==false && _.get(data,'message')) {
                      setapplyCoupon(false)
                      // Alert.alert('Error',_.get(data,'message'))
                      // notification.error({
                      //   message: `Error`,
                      //   description:
                      //     _.get(data,'message'),
                      //   placement:'topRight',
                      // })
                    }else{
                      setapplyCoupon(false)
                    }
                }
            });
        } else {
            
            
        }
      };

      const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };

      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const roundPrice = (price) => {
        return Math.round((parseFloat(price) + Number.EPSILON) * 100) / 100;
      }

      const right_card_session_title = {
        color: 'var(--Gray-900, #101828)',
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
      };

      const cardStyles = {
        width: '100%',
        borderRadius: '8px',
        border: '1px solid var(--Gray-300, #D0D5DD)',
        background: 'var(--Base-White, #FFF)',
        padding: '10px 14px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        marginTop: "8px"
      };

      const dateTextStyle = {
        color: 'var(--Gray-500, #667085)',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
      };

      const fullscheduleButtonStyle = {
        borderRadius: '6px',
        border: '1px solid var(--Gray-300, #D0D5DD)',
        background: 'var(--Base-White, #FFF)',
        padding:" 4px 10px 4px 9px",
        marginLeft: "12px",
        color: '#667085',
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textDecorationLine: 'underline',
        cursor: "pointer"
        // Add any additional styles as needed
      };

      const priceStyle = {
        color: 'var(--Gray-700, #344054)',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
      };

      const totalPriceStyle = {
        marginTop: '16px',
        color: 'var(--Gray-700, #344054)',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
      };

      const promoCodeSelectProfileStyle = {
        color: "#475467",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        marginTop: "8px"
      }

      // const redirectSessionPage = () => {
      //   navigate(`/session-list/${searchParams.get('id')}?template=${_.get(getTempData, 'data.details.template.id')}&handle=${_.get(getTempData, 'data.details.host.handle')}&id=${searchParams.get('id')}`)
      // }

      const clickChevronIcon = (val)  => {
        
        setIsShowPromoCode(prevState => !prevState);
      }

      const render = () => {
        
          
        

        return (
          <div>
              <div className='common-right-card' style={{marginBottom: "32px"}} >
              <div style={{...right_card_session_title, fontSize: windowSize.width <=768 ? '1.125rem' : ""}} className='firstColor-style'>{_.get(getTempData, 'data.details.template.title')}</div>
             { _.get(getTempData, 'data.details.type') !== 'open' && <div> <div 
                style={{
                    marginTop: "20px",
                 
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >Start date and end date
                </div>
                <div style={cardStyles}>
                  <div style={{...dateTextStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='secondColor-style'>
                  {`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format('ddd, MMM DD, YYYY')}  -  ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format('ddd, MMM DD, YYYY')}`}
                  </div>
                  </div></div>}

                  { _.get(getTempData, 'data.details.type') !== 'open' && <div> <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  Start time and end time
                </div>
                <div style={cardStyles}>
                  <div style={{...dateTextStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='secondColor-style'>
                  {`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'),_.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}
                  </div>
                  </div> </div>}

                  <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  Location
                </div>
                <div style={cardStyles}>
                  <div style={{...dateTextStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ''}} className='secondColor-style'>
                  {_.get(getTempData, 'data.details.address')}
                  </div>
                  </div>

                 { _.get(getTempData, 'data.details.type') !== 'open' && <div> <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  Session schedule
                </div>
                {/* session schedule card starts here */}
                <div 
                    style={{
                      width: "100%",
                      padding: "0px 16px" ,
                      backgroundColor: "#F9FAFB",
                      borderBottom: "1px solid var(--Gray-200, #EAECF0)", 
                      marginTop:"8px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center"
                      
                  }}>
                    <div 
                    style={{
                     
                     fontSize: windowSize.width<=768 ? "0.875rem" : '',
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                    className='secondColor-style'
                    >
                      {_.size(getScheduledDate)-pastDateCount > 1 ? `${_.size(getScheduledDate)-pastDateCount} Sessions` : `${_.size(getScheduledDate)-pastDateCount} Session`}
                    </div>
                    <button 
                    onClick={() => showSessionDateModal()}
                    style={{...fullscheduleButtonStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ''}}
                    className='secondColor-style'
                    >
                    <MdOutlineCalendarToday style={{marginRight: "6px", marginBottom: "2px"}} />
                    See full schedule
                    </button>
                  </div></div> }
                  <div style={{display: "flex", flexDirection: "row"}}>
                  <div 
                style={{
                    marginTop: "20px",
                 
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px',
                    cursor: "pointer"
                  }}
                  className='secondColor-style'
                  onClick={() => clickChevronIcon()}
                >
                  Promo code
                </div>
                {isShowPromoCode && <img onClick={() => clickChevronIcon()} style={{marginTop: "24px", marginLeft: "4px", cursor: "pointer", width: "16px", height: "16px"}} src={require('../../assets/img/chevron-up.png')}/>}
                { !isShowPromoCode && <img onClick={() => clickChevronIcon()} style={{marginTop: "24px", marginLeft: "4px", cursor: "pointer", width: "16px", height: "16px"}} src={require('../../assets/img/chevron-down.png')}/>}
                </div>
                { isShowPromoCode && <TextField 
                disabled = {props?.disablePromoCode==true?true: _.get(props, 'orderCreateData.profile') === "" ? true : false}
                className={classes.root}
                style={{marginTop: "8px", width: "100%"}}
                error = {inValidPromo}
                helperText={inValidPromo ? 'Invalid promo code': ''}
                        // helperText="Some important text"
                        onChange={(e)=>{
                          setcouponCode(e.target.value);setInValidPromo(false)
                        }}
                        value={(props?.defaultValuePromoCode==true &&props?.disablePromoCode==true)?'': localStorage.getItem('coup_code') ? localStorage.getItem('coup_code') : couponCode}
                        label="Promo code" 
                        // style={{height:'60px'}} 
                        InputProps={{
                          style: {
                            // height:'50px',
                            width:'100%',

                            // padding: '0 14px',
                          },
                          className:'secondColor-style',
                          endAdornment: (
                            <InputAdornment style={{cursor:'pointer'}} disablePointerEvents={`${couponCode || localStorage.getItem('coup_code')}`.length>=3?false:true} position="end">
                                   {applyCoupon==true || localStorage.getItem('coup_code') ? <CloseOutlined disabled={props?.disablePromoCode==true?true:false} onClick={()=>{
                                    if(!props?.disablePromoCode){
                                      localStorage.setItem('discount_key', '');
                                      localStorage.setItem('coup_code', '');
                                      setcouponCode('');
                                      setcouponLoading(false);
                                      setapplyCoupon(false);
                                      if(_.get(props,'orderCreateData.profile')){
                                        computeOrder({
                                          experience: searchParams.get("id"),
                                          profile: _.get(props,'orderCreateData.profile'),
                                          // discount:_.get(data,'details.id')
                                      });
                                      }else{
                                        computeOrder({
                                          experience: searchParams.get("id"),
                                         
                                          // discount:_.get(data,'details.id')
                                      });
                                      }
                                      
                                    }
                                    
                                   }} style={{cursor:'pointer',marginRight:'10px'}}  /> : <Button 
                                    // loading={couponLoading} 
                                    disabled={props?.disablePromoCode==true?true:`${couponCode}`.length>=3?false:true} onClick={()=>onChangeCouponInputText(couponCode)}className='apply-coupon-btn'>Apply</Button>}
      
                            </InputAdornment>
                          ),
                        }}
                        defaultValue={''} variant="outlined" />}
                        { _.get(props, 'orderCreateData.profile') === "" && isShowPromoCode && <div style={{...promoCodeSelectProfileStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>Please select an athlete profile before you enter a promo code</div>}
                        {applyCoupon==true &&<p style={{color:'#669F2A',fontWeight:500,marginTop:'6px'}}>Promo code applied</p>}
                        { _.get(props,'orderCreateData.profile') ? <div>
                        <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  Price
                </div>
                <div style={{display: "flex", justifyContent: "space-between",marginTop:'8px'}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>Listing price </div>
                {!loading ? <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>{`$${_.get(storedData, 'details.sub_total') ? _.get(storedData, 'details.sub_total') : _.get(getComputeOrder, 'sub_total')}`}</div> : <Skeleton.Button active/>}
                </div>
                {/* <Skeleton.Button active/> */}
                <div style={{display: "flex", justifyContent: "space-between", marginTop: "8px"}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>Tax </div>
                {!loading ? <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>{`$${ _.get(storedData, 'details.tax_amount') ? _.get(storedData, 'details.tax_amount') : !loading ? _.get(getComputeOrder, 'tax_amount') : ''}`}</div> : <Skeleton.Button active/>}
                </div>
               
                {_.get(getComputeOrder, 'base_platform_amount_visible') === true && _.get(getComputeOrder, 'base_platform_amount') > 0 && <div style={{display: "flex", justifyContent: "space-between", marginTop: "8px"}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}} className='secondColor-style'>Communiti fee </div>
                {!loading ? <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}} className='secondColor-style'>{`$${ _.get(getComputeOrder, 'base_platform_amount') ? _.get(getComputeOrder, 'base_platform_amount') :_.get(getComputeOrder, 'base_platform_amount')}`}</div> : <Skeleton.Button active/>}
                </div>}
                { _.get(getComputeOrder, 'base_platform_amount_visible') === true && _.get(getComputeOrder, 'base_platform_amount') > 0 &&<div style={{display: "flex", justifyContent: "space-between", marginTop: "8px"}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}} className='secondColor-style'>Communiti fee tax </div>
               {!loading ? <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}} className='secondColor-style'>{`$${_.get(getComputeOrder, 'base_platform_tax_amount') ? _.get(getComputeOrder, 'base_platform_tax_amount') : _.get(getComputeOrder, 'base_platform_tax_amount')}`}</div> : <Skeleton.Button active/>}
                </div>}
               {!_.isEmpty(storedData) && _.get(storedData, 'details.discount_amount') !=0 && <div style={{display: "flex", justifyContent: "space-between", marginTop: "8px"}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>Promo code</div>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? '0.875rem' : ''}}  className='secondColor-style'>-${_.get(storedData, 'details.discount_amount')}</div>
                </div>}
                <hr style={{marginBottom:'0px',marginTop:'8px'}}/>
                
                
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div 
                style={{
                    marginTop: "16px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  {`Total (${_.get(getTempData, 'data.details.meta_details.currency')})`}
                  </div>
                  {!loading ? <div style={totalPriceStyle}  className='secondColor-style'>{`$${ _.get(getComputeOrder, 'total') ? _.get(getComputeOrder, 'total') : _.get(getComputeOrder, 'total')}`}</div> : <Skeleton.Button style={{marginTop: "16px"}} active/>}
                
                </div>
                </div> : <div>
                <div 
                style={{
                    marginTop: "20px",
                   
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '24px'
                  }}
                  className='secondColor-style'
                >
                  Price
                </div>
                <div style={{display: "flex", justifyContent: "space-between",marginTop:'8px'}}>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ""}}  className='secondColor-style'>Listing price </div>
                <div style={{...priceStyle, fontSize: windowSize.width<=768 ? "0.875rem" : ""}}  className='secondColor-style'>{`$${roundPrice(_.get(getTempData, 'data.details.price'))}`}</div>
                </div>
                  </div>}
                {/*  */}
              </div>

              <div>
          <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
          <p className='register-location' >Session schedule</p>
                       
                        <div>
            {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
              const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
              const isPastDate = date.isBefore(today);
              const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
              return(
              <div
                key={index}
                className='card'
                style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
              >
                <div style={{display: "flex", flexDirection: "column"}}>
                  <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
                  <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
                  </div>
              </div>
              )
        })}
          </div>
            </Modal>
            </div>
            </div>
        )
      };

      return render();

 
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    applyCoupon: details => dispatch(global.redux.action.coupons.apply(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(CommonRightContainer);