import React, {useState, useEffect} from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import {  useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import "./style.css";

const AuthFooter = (props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getTempData, setTempData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getExperienceDetails();
  }, []);

    const openTermsService = () => {
        window.open("https://www.communiti.app/terms-of-service", "_blank");
      };

      const openPrivacyPolicy = () => {
        window.open("https://www.communiti.app/privacy-policy", "_blank");
      };

      const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setTempData(value)
                
              }
            }
          })
    };

      const footerStyle = {
        backgroundColor: '#fff', // Change this to your desired background color
        // color: '#fff', // Change this to your desired text color
        padding: '20px',
        // textAlign: 'center',
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        marginTop: "10px"
      };

  return (
        <footer style={footerStyle}>
            <div className='container'>
            <div style={{display: "flex", flexDirection: "row"}}>
        <p>&copy; 2024 Communiti Inc.</p>
        <p onClick={() => openTermsService()} className='footer-terms-condition'>Terms of Service</p>
        {windowSize.width > 768 && <p onClick={() => openPrivacyPolicy()} className='footer-privacy-policy'>Privacy Policy</p>}
        { windowSize.width > 768 &&  !_.isEmpty(_.get(getTempData, 'data.details.host.meta_details.refund_policy')) && <p onClick={() => showModal()} className='footer-privacy-policy'>Refund Policy</p>}
        </div>
        {windowSize.width <= 768 && <div style={{display: "flex", flexDirection: "row"}}>
          <p onClick={() => openPrivacyPolicy()} className='footer-privacy-policy'>Privacy Policy</p>
        { !_.isEmpty(_.get(getTempData, 'data.details.host.meta_details.refund_policy')) && <p onClick={() => showModal()} className='footer-privacy-policy'>Refund Policy</p>}
        </div>}
        </div>
        <Modal title="Refund Policy" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: _.get(getTempData, 'data.details.host.meta_details.refund_policy')}}></div>
      </Modal>
        </footer>
  )
}

const mapStateToProps = state => {
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthFooter)