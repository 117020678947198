export const reduxActionTypes = {
  // please have all host actions types const in the below host property
  host: {
    name: 'host',
    profile: 'profile',
    update: 'update',
    updatePhoto: 'updatePhoto',
    request: 'request',
    updatePhone:'updatePhone',
    inviteCode:'inviteCode',
    create:'create',
    taxList:'taxList',
    location:'location',
    getHostsDetails: 'getHostsDetails'
  
  },

  // please have all guest actions types const in the below guest property
  guest: {
    name: 'guest',
    profile: 'profile',
    profilesList: 'profilesList',
    update: 'update',
    updatePhoto: 'updatePhoto',
    updatePhone: 'updatePhone',
    stripeCustomer: 'stripeCustomer',
    taxList: 'taxList',
    guestProfilesCreate: 'guestProfilesCreate',
    guestProfilesView: 'guestProfilesView',
    guestProfilesUpdate: 'guestProfilesUpdate',
    guestProfilesDelete: 'guestProfilesDelete',
    guestUpdatePhone: 'guestUpdatePhone',
    guestUpdateEmail: 'guestUpdateEmail',
    checkProfile: 'checkProfile',
    guestProfileCreate:'guestProfileCreate',
    requestResetPassword:'requestResetPassword',
    resetPassword:'resetPassword',
    accountsVerify:'accountsVerify',
    accountsResendVerification:'accountsResendVerification',
    loginEmail:'loginEmail',
    updateContactDetails: 'updateContactDetails',
  },

  // please have all settings actions types const in the below settings property
  settings: {
    name: 'settings',
    isAppReady: 'isAppReady',
    canAccessHostDashboard: 'canAccessHostDashboard',
    chatKittyCurrentUserData: 'chatKittyCurrentUserData',
    onsitePlayersCheckIn: 'onsitePlayersCheckIn',
    setTemplateFuncActive: 'setTemplateFuncActive',
    setTemplateFuncArchive: 'setTemplateFuncArchive',
    tabTeam: 'tabTeam',
    setQuestionsAnswer: 'setQuestionsAnswer',
    setAllQuestionsRedux: 'setAllQuestionsRedux',
    athleteRapidPhoto: 'athleteRapidPhoto'
  },

  // please have all account actions types const in the below account property
  account: {
    name: 'account',
    socialLogin: 'socialLogin',
    phoneLogin: 'phoneLogin',
    token: 'token',
    stripeOnboardWeb: 'stripeOnboardWeb',
    isLogin: 'isLogin',
    login: 'login',
    logout: 'logout',
    requestResetPassword: "requestResetPassword",
    changePassword: "changePassword",
    resetPassword: "resetPassword",
    location:"location",
    profile:'profile'
  },

  // please have all categories actions types const in the below categories property
  categories: {
    name: 'categories',
    list: 'list'
  },

  // please have all experiences-templates actions types const in the below experiencesTemplates property
  experiencesTemplates: {
    name: 'experiencesTemplates',
    list: 'list',
    view: 'view',
    create: 'create',
    update: 'update',
    addPhoto: 'addPhoto',
    changeStatusTemplate: 'changeStatusTemplate'
  },

  experiences: {
    name: 'experiences',
    list: 'list',
    view: 'view',
    computeOrder: 'computeOrder',
    create: 'create',
    update: 'update',
    addPhoto: 'addPhoto',
    orderList:"orderList",
    requestCancel:'requestCancel',
    changeStatus:'changeStatus',
    completeOrder: 'completeOrder',
    increaseMaxSlot: 'increaseMaxSlot',
    refund:"refund",
    transList:"transList",
    newRefund:"newRefund",
    computeRefund:"computeRefund",
    experienceCreateOrder:"experienceCreateOrder",
    experienceRatingList:"experienceRatingList",
    viewPayInvoiceDetails:"viewPayInvoiceDetails",
    createPaymentInvoice:"createPaymentInvoice",
    experienceCreateRating:"experienceCreateRating"
  },

  
  // please have all site actions types const in the below site property
  site: {
    name: 'site',
    contactUs: 'contactUs',
    feedBack: 'feedBack'
  },

  // please have all subscription actions types const in the below subscription property
  subscription: {
    name: 'subscription',
    create: 'create',
    view: 'view',
    list: 'list',
    listSubscribers: 'listSubscribers',
    uploadPhoto: 'uploadPhoto',
    subscribe: 'subscribe'
  },

  // please have all dashboard actions types const in the below dashboard property
  dashboard: {
    name: 'dashboard',
    forHost: 'forHost',
    revenueStats: 'revenueStats'
  },
  order: {
    name: 'order',
    list: 'list',
    view: 'view'
  },
  message: {
    name: 'message',
    create: 'create',

  },
  transcations: {
    name: 'transcations',
    view: 'view',

  },
  // please have all coupons actions types const in the below site property
  coupons: {
    name: 'coupons',
    apply: 'apply'
  },

  // please have all Finances actions types const in the below finances property
  finances: {
    name: 'finances',
    list: 'list',
    ratingList: 'ratingList',
    view: 'view',
  },
// please have all League actions types const in the below league property
league: {
    name: 'league',
    list: 'list',
    changeEventStatus: 'changeEventStatus',
    leagueEventRosterList: 'leagueEventRosterList',
    viewLeagueEvents: 'viewLeagueEvents',
    leagueEventCheckIn: 'leagueEventCheckIn',
    leagueTeamsList: 'leagueTeamsList',
    leagueMatchesList: 'leagueMatchesList',
    courtsCreate: 'courtsCreate',
    leagueUpdateCaptain: 'leagueUpdateCaptain',
    leagueUpdateMatch: 'leagueUpdateMatch',
    leagueNewUpdateMatch: 'leagueNewUpdateMatch',
    leagueUpdateTeam: 'leagueUpdateTeam',
    viewTeam: 'viewTeam',
    leaderBoardList: 'leaderBoardList'
  },
   // please have all Notification actions types const in the below Notification property
   notifications: {
    name: 'notifications',
    list: 'list',
    delete: 'delete',
    view: 'view',
    read:'read'
  },

  // please put all user handle proprty here

  userHandle: {
    name: 'userHandle',
    checkUserHandleAvailablity: 'checkUserHandleAvailablity',
    createOrUpdateUserHandle: 'createOrUpdateUserHandle'
  },

  question: {
    name : "question",
    experienceQuestionList: "experienceQuestionList",
    guestQuestionList: "guestQuestionList",
    experienceAnswerCreate: "experienceAnswerCreate",
    experienceAnswerUpdate: "experienceAnswerUpdate",
    guestAnswerCreate: "guestAnswerCreate",
    guestAnswerUpdate: "guestAnswerUpdate",
    questionList:"questionList"
  },

  otpLogin:{
    name: "otpLogin",
    sendOtp: "sendOtp"
  },

  accountProfile:{
    name:"accountProfile",
    checkProfile: "checkProfile"
  },

  orders:{
    name:"orders",
    list: "list",
    orderDetils: "orderDetils"
  },

  // update specific action state
  reduxUpdateHostState: 'reduxUpdateHostState',
  reduxUpdateGuestState: 'reduxUpdateGuestState',
  reduxUpdateSettingsState: 'reduxUpdateSettingsState',
  reduxUpdateAccountState: 'reduxUpdateAccountState',
  reduxUpdateCategoriesState: 'reduxUpdateCategoriesState',
  reduxUpdateExperiencesTemplatesState: 'reduxUpdateExperiencesTemplatesState',
  reduxUpdateExperiencesState: 'reduxUpdateExperiencesState',
  reduxUpdatePackageState: 'reduxUpdatePackageState',
  reduxUpdateSiteState: 'reduxUpdateSiteState',
  reduxUpdateSubscriptionState: 'reduxUpdateSubscriptionState',
  reduxUpdateDashboardState: 'reduxUpdateDashboardState',
  reduxUpdateOrderState: 'reduxUpdateOrderState',
  reduxUpdateMessageState: 'reduxUpdateMessageState',
  reduxUpdateTranscationsState: 'reduxUpdateTranscationsState',
  reduxUpdateCouponsState: 'reduxUpdateCouponsState',
  reduxUpdateFinancesState: 'reduxUpdateFinancesState',
  reduxUpdateLeagueState: 'reduxUpdateLeagueState',
  reduxUpdateNotificationsState: 'reduxUpdateNotificationsState',
  reduxUpdateUserHandleState: 'reduxUpdateUserHandleState',
  reduxUpdateQuestionState: 'reduxUpdateQuestionState',
  reduxUpdateOtpLoginState: 'reduxUpdateOtpLoginState',
  reduxUpdateCheckProfileAccountState: 'reduxUpdateCheckProfileAccountState',
  reduxUpdateOrdersState: 'reduxUpdateOrdersState',
};
export default reduxActionTypes;