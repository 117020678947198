import React, { useState, useEffect, useRef } from 'react';
import { Button as AntButton, Modal, Form, Input, notification, Image, Card, Popconfirm, Tooltip } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { CiCircleInfo, CiCirclePlus } from "react-icons/ci";
import { PiQuestionThin } from "react-icons/pi"
import { IoMdClose } from 'react-icons/io'
import { TbArrowBackUp } from "react-icons/tb";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PlacesAutocomplete from 'react-places-autocomplete';
import "./style.css";
import { makeStyles } from '@mui/styles';
import { geocodeByAddress } from 'react-places-autocomplete';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../assets/json/b.json";
import dayjs from 'dayjs';
import StickyBox from "react-sticky-box";

import CommonRightContainer from '../common-right-container';
import AuthFooter from '../../components/auth-footer'
const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#FF692E"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#FF692E"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#FF692E"
      }
    }
  },
  focused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF692E',  // Change this to the desired focused border color
    },
    "& label.Mui-focused": {
      color: "#FF692E"
    },
  },

});
const GuestProfilesList = (props) => {
  const childRef = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  

  const classes = useStyles();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [TaxListData, setTaxListData] = useState([]);

  const [getTempData, setTempData] = useState({});
  const [getScheduledDate, setScheduledDate] = useState([]);
  const [pastDateCount, setPastDateCount] = useState(0);
  const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
  const [getProfileList, setProfileList] = useState([]);
  const [getLoadMoreProfileList, setLoadMoreProfileList] = useState([]);

  const [getComputeOrder, setComputeOrder] = useState({});
  const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
  const [selectedProfile, setselectedProfile] = useState('')
  const [selectedProfileDetails, setselectedProfileDetails] = useState('')
  const [fromLevel, setfromLevel] = useState({ isEdit: false, form: 1 })
  const [profileType, setprofileType] = useState('')
  const [isProfileModal, setisProfileModal] = useState(false)
  const [limit, setlimit] = useState(3)
  const [listProfileTotalItem, setlistProfileTotalItem] = useState('')
  const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
  const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

  //Guest User form one
  const [first_name, setfirst_name] = useState('')
  const [last_name, setlast_name] = useState('')
  const [gender, setgender] = useState('')
  const [dob, setDob] = useState(null);
  const [selfIdentify, setselfIdentify] = useState('')

  //Guest User form two
  const [email, setemail] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [country, setcountry] = useState('')
  const [address, setaddress] = useState('')
  const [state, setstate] = useState('')
  const [postalCode, setpostalCode] = useState('')
  const [submitted, setSubmitted] = useState(false);
  const [address_results, setaddress_results] = useState([]);
  const [editProfileDetails, seteditProfileDetails] = useState({})
  const [userProfileDetails, setuserProfileDetails] = useState({})
  const [loading, setloading] = useState(false)
const [loadMore, setloadMore] = useState(3);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [isScrollingByButton, setIsScrollingByButton] = useState(false);
const [showStickyButton, setShowStickyButton] = useState(true);
const [windowSize, setWindowSize] = useState({
  width: window.innerWidth,
  height: window.innerHeight,
});

useEffect(() => {
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  window.addEventListener('resize', handleResize);
  handleResize(); // Call handler right away so state gets updated with initial window size

  return () => window.removeEventListener('resize', handleResize);
}, []);



useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
  useEffect(() => {
    getGuestProfile()
    getExperienceDetails();
    listGuestProfile(limit);
    // computeOrder()
    loadTaxList();
   
  }, []);
  const loadTaxList = () => {
    props.taxList({
      params: { limit: 100 },
      callback: (res, data) => {
        if (res == true && _.get(data, 'data.items[0]'))
          setTaxListData(_.get(data, 'data.items'))
      }
    })
  }



  useEffect(() => {
    let count = 0;

    getScheduledDate.forEach((dateString) => {
      const date = moment.tz(dateString, timeZone);
      if (date.isBefore(today)) {
        count++;
      }
    });

    setPastDateCount(count);
  }, [getScheduledDate, today, timeZone]);

  const handleScroll = () => {
    if (isScrollingByButton) return; // Ignore scroll when triggered by the button

    if (childRef.current) {
      // Check for both proceed and disabled-proceed buttons
      const childButton = childRef.current.querySelector('.proceed-btn');
      const disabledChildButton = childRef.current.querySelector('.diabled-proceed-btn');

      if (childButton || disabledChildButton) {
        const childButtonPosition = (childButton || disabledChildButton).getBoundingClientRect().top;

        if (childButtonPosition <= window.innerHeight) {
          setShowStickyButton(false);
        } else {
          setShowStickyButton(true);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollingByButton]);

  const scrollToChild = () => {
    if (childRef.current) {
        childRef.current.scrollIntoView({ behavior: 'smooth' });
      
    }
  };

  const getExperienceDetails = () => {
    props.viewSessionAPI({
      params: {
        id: searchParams.get("id"),
        expand: "template,template_photos,host",
        is_price_porated: 1
      },
      callback: (res, value) => {
        
        if (res) {
          setTempData(value)
          let start_date_item = _.get(value, 'data.details.experience_date');
          let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
          
          let concated_array = scheduled_date_items
          setScheduledDate(concated_array.filter(item => item !== "" && item !== null))

        }
      }
    })
  };


  const showSessionDateModal = () => {
    setIsSessionDateModalOpen(true)
  };
  const scheduleHandleOk = () => {
    setIsSessionDateModalOpen(false)
  }

  const scheduleHandleCancel = () => {
    setIsSessionDateModalOpen(false)
  };

  const listGuestProfile = (limit) => {
    props.guestProfileListAPI({
      params: {
        status: 1,
        limit:limit,
        sort:'created_at.desc',
      },
      callback: (res, data) => {
        
        if (_.size(_.get(data, 'data.items')) > 0) {
          setlistProfileTotalItem(_.get(data, 'data.meta.total'))
          
          setProfileList(_.get(data, 'data'))
        } else {
          setselectedProfile('')
          setProfileList([])
        }
      }
    })
  };

  useEffect(() => {
   if( props?.defaultsetProfile?.profile &&props?.defaultsetProfile?.profile_details){
    setselectedProfile(props?.defaultsetProfile?.profile)
    setselectedProfileDetails(props?.defaultsetProfile?.profile_details)
    
   }
  }, [])
  const computeOrderCheck = (id,type) => {
    props.experienceComputeOrderAPI({
      body: {
        experience: searchParams.get("id"),
        profile: id
      },
      callback: (res, data) => {
        
        if (!_.isEmpty(_.get(data, 'details')) && type=='procced') {
          props?.onChangeScreen(2,selectedProfile,selectedProfileDetails)
        }else if(res==false){
          
          if(_.get(data,'error.message')){
if(_.get(getTempData, 'data.details.type')=="scheduled"){
  api.error({
    // duration:100000,
    message: 'Oops looks like this athlete profile has already purchased this session',
    description:
      'If you would like to buy another session please select a different athlete profile or create a new athlete profile',
      className:'error-message-style'
  });
}else if(_.get(getTempData, 'data.details.type')=="league"){
  api.error({
    message: 'Oops looks like this account has already purchased this session',
    description:
      'If you would like to buy another league session please sign out and create a new account',
      className:'error-message-style'
  });
  
}
           
          }

        }
      }
    })
  };

  const computeOrder = () => {
    props.experienceComputeOrderAPI({
      body: {
        experience: searchParams.get("id"),
      },
      callback: (res, data) => {
        
        if (!_.isEmpty(_.get(data, 'details'))) {
          setComputeOrder(_.get(data, 'details'))
        }
      }
    })
  };

  const editProfile = (value) => {
    navigate(`/edit-profile?id=${searchParams.get("id")}&p_id=${_.get(value, 'id')}&from=edit`);
  };

  const selectProfile = (value) => {
    // if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization'))){
    //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${_.get(value, 'id')}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
    // } else {
    //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${_.get(value, 'id')}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
    // }

    if (_.get(getTempData, 'data.details.host.organization')) {
      navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${_.get(value, 'id')}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
    } else {
      navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${_.get(value, 'id')}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
    }

  };

  const deleteGuestProfile = (value) => {
    if(value?.id == selectedProfile ){
      setselectedProfile('')
    }
    props.guestProfileDeleteAPI({
      body: {
        id: _.get(value, 'id'),
        status: 0,
        status_glossary: "Deactivated."
      },
      callback: (res, val) => {
        
        
        if (res) {
          listGuestProfile(limit);
        }
      }
    })
  };

  const createProfile = () => {
    navigate(`/profile-registration?id=${searchParams.get("id")}`)
  };

  const showProratedModal = () => {
    setIsProratedModalOpen(true);
  }

  const handleProratedOk = () => {
    setIsProratedModalOpen(false)
  }

  const handleProratedCancel = () => {
    setIsProratedModalOpen(false)
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
  };
  const fetchPostalCode = address_components => {
    let postal_code = null;
    address_components.forEach(value => {
      value.types.forEach(type => {
        if (type === 'postal_code') {
          postal_code = value.long_name
        }
      })
    })

    if (postal_code) {
      setpostalCode(postal_code)
    }

    return postal_code;
  };
  const handleSelect = async (value) => {

    
    setaddress(value)
      ;
    setaddress_results([])
    geocodeByAddress(value).then(body => {

      const address_results = _.map(body, location => ({
        text: location.formatted_address,
        value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
      }));
      
      setaddress_results(address_results)



      if (_.size(address_results) > 0) {
        let a = JSON.parse(address_results[0].value)
        setpostalCode(_.get(a, 'postal_code'))

      }

      let state = extractStateName(body)
      let ada = _.filter(TaxListData, (v) => _.lowerCase(v.state) == _.lowerCase(state))
      if (_.size(ada) > 0) {
        setstate(_.get(ada[0], 'id'))

      }

      setaddress_results(address_results)
      let a = JSON.parse(address_results[0].value)
      // setLocationData({lat:a.lat,lng:a.lng})
    }).catch(error => {
      setaddress_results([])
    });
  };

  const extractStateName = results => {
    for (let i = 0; i < results[0].address_components.length; i++) {
      const component = results[0].address_components[i];
      if (component.types.includes('administrative_area_level_1')) {
        return component.long_name; // Return the state name
      }
    }
    return '';
  };
  const searchOptions = {
    componentRestrictions: { country: country == 2 ? ['us'] : ['ca'] }, // Restrict search to United States and Canada
  };
  const handleChange1 = (value) => {
    
    setaddress(value);
  };
  const conditionFrom = (type) => {
    if (type == 2) {
      if (!first_name || !last_name || !gender || !dob || dob == null) {
        return true
      } else if(gender==3 && !selfIdentify) {
        return true
      }else{
        return false
      }
    } else if (type == 3) {
      if (!email || !phoneNumber || !country || !state || !postalCode) {
        return true
      } else {
        return false
      }
    }

    return false

  }
  const formatPhoneNumber = (phoneNumberString) => {
    
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      
      setphoneNumber(match[1] + '-' + match[2] + '-' + match[3])
      // return   match[1] + '-' + match[2] + '-' + match[3];

    }else{
      setphoneNumber(phoneNumberString)
    }
    return null;
  }
  const getGuestProfile = () => {
    props.guestProfileDataAPI({
      callback: (res, data) => {
        setuserProfileDetails(_.get(data, 'data.details'))
        
        if (!_.isEmpty(_.get(data, 'data.details'))) {
          setemail(_.get(data, 'data.details.contact_details.email'))
          setphoneNumber(_.get(data, 'data.details.contact_details.phone'))
          setaddress(_.get(data, 'data.details.address'))
          setcountry(_.upperCase(_.get(data, 'data.details.country')) === "CA" || _.isEmpty(_.get(data, 'data.details.country')) ? 1 : 2)
          setstate(_.get(data, 'data.details.tax_state'))
          setpostalCode(_.get(data, 'data.details.zip_code'))
          const address_results = [{
            text: _.get(data, 'data.details.address'),
            value: JSON.stringify({
              text: _.get(data, 'data.details.address'), lat: _.get(data, 'data.details.location.x')
              , lng: _.get(data, 'data.details.location.y'),
              postal_code: _.get(data, 'data.details.zip_code')
            }),
          }];
          setaddress_results(address_results)
        }

      }
    })
  };
  const resetFields = () => {
    

    seteditProfileDetails({});
    setfromLevel({
      isEdit: false,
      form: 1
    })

    setprofileType('')
    setfirst_name('')
    setlast_name('')
    setgender('')
    setDob(null)

  }
  const editFunctiom = (value) => {
    
    seteditProfileDetails(value);
    setfromLevel({
      isEdit: true,
      form: 1
    })
    setisProfileModal(true)
    setprofileType(value?.profile_type == "self" ? 1 : 2)
    setfirst_name(value?.first_name)
    setlast_name(value?.last_name)
    setgender(value?.gender == "male" ? 1 : value?.gender == "female" ? 2 : 3)
    setDob(dayjs(value?.date_of_birth))
    if(value?.gender != "male" && value?.gender != "female"){
      setselfIdentify(value?.gender)
    }
    
}

const onFinish = (e) => {
  let guestProfile ={
    profile_type: profileType==1 ? 'self' : 'dependent',
    first_name: first_name,
    last_name: last_name,
    date_of_birth:dayjs(dob).format('YYYY-MM-DD'),
    gender: gender==1?"male":gender==2?"female":selfIdentify
  }
  if(fromLevel.isEdit==true){
    guestProfile.id = editProfileDetails?.id
  }
  setloading(true)
  props[fromLevel.isEdit==true?"updateGuestProfilesAPI":"guestProfilesCreateAPI"]({
    body:guestProfile,
    callback: (res, data) => {
      setloading(false)
      
      if(_.get(data, 'success')){
        if(fromLevel.isEdit==false){
          
          resetFields()
          setfromLevel({
            isEdit:false,
            form:4
          })
          notification.success({
            message:'Success',
            description:"Profile Created Successfully"
          });
          listGuestProfile(limit)
        }else{
          listGuestProfile(limit)
          setisProfileModal(false)
          resetFields()
          setfromLevel({
            isEdit:false,
            form:1
          })

          notification.success({
            message:'Success',
            description:"Profile Edited Successfully"
          });
        }
        
        }
    }
    
  });

let userProfile={
  address: address,
        country: country == 2 ? 'US' : 'CA',
        zip_code: postalCode,
        tax_state: state
}
if(_.size(address_results)>0){
  userProfile.location=`${JSON.parse(_.get(_.head(address_results),'value')).lat},${JSON.parse(_.get(_.head(address_results),'value')).lng}`
}
    props.userProfileUpdateAPI({
   
      body:userProfile,
      callback: (res, val) => {
        
        if(_.get(val, 'success')){
          
        }
      }
    })
 

  if(!_.isEmpty(phoneNumber) && phoneNumber != userProfileDetails?.phone){
    props.userUpdateContactDetailsAPI({
      body: {
        phone:phoneNumber
      },
      callback: (res, val) => {
      
      }
    })
  }

  if(!_.isEmpty(email) && email != userProfileDetails?.email){
    props.userUpdateContactDetailsAPI({
      body: {
        email: email
      },
      callback: (res, val) => {
        
      }
    })
  }

  
};

const  shouldHideButton = (max_slots, status) => {
  return max_slots === 0 || status === 0;
}

  const render = () => {
    
    return (
      <div>
{contextHolder}
        <div className="banner-container-profile">
          <div className='container'>

            <div className='row'>
            {windowSize.width <=900 && <div className="col-lg-4">
              <StickyBox offsetTop={16} offsetBottom={20}>
                <CommonRightContainer orderCreateData={props?.orderCreateData}/>
                </StickyBox>
              </div>}
              <div className='col-lg-8'>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <Image className='step-images-1' style={{ width: '32px', height: '66px' }} preview={false} src={require('../../assets/img/steps-1.png')} />
                      <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                        <div className='profile-heading secondColor-style'>Athlete profile</div>
                         {props?.screenType==1 ? <div style={{fontSize: windowSize.width<=768 ? '0.875rem' : ''}} className='profile-description secondColor-style'>Create or select an athlete profile to proceed</div>:
                          <div className='profile-description'>Matthew Ortuoste... Change</div>}
                      </div>
                    </div>
                  </div>
            
                  {props?.screenType==1 &&<>   
                  <div style={{ marginTop: '16px' }}>
                    {
                      _.size(_.get(getProfileList, 'items')) > 0 && _.get(getProfileList, 'items').map((value, index) => {

                        return (
                          <Card
                           bodyStyle={{ padding: 16, }} 
                           className='ant-card-hover-effect' 
                           hoverable={true} 
                           key={index}  
                           bordered style={{
                            border: selectedProfile == value.id ? '1px solid #FF4405' :
                              '1px solid #98A2B3'
                            , background: selectedProfile == value.id ? '#FFF' : '#FFF', marginBottom: '16px'
                          }}
                          onClick={() => {setselectedProfile(value?.id);
                            computeOrderCheck(value?.id)
                          setselectedProfileDetails(value)  
                          props?.onChangeScreen(1,value?.id,value)
                          }}
                          >
                            <div >
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={selectedProfile == value.id ? 'guest-name-active ' : 'guest-name-title secondColor-style'} style={{ marginBottom: '4px', fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>{`${_.startCase(_.get(value, 'first_name'))} ${_.startCase(_.get(value, 'last_name'))}`}</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Tooltip title="Edit" color={'#EF6820'} arrow={true} placement="top">
            <Image onClick={() => {
                                    editFunctiom(value)
                                  }} preview={false} style={{ width: '24px', height: '24px' }} src={selectedProfile == value.id ? require('../../assets/img/edit-active.png') : require('../../assets/img/edit.png')} />
                                   </Tooltip>
                                   <Tooltip title="Delete" color={'#EF6820'} arrow={true} placement="top">
                                  <Popconfirm
                                  rootClassName='delete-Popconfirm'
                                  className='delete-Popconfirm'
    title="Are you sure you want to delete your profile?"
    description="You can't view this profile in your list anymore if you delete it"
    okText="Yes"
    okButtonProps={{className: 'delete-Popconfirm-success '}}
    cancelButtonProps={{className:'delete-Popconfirm-cancel'}}
    cancelText="No"
    onConfirm={()=>{
      deleteGuestProfile(value)
    }}
    style={{width:'74%'}}
  >
    
     <Image  preview={false} style={{ width: '24px', height: '24px', marginLeft: '16px' }} src={selectedProfile == value.id ? require('../../assets/img/trash-active.png') : require('../../assets/img/trash.png')} />
  
  </Popconfirm>   </Tooltip>
                                 
                                </div>
                              </div>
                              <div className={selectedProfile == value.id ? 'guest-name-active' : 'guest-name-title secondColor-style'} style={{ marginBottom: '4px', fontSize: windowSize.width<=768 ? "0.875rem" : "" }}>{`${_.startCase(_.get(value, 'gender'))}`}</div>
                              <div className={selectedProfile == value.id ? 'guest-name-active' : 'guest-name-title secondColor-style'} style={{fontSize: windowSize.width<=768 ? "0.875rem" : ""}}>{`${moment(_.get(value, 'date_of_birth')).format("MM-DD-YYYY")}`}</div>
                              <div className='edit-select-btn' style={{ marginTop: '16px' }}>


                                
                                <AntButton 
                                style={{fontSize: windowSize.width<=768 ? '0.875rem':''}}
                                onClick={() => {setselectedProfile(value?.id);
                                computeOrderCheck(value?.id)
                              setselectedProfileDetails(value)  
                              props?.onChangeScreen(1,value?.id,value)
                              }}

                                  className={selectedProfile == value.id ? 'select-profile-btn-active' : 'select-profile-btn'}
                                >{selectedProfile == value.id ? "Selected" : "Select profile"}</AntButton>


                              </div>
                            </div>
                          </Card>
                        )
                      })
                    }
                    { listProfileTotalItem >limit &&<div onClick={()=>{
                      listGuestProfile(limit+3)
                      setlimit(limit+3)

                    }} style={{ marginBottom: '16px' }}>
                      <div style={{ color: '#1D2939', fontWeight: 600, fontSize: '14px', textDecorationLine: 'underline', textAlign: 'center', cursor: 'pointer' }}>Load more profiles</div>
                    </div>}
                    <div>
                      <Card onClick={() => {
                        setisProfileModal(true);
                        setfromLevel({ form: 1, isEdit: false })
                        resetFields()
                      }} bodyStyle={{ padding: 16 }} hoverable={true} bordered style={{
                        border: '2px solid #D0D5DD'
                        , background: '#FCFCFD', marginBottom: '16px'
                      }}>
                        <div className='create-new-profile'>
                          <CiCirclePlus size={24} color='#667085' strokeWidth={0.4} />
                          <div className='create-profile-style' style={{ color: '#475467', fontWeight: 600, fontSize: '1rem' }}>Create a new profile</div>
                        </div>
                      </Card>
                    </div>
                  </div>
                
                {!shouldHideButton(_.get(getTempData, 'data.details.no_of_available_slots'), _.get(getTempData, 'data.details.status')) && (<div style={{ }}>
                  <div ref={childRef}>
                  <AntButton
                  
                    disabled={!selectedProfile}
                      onClick={()=>{

                      computeOrderCheck(selectedProfile,'procced')
                      }}

                      className='proceed-btn'
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "8px",
                      backgroundColor: !selectedProfile ? "#98A2B3" : "#EF6820",
                      color: "#FFFFFF",
                      borderRadius: '12px',
                      fontWeight: 600,
                      fontSize: '1rem',

                    }}
                  >{!selectedProfile ? "Select or create an athlete profile to proceed" : "Proceed to next section"}</AntButton>
                  </div>
                </div>)}
                {shouldHideButton(_.get(getTempData, 'data.details.no_of_available_slots'), _.get(getTempData, 'data.details.status')) && (<div style={{ }}>
                  <div ref={childRef}>
                  <AntButton
                    disabled={true}

                      className='proceed-btn'
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "8px",
                      backgroundColor: "#98A2B3",
                      color: "#FFFFFF",
                      borderRadius: '12px',
                      fontWeight: 600,
                      fontSize: '1rem',

                    }}
                  >{"Proceed to next section"}</AntButton>
                  </div>
                  <div className='guest-name-title secondColor-style' style={{marginTop: "4px"}}>This sessions isn't available right now, has past its last date to register or has sold out</div>
                </div>)}
                
                <AntButton
className='ant-back-button-container'
                style={{
                  width: "100%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: "#475467",
                  borderRadius: '12px',
                  fontWeight: 400,
                  fontSize: windowSize.width<=768 ? '0.875rem' : '',
                  marginRight: '12px',
                  border:'none',
                  marginTop:'16px'

                }}
                onClick={()=>{
                  // navigate('/success?order_id=3860&handle=337&id=4953&payment_intent=pi_3Oh13iEK4MLKCsXA04ci10MJ&payment_intent_client_secret=pi_3Oh13iEK4MLKCsXA04ci10MJ_secret_OluprDpmQ7JggLJZHgXGvjF2S&redirect_status=succeeded')
                  // /experience-list/4933?template=4929&handle=iyyappan&id=4933
                  navigate(`/experience-list/${searchParams.get('id')}?template=${_.get(getTempData, 'data.details.template.id')}&handle=${searchParams.get('handle')}&id=${searchParams.get('id')}`);
                 }}
              ><TbArrowBackUp size={20} color={"#475467"} style={{ marginTop: -3, }} /> return to listing</AntButton>
                </>}
              </div>
              {windowSize.width >=900 && <div className="col-lg-4">
              <StickyBox offsetTop={16} offsetBottom={20}>
                <CommonRightContainer orderCreateData={props?.orderCreateData}/>
                </StickyBox>
              </div>}
            </div>
          </div>

        </div>
        <div>
          <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
            <p className='register-location'>Session Schedule</p>

            <div>
              {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
                const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
                const isPastDate = date.isBefore(today);
                const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
                return (
                  <div
                    key={index}
                    className='card'
                    style={{ backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px' }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
                      <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </Modal>
        </div>
        <div>
          <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              This session has already started and the price has been prorated. View the schedule to see the dates remaining
            </div>
          </Modal>
        </div>
        <Modal footer={null} open={isProfileModal} closeIcon={null} onCancel={() => setisProfileModal(false)}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ background: '#FF692E', width: '12px', height: '12px', borderRadius: '100px', marginRight: '10px' }} />
              <div style={{ background: (fromLevel.form == 2 || fromLevel.form == 3 ||fromLevel.form == 4) ? '#FF692E' : '#D0D5DD', width: '12px', height: '12px', borderRadius: '100px', marginRight: '10px' }} />
              <div style={{ background: (fromLevel.form == 3  ||fromLevel.form == 4)? '#FF692E' : '#D0D5DD', width: '12px', height: '12px', borderRadius: '100px' }} />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setisProfileModal(false)}><IoMdClose size={24} color='#667085' /> </div>
          </div>
          {fromLevel.form == 1 && <> <div style={{ marginTop: '16px' }}>
            <div className='modal-profile-header-title firstColor-style'>
              {fromLevel.isEdit == true ? "Edit your athlete profile" : "Create a new athlete profile"}
            </div>
            <div className='modal-profile-header-des secondColor-style'>
              Please select if this profile is for you or for your child
            </div>

            <div style={{ marginTop: '26px' }}>
              <Card onClick={() => setprofileType('1')} style={{ marginBottom: '16px', border: profileType == '1' ? "1px solid #F6692E" : "1px solid #EAECF0", borderRadius: '6px' }} bodyStyle={{ padding: 16, }} hoverable={true}>
                <div className='create-profile-style' style={{ textAlign: 'center', color: profileType == '1' ? "#FF4405" : '#475467', fontSize: '1rem', fontWeight: 400 }}>
                  This profile is for me
                </div>
              </Card>
              <Card onClick={() => setprofileType('2')} hoverable={true} bodyStyle={{ padding: 16, }} style={{ border: profileType == '2' ? "1px solid #F6692E" : "1px solid #EAECF0", borderRadius: '6px' }}>
                <div className='create-profile-style' style={{ textAlign: 'center', color: profileType == '2' ? "#FF4405" : '#475467', fontSize: '1rem', fontWeight: 400 }}>
                  This profile is for my child
                </div>
              </Card>
            </div>
          </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '28px' }}>
              <AntButton
className='ant-back-button'
                disabled={fromLevel.form == 1}
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: fromLevel.form == 1 ? "#D0D5DD" : "#344054",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  marginRight: '12px'

                }}
              ><TbArrowBackUp size={22} color={fromLevel.form == 1 ? "#D0D5DD" : "#344054"} style={{ marginTop: -3 }} /> back</AntButton>
              <AntButton
                onClick={() => {
                  setfromLevel({
                    ...fromLevel,
                    form: 2
                  })
                }}
                disabled={!profileType}
                className='create-profile-style'
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: !profileType ? "#D0D5DD" : "#FF692E",
                  color: "#FFFFFF",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem'

                }}
              >Next</AntButton>
            </div></>}
          {fromLevel.form == 2 && <Box sx={{ mt: 1 }}> <div style={{ marginTop: '16px' }}>
            <div className='modal-profile-header-title firstColor-style'>
              {fromLevel.isEdit == true ? "Edit your athlete profile" : "Create a new athlete profile"}
            </div>
            <div className='modal-profile-header-des secondColor-style'>
              Profile information
            </div>

            <div style={{ marginTop: '26px' }}>

              <TextField
                inputProps={{
                  className: 'mui-textfield '
                }}
                margin="normal"
                required
                fullWidth
                id="first_name"
                label="First name"
                name="first_name"
                autoFocus
                className={classes.root}
                // error={submitted ? first_name?false:true:false}
                value={first_name}
                onChange={(e) => setfirst_name(e.target.value)}

              />
              <TextField
                className={classes.root}
                margin="normal"
                required
                fullWidth
                name="last_name"
                label="Last name"
                type="last_name"
                id="last_name"
                // error={submitted ? last_name?false:true:false}
                value={last_name}
                onChange={(e) => setlast_name(e.target.value)}
              />
              <FormControl className={classes.focused} required fullWidth style={{ marginTop: 16 }}>
                <InputLabel
                  className={classes.focused}
                  // error={submitted ? gender?false:true:false}
                  id="gender-label">Gender</InputLabel>
                <Select

                  inputProps={{ className: classes.focused }}
                  className={classes.focused}
                  labelId="gender-label"
                  id="gender"
                  label="Gender"
                  // error={submitted ? gender?false:true:false}
                  value={gender}
                  onChange={(e) => setgender(e.target.value)}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                  <MenuItem value={3}>Self-identify</MenuItem>
                </Select>
              </FormControl>
              <TextField
              hidden={gender!=3}
                className={classes.root}
                margin="normal"
                required
                fullWidth
                name="selfIdentify"
                label="What's your gender?"
                type="selfIdentify"
                id="selfIdentify"
                // error={submitted ? last_name?false:true:false}
                value={selfIdentify}
                onChange={(e) => setselfIdentify(e.target.value)}
              />
              <FormControl className={classes.focused} required fullWidth style={{ marginTop: 16 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker value={dob} onChange={(newValue) => setDob(newValue)} slotProps={{ textField: { fullWidth: true, required: true }, }} label="Date of birth" />
                  </DemoContainer>
                </LocalizationProvider></FormControl>
            </div>
          </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '28px' }}>
              <AntButton
              className='ant-back-button'
                onClick={() => {
                  setfromLevel({
                    ...fromLevel,
                    form: 1
                  })
                }}
                disabled={fromLevel.form == 1}
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: fromLevel.form == 1 ? "#D0D5DD" : "#344054",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  marginRight: '12px'

                }}
              ><TbArrowBackUp size={22} color={fromLevel.form == 1 ? "#D0D5DD" : "#344054"} style={{ marginTop: -3 }} /> back</AntButton>
              <Button
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: conditionFrom(2) == true ? "#D0D5DD" : "#FF692E",
                  color: "#FFFFFF",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  textTransform:'none'


                }}
                onClick={() => {
                  if (first_name && last_name && gender !=3 && dob) {
                    setfromLevel({ ...fromLevel, form: 3 })
                  }else if(first_name && last_name && gender==3 && dob && selfIdentify !=''){
                    setfromLevel({ ...fromLevel, form: 3 })
                  }

                }}
                fullWidth

              >
                Next
              </Button>
            </div></Box>}
          {fromLevel.form == 3 && <Box sx={{ mt: 1 }}> <div style={{ marginTop: '16px' }}>
            <div className='modal-profile-header-title firstColor-style'>
              {fromLevel.isEdit == true ? "Edit your athlete profile" : "Create a new athlete profile"}
            </div>
            <div className='modal-profile-header-des secondColor-style'>
              Contact information
            </div>

            <div style={{ marginTop: '26px' }}>
                  {/* <div className='login-method-input'>
                    <div className='login-label'>Login Method</div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                    <img style={{height: "23px", width: "24px"}} src={require('../../assets/img/login_type.png')}/>
                    <div style={{marginLeft: "8px", color: "#98A2B3"}}>Phone: +1 647-470-4244</div>
                    </div>
                    
                  </div> */}

                <div className="custom-input outlined">
                      <label className="label">Login method *</label>
                      <input style={{width: windowWidth < 768 ? "350px" : ''}} disabled type="text" className="input" value={_.get(props, 'guestProfileDetails.guest.profile.data.details.login_type') == 'google' ? `Google Sign-in: ${_.get(props, 'guestProfileDetails.guest.profile.data.details.google_data.email')}`: _.get(props, 'guestProfileDetails.guest.profile.data.details.login_type') == 'email' ? `Email: ${_.get(props, 'guestProfileDetails.guest.profile.data.details.email')}` : _.get(props, 'guestProfileDetails.guest.profile.data.details.login_type') == 'phone' ? `Phone: +1 ${_.get(props, 'guestProfileDetails.guest.profile.data.details.phone')}` : ''}/>
                      <div className="image-container">
                        <img src={require('../../assets/img/login_type.png')} alt="icon" className="image" />
                      </div>
                    </div>
              <TextField
                inputProps={{
                  className: 'mui-textfield'
                }}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Contact Email"
                name="email"
                autoFocus
                className={classes.root}
                // error={submitted ? first_name?false:true:false}
                value={email}
                onChange={(e) => setemail(e.target.value)}

              />
              <TextField
                className={classes.root}
                margin="normal"
                required
                fullWidth

                name="phone"
                label="Contact Phone number"
                type="phone"
                id="phone"
                // error={submitted ? last_name?false:true:false}
                value={phoneNumber}
                onChange={(e) => formatPhoneNumber(e.target.value)}
              />
              <FormControl className={classes.focused} required fullWidth style={{ marginTop: 16 }}>
                <InputLabel
                  className={classes.focused}
                  // error={submitted ? gender?false:true:false}
                  id="country-label">Country</InputLabel>
                <Select

                  inputProps={{ className: classes.focused }}
                  className={classes.focused}
                  labelId="country-label"
                  id="country"
                  label="Gender"
                  // error={submitted ? gender?false:true:false}
                  value={country}
                  onChange={(e) => {
                    setcountry(e.target.value);
                    setaddress('')
                    setstate('');
                    setpostalCode('')
                  }}
                >
                  <MenuItem value={1}>Canada</MenuItem>
                  <MenuItem value={2}>United States</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.focused} required fullWidth style={{}}>

                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange1}
                  onSelect={handleSelect}
                  searchOptions={searchOptions}

                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    
                    return <div>
                      <TextField {...getInputProps()} className={classes.root}
                        margin="normal"
                        fullWidth

                        name="address"
                        label="Address"
                        type="address"
                        id="address" />
                      {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                      <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion, i) => {

                          const style = {
                            backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                            marginTop: i == 0 ? 10 : 5,
                          };

                          return (
                            <Card hoverable={true} bodyStyle={{ padding: 10 }} key={i} {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </Card>
                          );
                        })}
                      </div>
                    </div>
                  }}
                </PlacesAutocomplete>
              </FormControl>
              <FormControl className={classes.focused} required fullWidth style={{ marginTop: 16, marginBottom: '10px' }}>
                <InputLabel
                  className={classes.focused}
                  // error={submitted ? gender?false:true:false}
                  id="state-label">Province/state</InputLabel>
                <Select
InputLabelProps={{ shrink: true }}
                  inputProps={{ className: classes.focused }}
                  className={classes.focused}
                  labelId="state-label"
                  id="state"
                  label="Province/state"
                  // error={submitted ? gender?false:true:false}
                  value={state}
                  onChange={(e) => setstate(e.target.value)}
                >
                  {
                    _.map(TaxListData, (value, index) => {
                      return <MenuItem key={index} value={value?.id}>{_.startCase(value?.state)}</MenuItem>
                    })
                  }

                </Select>
              </FormControl>
              <TextField
              InputLabelProps={{ shrink: true }}
                className={classes.root}
                margin="normal"
                required
                fullWidth

                name="phone"
                label="Postal code"
                type="postalCode"
                id="postalCode"
                // error={submitted ? last_name?false:true:false}
                value={postalCode}
                onChange={(e) => setpostalCode(e.target.value)}
              />
            </div>
          </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '28px' }}>
              <AntButton
                onClick={() => {
                  setfromLevel({
                    ...fromLevel,
                    form: 2
                  })
                }}
                disabled={fromLevel.form == 1}
                className='ant-back-button'
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: fromLevel.form == 1 ? "#D0D5DD" : "#344054",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  marginRight: '12px'

                }}
              ><TbArrowBackUp size={22} color={fromLevel.form == 1 ? "#D0D5DD" : "#344054"} style={{ marginTop: -3 }} /> back</AntButton>
              <Button
              
              disabled={loading}
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: conditionFrom(3) == true ? "#D0D5DD" : "#FF692E",
                  color: "#FFFFFF",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  textTransform:'none'

                }}
                onClick={() => {
                  if (conditionFrom(3) == false) {
                    onFinish()
                  }

                }}
                fullWidth

              >
                {fromLevel.isEdit == true ? "Save changes" : "Finish profile"}

              </Button>
            </div></Box>}

          {fromLevel.form == 4 && <> <div style={{ marginTop: '16px' }}>
            <div className='modal-profile-header-title firstColor-style'>
              Create a new athlete profile
            </div>
            <div className='modal-profile-header-des secondColor-style'>
              Athlete Personal info
            </div>

            <div style={{ marginTop: '26px' }}>
              <Lottie animationData={groovyWalkAnimation} />
            </div>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              <div>
                <Image preview={false} style={{ width: '48px', height: '48px' }} src={require('../../assets/img/done-profile.png')} />
              </div>
              <div className='modal-profile-header-title secondColor-style' style={{
                fontSize:'1.25rem',
                marginTop:'20px'
              }}>
                Profile created!
              </div>
              <div className='modal-profile-header-des secondColor-style' style={{ textAlign: 'center',fontSize:'1rem',marginTop:'8px' }}>
                Your athlete profile was successfully created and saved. You can now use it to purchase any future sessions!
              </div>
            </div>
          </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '28px' }}>
              <AntButton
className='ant-back-button'
                disabled={true}
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: "#D0D5DD",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem',
                  marginRight: '12px'

                }}
              ><TbArrowBackUp size={22} color={"#D0D5DD"} style={{ marginTop: -3 }} /> back</AntButton>
              <AntButton
                onClick={() => {
                  setisProfileModal(false)
                  setfromLevel({
                    ...fromLevel,
                    form: 1
                  })
                }}
                style={{
                  width: "90%",
                  height: "44px",
                  backgroundColor: "#FF692E",
                  color: "#FFFFFF",
                  borderRadius: '12px',
                  fontWeight: 600,
                  fontSize: '1rem'

                }}
              >Done</AntButton>
            </div></>}
        </Modal>
        {showStickyButton && windowSize.width <=768 && (
        <div style={{padding: "16px"}}>
        <button className="sticky-btn" onClick={scrollToChild}>
        Proceed to registration
        </button>
        </div>
      )}
{/* <AuthFooter /> */}
      </div>
    )
  };
  return render();

}

const mapStateToProps = state => {
  
  return {
    guestProfileDetails: _.get(state, 'app')

  }
}

const mapDispatchToProps = dispatch => ({
  taxList: details => dispatch(global.redux.action.tax.list(details)),
  guestProfileDataAPI: details => dispatch(global.redux.action.guest.profile(details)),

  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
  guestProfileListAPI: details => dispatch(global.redux.action.guest.profilesList(details)),
  guestProfileDeleteAPI: details => dispatch(global.redux.action.guest.guestProfilesDelete(details)),
  guestProfilesCreateAPI: details => dispatch(global.redux.action.guest.guestProfilesCreate(details)),
    userProfileUpdateAPI: details => dispatch(global.redux.action.guest.update(details)),
    userUpdatePhoneAPI: details => dispatch(global.redux.action.guest.guestUpdatePhone(details)),
    userUpdateEmailAPI: details => dispatch(global.redux.action.guest.guestUpdateEmail(details)),
    updateGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesUpdate(details)),
    userUpdateContactDetailsAPI: details => dispatch(global.redux.action.guest.updateContactDetails(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(GuestProfilesList)