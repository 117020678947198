import React, {useState, useEffect} from 'react';
import { Image } from 'antd';
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { GooglePlayButton,AppStoreButton, ButtonsContainer } from "react-mobile-app-button";

import './footer.css';

const textStyle = {
    color: '#fff',
    marginLeft: '5px', // Adjust the spacing between the icon and text
  };

  const appStyle = {
    color: '#fff',
    marginLeft: '-30px',
    fontSize: "1rem",
    fontWeight: "500px"
  };
  const APKUrl = "https://play.google.com/store/apps/details?id=com.studioq.communitiguestapp&hl=en&gl=US";
  const IOSUrl = "https://apps.apple.com/us/app/communiti/id1551306754";
const CommonFooter = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openInstagram = () => {
    window.open("https://www.instagram.com/communitisports/", "_blank")
  };
  const openFaceBook = () => {
    window.open("https://www.facebook.com/communitisports", "_blank")
  };
  const openYoutube = () => {
    window.open("https://www.youtube.com/channel/UCSAVo4lZiIhPr3yTTenhqoQ/featured", "_blank")
  };

  const openAboutUs = () => {
    window.open("https://www.communiti.app/", "_blank");
  };

  const openTermsService = () => {
    window.open("https://www.communiti.app/terms-of-service", "_blank");
  };

  const openPrivacyPolicy = () => {
    window.open("https://www.communiti.app/privacy-policy", "_blank");
  }
  return (
    <footer>
      {/* Your footer content goes here */}
      <div className='container'>
        {/* <img style={{marginLeft: "10px"}} src={require('../../assets/img/_Footer link.png')}/> */}
        <div style={{display:"flex", justifyContent: "space-between"}}>
        <img style={{height: "50px", width: "auto", marginLeft: '30px'}} src={require('../../assets/img/powered by communit_loogo.png')}/>
                {windowWidth > 768 && <div style={{display: "flex", flexDirection: "column", marginLeft: "30px"}}>
                <img 
                onClick={()=>window.open('https://link.communiti.app/ios', '_blank').focus()} 
                style={{
                  width: windowWidth < 768 ? '100px' : '135px',
                  height: windowWidth < 768 ? '30px' : '40px',
                  cursor: 'pointer',
                }}
                src={require('../../assets/img/app-store.png')}
                 />
                <img 
                onClick={()=>window.open('https://link.communiti.app/android', '_blank').focus()} 
                style={{
                  width: windowWidth < 768 ? '100px' : '135px',
                  height: windowWidth < 768 ? '30px' : '40px',
                  cursor: 'pointer',
                  marginTop:'16px',
                  marginBottom: windowWidth < 768 ? '2rem' : ''
                }}
                src={require('../../assets/img/play-store.png')} />
                </div>}
                
        </div>
        <div className='container'>
        <div style={{display: "flex", flexDirection: "row", marginTop: windowWidth < 768 ? "24px" : ''}}>
        <p style={{fontSize:  windowWidth <= 768 ? "0.875rem" : ""}} onClick={() => openAboutUs()} className='footer-about-us'>About us</p> 
        <p style={{fontSize:  windowWidth <= 768 ? "0.875rem" : ""}} onClick={() => openTermsService()} className='footer-terms-condition'>Terms of Service</p>
        {windowWidth > 768 && <p style={{fontSize:  windowWidth <= 768 ? "0.875rem" : ""}} onClick={() => openPrivacyPolicy()} className='footer-privacy-policy'>Privacy Policy</p>}
        </div>
        {windowWidth < 768 && <p style={{fontSize:  windowWidth <= 768 ? "0.875rem" : ""}} onClick={() => openPrivacyPolicy()} className='mobile-footer-privacy-policy'>Privacy Policy</p>}
       { windowWidth <= 768 && <>
        <img 
                onClick={()=>window.open('https://link.communiti.app/ios', '_blank').focus()} 
                style={{
                  width: windowWidth < 768 ? '100px' : '135px',
                  height: windowWidth < 768 ? '30px' : '40px',
                  cursor: 'pointer',
                }}
                src={require('../../assets/img/app-store.png')}
                 />
                <img 
                onClick={()=>window.open('https://link.communiti.app/android', '_blank').focus()} 
                style={{
                  width: windowWidth < 768 ? '100px' : '135px',
                  height: windowWidth < 768 ? '30px' : '40px',
                  cursor: 'pointer',
                  marginLeft: "24px",
                }}
                src={require('../../assets/img/play-store.png')} />
                </>}
        <hr/>
        <div className="footer-buttons-container">
        <p className='footer-organization'>&copy; 2024 Communiti Inc.</p>
      <div className="right-buttons">
      <FaInstagram onClick={() => openInstagram()} className='footer-social-icons' />
      <FaFacebook onClick={() => openFaceBook()} className='footer-social-icons' />
      <FaYoutube onClick={() => openYoutube()} className='footer-social-icons' />
      </div>
    </div>
        </div>
        
        
      </div>
      {/* <p>&copy; 2024 Your Company</p>  */}
    </footer>
    // <div>
    //     <footer className='footer-container'>
    //     <p>&copy; 2024 Your Company</p>
    //     </footer>
    // </div>
  )
}

export default CommonFooter