let host = window.location.host;
let hostName = host.split(":");


const baseUrl = `${window.location.protocol}//${hostName[0]}`;

const MODES = {
    DEVELOPMENT: {
        API: {
            BASE_URL: `${baseUrl}`,
            API_URL:'http://3.96.180.154:1337',
            CLIENT_ID:'TO4DFGJ3CK',
            CLIENT_SECRET:'5PY02KdOpCX5FO93jSjPa7gKsBvQF'
        },
        FACEBOOK: {
            APP_ID: '252849962899986',
            APP_NAME: 'com.studioq.communitiguestapp'
        },
        GOOGLE: {
       GOOGLE_CLIENT_ID:'796497316506-gbvitnnd3n2im3m7p8q04act06en5ebf.apps.googleusercontent.com',
        },
        GOOGLEMAP: {
            LOCATION: 'AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc',
            PRODUCTION_LOCATION_KEY: 'AIzaSyAIU0XlymmFrHfYvPDn-uPCIcyCdTphABE'
        },
        STRIPE: {
            URL: 'https://stripe.com/legal',
            SECRET_KEY: "sk_test_51GpJBKCh0aVQteBpiOG8LrCraKlZY3V0wudKcm56JTHgoUUpBE93b1zB4QrPMXLEvg0Wb1MAZc54WSW9cPY4MRUW00ZjTvsYby",
            PUBLISHABLE_KEY: "pk_test_Vl7NdHPf2yLEUDou4TTJTG0N00F35InABj",
            KEY: 'pk_test_Vl7NdHPf2yLEUDou4TTJTG0N00F35InABj',
            BASE_URL: 'https://api.stripe.com',
            SRIPE_MERCHANT_ID:"merchant.com.communitiapp",
        },
        COMMUNITI_PRIVACY:{
            URL:'https://www.communiti.app/privacy-policy',
            TERMS:'https://www.communiti.app/terms-of-service'
        },
        CHAT_SYSTEM: {
            CHATKITTY: {
                API_KEY: '44b115e1-24db-4633-a7d3-fc1577545ed6',
                CLIENT_ID: '44b115e1-24db-4633-a7d3-fc1577545ed6',
                CLIENT_SECRET: '4f458053-91c5-4dd1-aebc-ff710112262e',
                API_BASE_URL: 'https://api.chatkitty.com/v1/applications/7503',
                API_AUTHORIZATION_URL: 'https://authorization.chatkitty.com/'
            },
            EMAIL_DOMAIN: 'communiti.app',
            CONSTANT_PASSWORD: '9nVZUynhRJLZ'
        },
        FIREBASE: {
            apiKey: "AIzaSyAtpGKeh3VNBsHR0aESUlqaj_jK7GjjTS0",
            authDomain: "communiti-app-chat.firebaseapp.com",
            projectId: "communiti-app-chat",
            storageBucket: "communiti-app-chat.appspot.com",
            messagingSenderId: "98643544100",
            appId: "1:98643544100:web:60c87433cd77ec89f2b0b1",
            measurementId: "G-QTHFR0H91K"
        },
        ONESIGNAL:{
            APP_ID:"f7a1c7a3-db47-4d2a-8a51-d832269d60cd"
        },
        SENTRY_CONFIG:{
            DSN: "https://95066f5e5d64149381e92f168ea1899a@o1397951.ingest.us.sentry.io/4507311738126336",
        },
        
    }
};

const getEnvVariables = ()=>{
    return MODES.DEVELOPMENT;
};

export default {
    ...getEnvVariables()
};