import React, {useEffect, useState} from 'react'
import { Button, Image, Modal, Tooltip, Skeleton, Select, notification } from "antd"
import { connect } from 'react-redux'
import _ from 'lodash'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import GoogleMapReact from 'google-map-react';
import { RiHome6Line } from "react-icons/ri";
import {IoLocationOutline} from "react-icons/io5"
import {PiQuestionThin} from "react-icons/pi"
import {CiCircleInfo} from "react-icons/ci";
import {GoLinkExternal} from "react-icons/go"
import moment from 'moment-timezone';
import { GoogleLogin } from '@react-oauth/google';
import { CiMobile3 } from "react-icons/ci";
import SessionCard from '../sticky-container'

import global from '../../core_units/config/global'
import BannerBg from "../../assets/img/image 1.png";
import Loader from './loader';
import "./style.css";
import CommonFooter from '../../components/Footer/footer';

const { Option } = Select;
const SessionList = (props) => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [getTempData, setTempData] = useState({});
  const [isSticky, setIsSticky] = useState(false);
  const [getPhoto, setPhoto] = useState();
  const [LocationData, setLocationData] = useState({})
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);
  const [isLeagueModalOpen, setIsLeagueModalOpen] = useState(false);
  const [isOpenSessionModalOpen, setIsOpenSessionModalOpen] = useState(false);
  const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
  const [isAiModalOpen, setIsAiModalOpen] = useState(false);
  const [getScheduledDate, setScheduledDate] = useState([]);
  const [pastDateCount, setPastDateCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [getExperinceData, setExperinceData] = useState([]);
  const [getAddressData, setAddressData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [getIsDateEnable, setIsDateEnable] = useState(true);
  const [getSelectedDate, setSelectedDate] = useState(null);
  const [getTime, setTime] = useState(null);
  const [getDate, setDate] = useState(null)
  const [getIsTimeEnable, setIsTimeEnable] = useState(true);
  const [isEnableSchedule, setIsEnableSchedule] = useState(true);
  const [isMultipleLocationModalOpen, setIsMultipleLocationModalOpen] = useState(false)
  const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
  const [getItems, setItems] = useState(null);
  const [getTimeValue, setTimeValue] = useState("");
  const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);


  const { id } = useParams();
  const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
  
  const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

  const modalTitle = (
    <div style={{display: "flex", flexDirection: "column"}}>
      <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
      <div className='continue-with-email' style={{marginBottom: "5px"}}>Please sign-in to complete your purchase</div>
    </div>
  );
  
  let navigate = useNavigate();
  const defaultProps = {
    center: {
      lat: 11.937871, lng: 79.819953
    },
    zoom: 15
  };

  useEffect(() => {
    viewTemplate();
    experienceList();
  }, []);

  useEffect(() => {
    compareAddresses();
  }, [getExperinceData]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  const viewTemplate = () => {
    props.viewSessionAPI({
      params: {
        id: id,
        expand: "template,template_photos,host",
        is_price_porated: 1
      },
      callback: (res, value) => {
        
        if(res){
          if(_.get(value, 'error')){
            setErrorMessage("404 Not found")
          }
          setTempData(value)
          setPhoto(`${_.get(value, 'data.meta.photo.path')}/${_.get(value, 'data.meta.photo.folders.template')}/${_.get(value, 'data.meta.photo.sizes.large')}/${_.get(value, 'data.details.template.photos[0].photo')}`);
          setLoading(false)
          setLocationData({lat:_.get(value, 'data.details.location.x'), lng:_.get(value, 'data.details.location.y')});
            let start_date_item = _.get(value, 'data.details.experience_date');
            let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
            
            let concated_array = scheduled_date_items
            setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
          
        }
      }
    })
  };

  const experienceList = () => {
    props.experienceListAPI({
        params: {
            template: searchParams.get("template"),
            is_price_porated: 1,
            min_experience_date: currentLocalISOString,
            min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
        },
        callback: (res, value) => {
            if(res){
              if(_.get(value, 'error')){
                setErrorMessage("404 Not found")
              }
                
                setExperinceData(_.get(value, 'data.items'))
            }
        }
    })
  };

  const showModal = (type) => {
    
    if(type === 'scheduled'){
      setIsScheduledModalOpen(true)
    } else if(type === 'league'){
      setIsLeagueModalOpen(true)
    } else if(type === 'open'){
      setIsOpenSessionModalOpen(true);
    }
    
  };

  const handleOk = () => {
    if(_.get(getTempData, 'data.details.type') === 'scheduled'){
      setIsScheduledModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'league'){
      setIsLeagueModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'open'){
      setIsOpenSessionModalOpen(false);
    }
    
  };

  const handleCancel = () => {
    if(_.get(getTempData, 'data.details.type') === 'scheduled'){
      setIsScheduledModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'league'){
      setIsLeagueModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'open'){
      setIsOpenSessionModalOpen(false);
    }
  };

  const showAiModal = () => {
    setIsAiModalOpen(true);
  }

  const handleAIok = () => {
    setIsAiModalOpen(false)
  }

  const handleAICancel = () => {
    setIsAiModalOpen(false)
  }

  const showSessionDateModal = () => {
    setIsSessionDateModalOpen(true)
  };

  const scheduleHandleOk = () => {
    setIsSessionDateModalOpen(false)
  }

  const scheduleHandleCancel = () => {
    setIsSessionDateModalOpen(false)
  };

  const onCloseMultiple = () => {
    setIsMultipleLocationModalOpen(false)
  }

  const AnyReactComponent = () => <div><a href={`https://www.google.com/maps?q=${_.get(getTempData, 'data.details.address')}`} target='_blank'><Image className='orange-image-icon' width={35} preview={false} height={35} src={require('../../assets/img/map-marker-icon.png')} /></a></div>;

  const inlineStyle = {
    backgroundImage: !_.isEmpty(_.get(getTempData, 'data.details.template.photos')) ? `url(${getPhoto})` : `url(${require("../../assets/img/communiti-static-image.png")})`,
    backgroundSize: 'cover', // Adjust as needed
    backgroundRepeat: 'no-repeat', // Adjust as needed
    backgroundColor: _.isEmpty(_.get(getTempData, 'data.details.template.photos')) ? "#ccc" : ""
  };

  const proceedPayment = () => {
    // navigate(`/social-login/?id=${getItems.id}`)
    showLoginModal();
  };

  const proceedPaymentOpen = () => {
    navigate(`/social-login/?id=${id}`)
  };

  const compareAddresses = () => {
    if(_.size(getExperinceData) > 0){
        const uniqueAddresses = new Set(getExperinceData.map((item) => item.address));
  
        if (uniqueAddresses.size === 1) {
          
          setAddressData(getExperinceData[0].address)
        } else {
          
          setAddressData("Multiple Locations")
        }
    }
   
  };

  const handleSelectChange = (value) => {
    // Find the selected item based on the address
    
    setDate(value)
    setTime(null);
    
    if(_.size(getExperinceData) > 0){
        const selected = getExperinceData.find(item => item.experience_date === value);
        if(selected){
          const mergedDate = [selected.experience_date, ...selected.schedule_dates]
          setItems({
              id: selected.id,
              dates: mergedDate,
              session_dates: mergedDate,
              experience_date: selected.experience_date,
              experience_end_date: selected.experience_end_date,
              meta_details: selected.meta_details,
              can_late_register: selected.can_late_register,
              original_price: selected.original_price,
              price: selected.price,
              today:  moment.tz(_.get(selected, 'meta_details.timezone')),
              address: selected.address
          });
          let count = 0;
            const selectedToday = moment.tz(_.get(selected, 'meta_details.timezone'));
            const selectedTimeZone = _.get(selected, 'meta_details.timezone');
            mergedDate.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setPastDateCount(count);
        }
      
    }
   
}

const handleChangeTime = (value) => {
  
        setTime(value);
        // setIsEnableSchedule(false)
    
};

const showProratedModal = () => {
  setIsProratedModalOpen(true);
}

const handleProratedOk = () => {
  setIsProratedModalOpen(false)
}

const handleProratedCancel = () => {
  setIsProratedModalOpen(false)
}

const CustomMapStyles = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [{ saturation: -100 }],
  },
];

const showLoginModal = () => {
  setIsModalOpen(true);
};
const handleLoginOk = () => {
  setIsModalOpen(false);
};
const handleLoginCancel = () => {
  setIsModalOpen(false);
};

const getGoogleToken = (response, signInType) => {
  if (response) {
      let requestParams = {
        type: 'google',
        token: _.get(response, 'credential'),
                  scopes: ['profile', 'email']
      }
      props.login({
        body:requestParams,
        callback: (res,data)=>{
                          
          if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
message:"Success",
description:'Login successful'
})
// window.location.reload();
          }
          
        }
    });
      
    }
  };
  
  const basicExperienceDetailsCard = {
      // width: "616px",
      padding: "16px 16px 16px 32px",
      borderRadius: "10px",
      border: "1px solid var(--Gray-200, #EAECF0)",
      background: "var(--Base-White, #FFF)",
  };

  const experience_details_text = {
    color: 'var(--Gray-700, #344054)',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px', 
    marginLeft: "24px"
  }
  const render = () => {
    
  return (
      <div>
        {
           loading ? <Loader/> : errorMessage ? <h2 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>{errorMessage}</h2> : _.size(getScheduledDate)-pastDateCount === 0 ?
           <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>This session is not available.</div>:
          <div>
          <div className="banner-container">
          <div className='container'>
            {
              !loading ? <div className='image-background' style={inlineStyle}>
  
              </div> : <Skeleton active paragraph={{
                rows: 7
              }}/>
            }
              
  
          </div>
      
    </div>
    <div className='rating-container'>
      <div className='container'>
      <div className="row">
        <div className='col-12'>
        {/* review and address */}
        <div style={{marginTop: "16px"}}>
          {
            !loading ? <div className='rating-card'>
            <p className='experince-title'>{_.get(getTempData, 'data.details.template.title')}</p>
            <p className='experince-address'>{_.get(getTempData, 'data.details.address')}</p>
            <div style={{display:"flex", flexDirection: "row"}}>
              {
                !_.isEmpty(_.get(getTempData, 'data.details.average_ratings')) ?
                <>
                <Rating initialValue={1} readonly={true} iconsCount={1} size={20} className='experince-rating-star'/>
            <p className='experince-rating'>{_.get(getTempData, 'data.details.average_ratings')}</p>
            <p className='dot'></p>
            <p className='experience-reviews'> {_.get(getTempData, 'data.details.no_of_ratings')} reviews</p>
            </> : <p className='experience-no-reviews'> No reviews</p>
              }
            </div>
          </div> : <Skeleton active paragraph={{rows: 3}}/>
          }
        
        </div>
        </div>
          <div className='col-lg-8'>
              <div style={{marginTop: "16px"}}>
                <div>
                  
                    <div style={basicExperienceDetailsCard}>
                      <div style={{display: "flex", flexDirection: "row"}}>
                    <img style={{width: "24px", height: "24px"}} src={require('../../assets/img/home-line.jpg')}/>
                    <div style={experience_details_text}>{_.startCase(_.get(getTempData, 'data.details.type'))} Session</div>
                    <img
                    onClick={() => showModal(_.get(getTempData, 'data.details.type'))}
                     style={{width: "16px", height: "16px", marginLeft: "4px", marginTop: "4px", cursor: "pointer"}} 
                     src={require("../../assets/img/Question-Icon.jpg")}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", marginTop: "16px"}}>
                    <img style={{width: "24px", height: "24px"}} src={require('../../assets/img/marker-pin-04.png')}/>
                    <div style={experience_details_text}>{_.size(getExperinceData) > 0 && getAddressData}</div>
                    {getAddressData === "Multiple Locations" && <img
                    onClick={() => setIsMultipleLocationModalOpen(true)}
                     style={{width: "16px", height: "16px", marginLeft: "4px", marginTop: "4px", cursor: "pointer"}} 
                     src={require("../../assets/img/Question-Icon.jpg")}/>}
                    </div>
                    {/* <div style={{display: "flex", flexDirection: "row", marginTop: "16px"}}>
                      <img src={require('../../assets/img/trend-up-01.jpg')}/>
                      <div style={experience_details_text}>Selling fast! Less than 10% of spots remaining</div>
                    </div> */}
                    </div>
                  
                  
                  </div>
                  {
                    !loading ? <div className='description-container'>
                    <div className='template-description-title'>
                      About
                    </div>
                    <div className='template-description' dangerouslySetInnerHTML={{__html: _.get(getTempData, 'data.details.template.description')}} />
                    {/* {_.get(getTempData, 'data.details.template.description')} */}
                    {/* </div> */}
                    </div> : <Skeleton active paragraph={{rows: 8}}/>
                  }
                  
                  {
                    !_.isEmpty(_.get(getTempData, 'data.details.template.extra_info')) ?
                    <div className='description-container'>
                  <div className='template-description-title'>
                    Additional Information
                  </div>
                  {
                    _.get(getTempData, 'data.details.template.extra_info').map((value, index) => {
                      return(
                      <div className='additional-info-container'>
                      <div className='additional-info-title'>
                          {_.get(value, 'name')}
                      </div>
                      <div className='additional-info-description' dangerouslySetInnerHTML={{__html: _.get(value, 'description')}} />
                        
                      {
                        _.get(value, 'link') && <div className='additional-info-link-container'>
                        <div className='link-back'>
                        <GoLinkExternal className='additional-info-link-icon'/>
                        </div>
                      <a href={_.get(value, 'link')} target='_blank' className='additional-info-link'>
                          {_.get(value, 'name')}
                      </a>
                      </div>
                      }
                      
                      </div>
                      )
                      
                    })
                  }
                  
                  </div>
                  :""
                  }

                  {
                    !_.isEmpty(_.get(getTempData, 'data.details.host.meta_details.refund_policy')) ?
                    <div className='refund-description-container'>
                  <div className='refund-description-title'>
                    Refund Policyasdas
                  </div>
                      <div className='refund-info-description'>
                         {
                          _.get(getTempData, 'data.details.host.meta_details.refund_policy')
                         }
                      </div>
                      
                  
                  
                  </div>
                  :""
                  }
                  {
                    !loading ? <div className='refund-description-container'>
                    <div className='refund-description-title'>
                      Location
                    </div>
                    <a href={`https://www.google.com/maps?q=${_.get(getTempData, 'data.details.address')}`} target="_blank" className='experience-location-map'>
                           {
                            _.get(getTempData, 'data.details.address')
                           }
                        </a>
                        <div style={{ height: '40vh', width: '100%',borderRadius:'23px',marginTop:'23px' }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: "AIzaSyBIU6YefaUI89t5f7LwsvY0z21sLmLdpYc" }}
                         
                          defaultZoom={defaultProps.zoom}
                          center={LocationData}
                          options={{
                            styles: CustomMapStyles
                          }}
                        >
                        <AnyReactComponent
                         address={_.get(getTempData, 'data.details.address')}
                        />
                      </GoogleMapReact>
                    </div>
                    
                    
                    </div> : <Skeleton active paragraph={{rows: 5}}/>
                  }
                
                  
              </div>
          </div>
          <div className="col-lg-4" style={{marginTop: "16px"}}>
            {/* Sticky container comes here */}
            <SessionCard/>
              </div>
          </div>
      </div>
    </div>
    <div>
    <Modal closable={false} footer={null} open={isScheduledModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
        <p>A</p>&nbsp;<p style={{fontWeight: 600}}>Scheduled</p>&nbsp;<p>listing takes place over a specific sets of dates.</p>
        </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isLeagueModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      A < >League / Tournament</> listing will involve competing against other athletes in a set of games. Scores and results will be recorded.
        </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isOpenSessionModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      An Open session does not have a specified start date or time. You communicate directly with the Club or Coach to pick a date and time that works for you.
        </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isAiModalOpen} onOk={handleAIok} onCancel={handleAICancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      Communiti’s AI will ask you questions upon registration and ensure teams generated in the League or Tournament are balanced for fun, competitive play.
        </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isMultipleLocationModalOpen} onOk={onCloseMultiple} onCancel={onCloseMultiple}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session is held at multiple locations. Select a start date and time to see available locations.
        </div>
      </Modal>
      </div>

      <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      { !_.isEmpty(getItems) && _.size(_.get(getItems, 'session_dates')) > 0 && _.get(getItems, 'session_dates').map((item, index) => {
       
        const date = moment.tz(item, _.get(getItems, 'meta_details.timezone'));
        const isPastDate = date.isBefore(_.get(getItems, 'today'));
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      <div>
      <Modal 
      title={modalTitle}
      open={isModalOpen} 
      onOk={handleLoginOk} 
      onCancel={handleLoginCancel}
      footer={null}
      >
        <div>
        <div style={{display: "flex", justifyContent: "center"}}>
                    <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
        <Button className='continue-with-email' style={{width: "60%", height: "40px"}}><CiMobile3 style={{width: "25px", height: "25px"}}/>Continue with phone number</Button>
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
        <p cstyle={{textDecorationLine: "none", color: 'var(--Gray-600, #475467)',fontSize: '16px',fontWeight: 600,lineHeight: '24px',cursor: 'pointer'}}>Continue with email</p>
        </div>
        </div>
        
      </Modal>
      </div>
      </div>
       }
       <CommonFooter/>
    </div>
      
  )
  }
  return render();
}

const mapStateToProps = state => {
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
  login: details => dispatch(global.redux.action.account.socialLogin(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(SessionList)