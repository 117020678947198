import loginPage from '../../containers/login/login';
import ViewSession from '../../containers/view-seesion/viewSession';
import UserHandleExperience from '../../containers/userHandleView/experienceWithUserHandle';
import SocialAuthentication from '../../containers/login/social-login';
import RegistrationProfile from '../../containers/registration-profile';
import CreateGuestProfiles from '../../containers/create-profile/create-guest-profiles';
import EditGuestProfiles from '../../containers/create-profile/edit-guest-profile';
import QuestionList from '../../containers/questions/question-list';
import GuestProfilesList from '../../containers/create-profile/guest-profile-list';
import ClauseOperation from '../../containers/questions/clauses/clause';
import PaymentOperation from '../../containers/payment/pay-order';
import PaymentSuccess from '../../containers/payment/payment-success';
import SeeAllReviews from '../../containers/all-reviews/list';
import ExperienceListHandle from '../../containers/userHandleView/experienceListWithHandle';
import ExperienceListing from '../../containers/experience-listing';
import PayInvoice from '../../containers/pay-invoice';
import StepConfiguration from '../../containers/create-profile/step-screen';
import SessionList from '../../containers/session-list';
import MySessions from '../../containers/my-sessions/my-sessions';
import OrderDetails from '../../containers/order-details';
import CreateReview from '../../containers/create-review';
import AthleteAccount from '../../containers/athlete-account';
import ChatsAndNotification from '../../containers/chats/index';
export default {
    SocialAuthentication,
    loginPage,
    ViewSession,
    UserHandleExperience,
    RegistrationProfile,
    CreateGuestProfiles,
    QuestionList,
    EditGuestProfiles,
    GuestProfilesList,
    ClauseOperation,
    PaymentOperation,
    PaymentSuccess,
    SeeAllReviews,
    ExperienceListHandle,
    ExperienceListing,
    PayInvoice,
    StepConfiguration,
    SessionList,
    MySessions,
    OrderDetails,
    CreateReview,
    AthleteAccount,
    ChatsAndNotification
};
